<template>
  <div class="container-fliud">
    <b-overlay :show="aoiLoadingStatus==true">
      <b-alert v-if="filterOptShowAlert" show dismissible variant="warning"
        @dismissed="filterOptShowAlert=false"
      >
        <div class="alert-heading"><strong>Warning!</strong> Please check filter options (start, end).</div>
      </b-alert>

      <b-form-group
        id="input-group-aoi"
        label="Area of Interest (AOI)"
        label-for="input-aoi"
        class="form-label-bold"
      >
        <!-- <b-form-select v-model="selectedAoiItem" :options="aoiItemList">
          <template #first>
            <b-form-select-option :value="null" disabled>Select an AOI ...</b-form-select-option>
          </template>
        </b-form-select> -->
        <b-input-group class="mt-0 mb-0">
          <div id="list-select-aoi-products">
            <list-select
              :list="aoiItemList"
              @select="onSelect"
              :selected-item="selectedAoiItem"
              option-value="code"
              option-text="name"
              placeholder="Select AOI"
              data-cy="select-aoi"
            >
            </list-select>
          </div>
        </b-input-group>
      </b-form-group>
    
      <div class="mt-0 mb-3 text-right1">
        <b-link v-on:click="filterLinkClicked">Filter options</b-link>
        <span><b-icon id="popover-filter" class="ml-2" icon="info-circle" style="color:#007bff"></b-icon></span>
        <b-popover target="popover-filter" triggers="hover" placement="right">
          <template #title>Filter options</template>
          One filter option (date range or latest product) is required.
        </b-popover>
      </div>

      <b-collapse v-model="filterBoxOpened">
        <b-card class="mb-3">
          <div class="row">
            <div class="col col-2 my-auto"><label class="date-picker-label">Start</label></div>
            <div class="col col-10"><b-form-datepicker id="datepicker-begin" v-model="filterOptBegin" reset-button class="mb-2"></b-form-datepicker></div>
          </div>
          <div class="row">
            <div class="col col-2 my-auto"><label class="date-picker-label">End</label></div>
            <div class="col col-10"><b-form-datepicker id="datepicker-end" v-model="filterOptEnd" reset-button class="mb-2"></b-form-datepicker></div>
          </div>
          <div class="row">
            <div class="col col-2 my-auto"><label class="date-picker-label">Latest</label></div>
            <div class="col col-10">
              <b-form-checkbox
                id="checkbox-latest-product"
                v-model="filterOptLatest"
                name="checkbox-latest-product"
                value="accepted"
                unchecked-value="not_accepted"
              >
                Retrieve latest product
              </b-form-checkbox>
            </div>
          </div>
          <div class="text-right">
            <b-link v-on:click="clearFilterClicked">Clear filter</b-link>
          </div>
        </b-card>
      </b-collapse>

      <b-button
        block
        type="submit"
        variant="primary"
        :disabled="filterAOIButtonBusy"
        @click="filterBtnClicked"
        data-cy="submit-load-products"
      >
      <b-spinner
        v-if="filterBtnBusyFromLoading == true"
        small
        style="margin-bottom: 1px"
      ></b-spinner>
        Load products
      </b-button>
    </b-overlay>
  </div>
</template>

<script>
import { ListSelect } from 'vue-search-select'
import {eventBus} from "@/main.js";

export default {
  name: "FilterProducts",
  components: {
    ListSelect
  },
  props: [
    'filterBtnBusyFromLoading'
  ],
  data() {
    return {
      selectedAoiItem: null,
      aoiItemList: [],
      filterSearchPatternSelected: false,
      filterBoxOpened: true,
      filterOptBegin: null,
      filterOptEnd: null,
      filterOptLatest: 'not_accepted',
      filterOptShowAlert: false,
    };
  },
  computed: {
    aoiLoadingStatus() {
      return this.$store.getters.getAOIsLoadingStatus;
    },
    aoiLoadingError() {
      return this.$store.getters.getAOIsLoadingError;
    },
    filterOptDatesCheck() {
      if ((this.filterOptBegin == null) || (this.filterOptEnd == null) || (this.filterOptBegin == "") || (this.filterOptEnd == "")) {
        return false;
      } else {
        return true;
      }
    },
    filterAOIButtonBusy() {
      return ( ((this.filterSearchPatternSelected == false) || (this.filterBtnBusyFromLoading == true) || (this.filterOptsSet == false)) ? true : false );
    },
    filterOptsSet() {
      return (this.filterOptLatest == 'accepted' || (this.filterOptDatesCheck == true))
    }
  },
  methods: {
    /***
     * 
     * Eventhandler for the multi-select input widget
     */
    onSelect (selectedAoiItem) {
      this.selectedAoiItem = selectedAoiItem;
      this.$store.commit("setCurrentAoiName", this.selectedAoiItem);
    },
    /***
     * Eventhandler for show / hide filter options box
     */
    filterLinkClicked() {
      this.filterBoxOpened = !this.filterBoxOpened;
    },
    /**
     * Eventhandler for the click event of the filter products button
     */
    filterBtnClicked() {
      // Close the filter options box of opened
      if (this.filterBoxOpened) {
        this.filterBoxOpened = !this.filterBoxOpened;
      }

      // Reset timeRangeCorrectFlag
      this.$store.commit("setTimeRangeCorrect", false);

      // Remove alert 
      this.filterOptShowAlert = false;

      // Check for optinal filter options
      var filterOption;
      if (this.filterOptLatest == 'accepted') {
        filterOption = "latest";
      } else if (this.filterOptBegin || this.filterOptEnd) {
        filterOption = "range";
        if (
          ((this.filterOptBegin == null) || (this.filterOptBegin == "") && this.filterOptEnd != null) ||
          ((this.filterOptEnd == null) || (this.filterOptEnd == "") && this.filterOptBegin != null) ||
          this.filterOptEnd < this.filterOptBegin
        ) {
          this.filterOptShowAlert = true;
          return;
        }
      } else {
        filterOption = "all";
      }
      
      // Set timeRangeCorrect flag
      let diffTime = Math.abs(new Date(this.filterOptEnd) - new Date(this.filterOptBegin));
      let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays <= 62) {
        this.$store.commit("setTimeRangeCorrect", true);
      }

      // Retrieve products
      var payload = {
        'aoi_id': this.selectedAoiItem.code,
        'filterOption' : filterOption,
        'dateBegin': this.filterOptBegin,
        'dateEnd': this.filterOptEnd
      };
      this.$store.dispatch('getProducts', payload);
      this.$store.commit("setDataLayer", null);
      eventBus.$emit('hide-sidebar-right');
    },
    /**
     * Resets the filter otptions box
     */
    clearFilterClicked() {
      this.filterOptBegin = null;
      this.filterOptEnd = null;
      this.filterOptLatest = false;
      //this.$store.commit("setFilterOpts", null);  
    },
    /**
     * Initializes the eventbus handlers
     */
    initEventBusHanslers() {
      var ref = this;

      eventBus.$on('clear-filter', function () {
        ref.clearFilterClicked();
      });

      eventBus.$on('close-filter-box', function () {
        if (ref.filterBoxOpened) {
          ref.filterLinkClicked();
        }
      });

      eventBus.$on('select-aoi', function (aoiID) {
        const aoiItem = ref.aoiItemList.find(a => a.code == aoiID);
        var d = {
          code: aoiItem.code,
          name: aoiItem.name
        }
        ref.onSelect(d);
      });
    }
  },
  mounted() {
    // Load list of aois from the store and sort the list alphabetically
    this.$store.dispatch("getAOIs").then( () => {
      var tmpAOIs = this.$store.getters.getAOIs.slice();
      var sortedAOIs = tmpAOIs.sort((a, b) => (a.aoi_name.toLowerCase() > b.aoi_name.toLowerCase()) ? 1 : -1)
      sortedAOIs.forEach(element => {
        var d =  { code: element.aoi_id, name: element.aoi_name };
        this.aoiItemList.push(d);
      });
    });
    
    // Initialize eventbus handler
    this.initEventBusHanslers();

    this.$store.commit("setFilterOpts", null);
  },
  watch: {
    selectedAoiItem(newValue) {
      if ('code' in newValue) {
        this.filterSearchPatternSelected = true;
        // Refresh filter options ?
      } else {
        this.filterSearchPatternSelected = false;
      }
    },
    filterOptLatest(newVal) {
      if (newVal === 'accepted') {
        this.filterOptBegin = null;
        this.filterOptEnd = null;
        this.$store.commit("setFilterOpts", "latest");
      } else {
        this.$store.commit("setFilterOpts", null);
      }
    },
    filterOptBegin(newVal) {
      if (newVal) {
        this.filterOptLatest = 'not_accepted';
        this.$store.commit("setFilterOpts", {"start": this.filterOptBegin, "end": this.filterOptEnd});
      } else {
        this.$store.commit("setFilterOpts", null);
      }
    },
    filterOptEnd(newVal) {
      if (newVal) {
        this.filterOptLatest = 'not_accepted';
        this.$store.commit("setFilterOpts", {"start": this.filterOptBegin, "end": this.filterOptEnd});
      } else {
        this.$store.commit("setFilterOpts", null);
      }
    }
  }
};
</script>

<style scoped>
#list-select-aoi-products {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
  font-weight: normal;
}

.card-body {
  padding-bottom: 14px;
}

.form-label-bold {
  font-weight: bold;
}

.date-picker-label {
  font-weight: bold;
}
</style>
