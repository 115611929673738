<template>
  <div>
    <b-modal
      size="lg"
      ref="modal-get-token-glofas"
      title="Retrieve Access Token"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      ok-only
    >
      <b-overlay :show="showOverlay==true" rounded="sm">
        <div class="mt-1">
          <dd class="font-sidebar-key">Get Access Token (API call)</dd>
          <b-form-textarea
            id="textarea-aoi-description"
            placeholder="AOI description ..."
            v-model="accessTokenCurl"
            rows="3"
            no-resize
            disabled
          ></b-form-textarea>
        </div>

        <div class="pt-4">
          <div>
            <dd class="font-sidebar-key">Client-ID</dd>
            <b-form-input disabled v-model="this.clientID"></b-form-input>
          </div>

          <div class="pt-2">
            <dd class="font-sidebar-key">Client Secret</dd>
            <b-form-input disabled v-model="this.clientSecret"></b-form-input>
          </div>

          <div class="row">
            <div class="col">
              <b-button class="mt-2 float-right" variant="outline-primary" @click="getTokenClicked()">Get token</b-button>
            </div>
          </div>
        </div>

        <div v-if="showError==false">
          <b-card v-if="tokenResult.length > 0" class="mt-3 card-padding">
            <div v-if="tokenResult.length > 0" class="text-muted">
              <dd class="font-key">Access Token</dd>
              <dt class="font-value mb-2">{{tokenResult}}</dt>
              <dd class="font-key">Expires in:</dd>
              <dt class="font-value mb-2">{{expiresIn}} - {{expiresInDate}}</dt>
            </div>
          </b-card>
        </div>
        <div v-else>
          <b-alert v-if="showError" class="mt-2" show dismissible @dismissed="showError=false" variant="danger">
            <div class="alert-heading"><strong>Error!</strong> Token could not be retrieved</div>
          </b-alert>
        </div>

        <div class="mb-2"></div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import axios_services from "@/axios/axios-services.js";

export default {
  name: "ModalDialogGetTokenGloFAS",
  data() {
    return {
      accessTokenCurl: "",
      userID: "",
      tokenResult: "",
      expiresIn: "",
      expiresInDate: "",
      showOverlay: false,
      showError: false,
    }
  },
  computed: {
    clientID() {
      return this.$store.getters.clientID;
    },
    clientSecret() {
      return this.$store.getters.clientSecret;
    },
  },
  methods: {
    showModal() {
      this.initForm();
      this.$refs["modal-get-token-glofas"].show();
    },
    hideModal() {
      this.refresh();
      this.clearInputs();
      this.$nextTick(() => {
        this.$refs["modal-get-token-glofas"].hide();
      });
    },
    initForm() {
      this.userID = this.clientID;
      this.tokenResult = "";
      this.showError = false;
      this.showOverlay = false;

      this.accessTokenCurl = `curl -X 'POST' 'https://api.gfm.eodc.eu/v1/auth/get_bearer_token' \
-H 'accept: application/json' \
-H 'Content-Type: application/json' \
-d '{ "client_id": "`+ this.clientID +`", "client_secret": "` + this.clientSecret + `" }'`;
    },
    getTokenClicked() {
        let payload = {
          "client_id": this.clientID,
          "client_secret": this.clientSecret
        }

        this.showOverlay = true;
        axios_services    
          .post("auth/get_bearer_token", payload)
          .then((response) => {
            // console.log("response: ", response);
            
            this.tokenResult = response.data.access_token;
            this.expiresIn = this.secondsToHms(response.data.expires_in);
            this.expiresInDate = this.getExpiredInDate(response.data.expires_in);

            this.showOverlay = false; 
            this.showError = false;
          }).catch((err) => {
            console.log("Error: ", err);
            this.showOverlay = false;
            this.showError = true;
          });
    },
    secondsToHms(d) {
      d = Number(d);

      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);

      var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours ") : "";
      var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
      var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";

      return hDisplay + mDisplay + sDisplay;
    },
    getExpiredInDate(d) {
      let currentDate = new Date;
      let expiresInDate = new Date(currentDate.getTime() + 1000 * d);
      return expiresInDate;
    }
  },
  created: function () {
    var ref = this;
    eventBus.$on("show-modal-get-token-glofas", () => {
      ref.showModal();
    });
  },
};
</script>

<style scoped>
.font-value {
  font-size: 12px;
}

.font-key {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 0px;
}

.card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
