<template>
  <div id="map"></div>
</template>

<script>
import {eventBus} from "@/main.js";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import axios_services from '@/axios/axios-services.js'
import {ProductLayers} from "@/product-layery.js";

export default {
  name: "ProductsMap",
  props: ["invalidateSize"],
  data() {
    return {
      map: null,
      tileLayerGrey: null,
      tileLayerStreet: null,
      tileLayerSatellite: null,
      legend: null,
      scaler: null,
      sidebarOpened: true,
      mapConfiguration: {
        center: [45.815457, 8.6101375],
        zoom: 16,
        minZoom: 0,
        maxZoom: 16,
        startZoom: 14,
      },
      aoiBoundaryLayer: null,
      aoiBoundaryLayer2: null,
      dataLayer: null,
      productExtent: null,
      legends: null,
      sentinel1LayerMetadata: null,
    };
  },
  computed: {
    aois() {
      return this.$store.getters.getAOIs;
    },
    productsAOIMapItem() {
      return this.$store.getters.getProductsAOIMapItem;
    },
    dataLayerInformation() {
      return this.$store.getters.getDataLayer;
    }
  },
  mounted() {
    this.initMap();
    this.initEventBusHandlers();
    this.loadLegendData();
  },
  methods: {
    /**
     * This function initializes the leaflte map
     */
    initMap: function () {
      // Initialize map

      // Gray map
      const mapURLGray = process.env.VUE_APP_LEAFLET_BASE_MAP_GRAY;
      this.tileLayerGrey = L.tileLayer.wms(mapURLGray, {
        attribution: "&copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
      });

      // OSM map
      const mapURLOsm = process.env.VUE_APP_LEAFLET_BASE_MAP_OSM;
      this.tileLayerStreet = L.tileLayer(mapURLOsm, {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      });

      // Satellite map
      const mapURLSatellite = process.env.VUE_APP_LEAFLET_BASE_MAP_SATELLITE;
      this.tileLayerSatellite = L.tileLayer(mapURLSatellite, {
        attribution:
          "&copy;  Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ",
      });

      // Initialize maps
      var mapOptions = {
        minZoom: this.mapConfiguration.minZoom,
        maxZoom: this.mapConfiguration.maxZoom,
        layers: [this.tileLayerGrey],
      };

      this.map = L.map("map", mapOptions).setView([0, 0], 0);
      
      // Base maps
      var baseMaps = {
				"Light Gray Map": this.tileLayerGrey,
        "Open Street Map": this.tileLayerStreet,
        "Satellite Map": this.tileLayerSatellite,
			}; 

      var ref = this;
      this.map.on('baselayerchange', function(e) {
        if (e.name === 'Light Gray Map') {
          ref.map.options.maxZoom = 16;
        } else {
          ref.map.options.maxZoom =  18;
        }
      });

      // Initialize control to switch maps
			L.control.layers(baseMaps).addTo(this.map);

      // var mapOptions = {
      //   minZoom: this.mapConfiguration.minZoom,
      //   maxZoom: this.mapConfiguration.maxZoom
      // };

      // this.map = L.map("map", mapOptions).setView(
      //   [0,0], 0
      // );

      // For details about the zIndey default values please visit:
      // https://github.com/Leaflet/Leaflet/blob/v1.0.0/dist/leaflet.css#L87 
      this.map.createPane('LAYER_1');                   // LAYER_1 -> raster or vector data
      this.map.createPane('LAYER_2');                   // LAYER_2 -> AOI bounding box
      this.map.createPane('LAYER_3');                   // LAYER_3 -> Satelloite extent
      this.map.getPane('LAYER_1').style.zIndex = 600;
      this.map.getPane('LAYER_2').style.zIndex = 690;
      this.map.getPane('LAYER_3').style.zIndex = 1750;

      //const mapURL = String(process.env.VUE_APP_MAP_URL);
      // const mapURL = "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
      // this.tileLayer = L.tileLayer(mapURL,
      //   {
      //     // minZoom: 0,
      //     maxZoom: this.mapConfiguration.maxZoom,
      //     attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ',
      //   }
      // );
      // this.tileLayer.addTo(this.map);

      // Add scaler to map
      this.scaler = L.control.scale();
      this.scaler.addTo(this.map);

      this.initMapView();
    },
    /**
     * Sets the view point to the user's geo location.
     */
    initMapView() {
      this.map.spin(true);

      var ref = this;
      this.map.locate({setView: true, maxZoom: ref.mapConfiguration.startZoom}) 
      .on('locationfound', function(){
        ref.map.spin(false);
      })
      .on('locationerror', function(){
          ref.map.setView(ref.mapConfiguration.center, ref.mapConfiguration.startZoom);
          ref.map.spin(false);
      });
    },
    /***
     * This function creates the event handler for the event bus
     */
    initEventBusHandlers() {
      var ref = this;
      
      eventBus.$on('updateEqui7GridViewPoint', function () {
          ref.updateLocation(ref.productExtent.getBounds());
      });
      
      eventBus.$on('displayEqui7Grid', function (data) {
        ref.removeSatelltiteExtent();
        ref.showSatelltiteExtent2(data)
      });
    },
    /**
     * This functions calls the leaflet invalidateSize function
     */
    invalidateMapSize() {
      this.map.invalidateSize();
    },
    /**
     * Updates the map view point
     * @param bounds: Boundaries of the AOI (see leaflet docomentatoin for details)
     */
    updateLocation(bounds) {
      if (bounds) {
        this.map.fitBounds(bounds);
      }
    },
    /**
     * This function removes a GeoJSON layer from the map
     */
    removeAOIBoundaryLayer() {
      if (this.aoiBoundaryLayer != null) {
        this.map.removeLayer(this.aoiBoundaryLayer);
      }
    },
    /**
     * This function adds the bounding box layer of the AOI to the map 
     */
    addAOIBoundaryLayer(v) {
      // Remove the current aoi from the map
      this.removeAOIBoundaryLayer()

      // Styling and options
      var style = {
        color: "#dc3545",
        weight: 2,
        //"opacity": 0.65,
        fill: false
      };
      var options = {
        interactive: false
      };

      // Find item in aoi list
      var aoiItem = this.aois.find(x => x.aoi_id === v);
      var aoiLayer = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: aoiItem.geoJSON.coordinates
        }
      };

      // Create the GeoJSON layer
      this.aoiBoundaryLayer = new L.geoJSON(aoiLayer, {
        options: options,
        style: style,
        pane: 'LAYER_2'
      });

      // Add the layer to the map
      this.$nextTick(() => {
        this.map.addLayer(this.aoiBoundaryLayer );
        this.updateLocation(this.aoiBoundaryLayer.getBounds());
      });
    },
    /**
     * This function visualizes the data provided by the wms server
     */
    addDataLayer(layerDetails) {

      this.removeDataLayer();
      this.removeSatelltiteExtent();

      const ref = this;

      const baseUrl = layerDetails.layerBaseUrl;
      const layerName = layerDetails.layerName;
      const layerID = layerDetails.layerID;

      // Add data layer to the map (raster or vector)
      if (
        layerName == ProductLayers.SENTINEL1_METADATA ||
        layerName == ProductLayers.SENTINEL1_FOOTPRINT ||
        layerName == ProductLayers.SENTINEL1_SCHEDULE
      ) {
        // Case 1 - Add vector information to data layer
        const style = {
          color: "#4c4c4c",
          fillColor: "#808080",
          weight: 2,
          opacity: 0.8,
          fill: true,
          fillOpacity: 0.8,
        };

        const options = {
          interactive: false,
        };

        const sentinel1Layer = layerDetails.sentinel1Layer.find( x => x.layer_id === layerID );
        const sentinel1Polygon = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: sentinel1Layer.geojson.coordinates
          },
        };

        this.dataLayer = new L.geoJSON(sentinel1Polygon, {
          options: options,
          style: style,
          pane: "LAYER_1",
          onEachFeature: this.onEachS1Feature,
        });

        // Store metadata
        this.sentinel1LayerMetadata = sentinel1Layer.metadata;

        // Set focus to geojson layer
        this.updateLocation(this.dataLayer.getBounds());
      } else {
        // Case 2 - Add raster information to data layer
        this.dataLayer = new L.tileLayer.wms(baseUrl, {
          pane: 'LAYER_1',
          layers: layerName,
          transparent: true,
          format: 'image/png',
          time: layerDetails.productDate.split('T')[0]
        });

        this.dataLayer.on('loading', function() {
          ref.map.spin(true);
          ref.$store.commit('setMapLoadingState', true);
        });

        this.dataLayer.on('load', function() {
          ref.map.spin(false);
          ref.$store.commit('setMapLoadingState', false);
        });

        this.dataLayer.on('tileerror', function() {
          ref.map.spin(false);
          console.log("Error: Data can not be loaded.");
        });

        // Set opacity for the data layer
        this.dataLayer.setOpacity(0.8);
      }
    
      // Add the data layer to the map
      this.dataLayer.addTo(this.map);

      // Add product extent layer to the map
      this.showSatelltiteExtent(layerDetails);
    },
    /**
     * This function binds mouse events to the GeoJSON polygon
     */
    onEachS1Feature(feature, layer) {
      layer.on({
        mouseover: this.highlightFeature,
        mouseout: this.resetHighlight,
        click: this.clickFeature
      });
    },
    /**
     * This function sets the style of the highlighted polygon
     */
    highlightFeature(e) {
      e.target.setStyle({
          color: "#4c4c4c", // TODO: Change color
          fillColor: "#636363",
          weight: 2,
          opacity: 0.80,
          fill: true,
          fillOpacity: 0.8
      });
      e.target.bringToFront();
    },
    /**
     * This function resets the style of the highlighted polygon
     */
    resetHighlight(e) {
      this.dataLayer.resetStyle(e.target);
    },
    /**
     * This function handles the click event for the highlighted polygon
     */
    clickFeature() {
      eventBus.$emit('show-sidebar-right', this.sentinel1LayerMetadata);
    },
    /**
     * This function shows the satellite extent of thr product
     */
    showSatelltiteExtent(layerDetails) {
      
      // Styling and options
      var style = {
        color: "#000000",
        dashArray: '5,10',
        weight: 1,
        //"opacity": 0.65,
        fill: false
      };
      var options = {
        interactive: false,
      };
      
      var productExtentGeometry = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: layerDetails.productExtent.coordinates
        }
      };
      
      // Create leaflet GeoJSON object
      this.productExtent = new L.geoJSON(productExtentGeometry, {
        options: options,
        style: style,
        pane: 'LAYER_3',
        onEachFeature: function (feature, layer) {
          layer.bindTooltip(layerDetails.productDate, { permanent: true });
        }});

      this.map.addLayer(this.productExtent);
    },
    /**
     * This function shows the satellite extent for a product
     */
    showSatelltiteExtent2(extentData) {
      // Styling and options
      var style = {
        color: "#000000",
        dashArray: '5,10',
        weight: 1,
        //"opacity": 0.65,
        fill: false
      };
      var options = {
        interactive: false,
      };
      
      var productExtentGeometry = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: extentData.layerBoundary.coordinates
        }
      };
      
      // Create leaflet GeoJSON object
      this.productExtent = new L.geoJSON(productExtentGeometry, {
        options: options,
        style: style,
        pane: 'LAYER_3',
        onEachFeature: function (feature, layer) {
          layer.bindTooltip(extentData.layerDate, { permanent: true });
        }});
      
      this.$nextTick(() => {
        // Add layer to map
        this.map.addLayer(this.productExtent);

        // Set focus on map
        this.updateLocation(this.productExtent.getBounds());
      });
    },
    /**
     * This function removes the satellite extent layer from the map
     */
    removeSatelltiteExtent() {
      if (this.productExtent) {
        this.map.removeLayer(this.productExtent);
      }
      this.map.spin(false);
    },
    /**
     * This function removes the data layer from the map
     */
    removeDataLayer() {
      if (this.dataLayer) {
        this.map.removeLayer(this.dataLayer);
      }
      this.map.spin(false);
    },
    /**
     * This function deletes the metadata information stored in the data section
     */
    clearSentinel1Metadata() {
      this.sentinel1LayerMetadata = null;
    },
    /**
     * Loads the legends for all layers
     */
    loadLegendData() {
      axios_services.get("legends").then((response) => {
       this.legends = response.data;
      });    
    },
    /**
     * Adds a legend to the map
     */
    addLegend(val) {
      this.removeLegend();

      if (this.legends != null) {
        // Get legend information from array of legends
        const legendItem = this.legends.find(element => element.layer_id == val.layerID);

        var legendTitle = legendItem.legend.title;
        var legendItems = legendItem.legend.values;

        // Add legend to map
        this.legend = L.control({ position: "bottomright" });
        this.legend.onAdd = function () {
          var div = L.DomUtil.create("div", "info legend");

          div.innerHTML +=
            '<div class="legend-padding-header"><strong>' +
            legendTitle +
            "</strong></div>";

          for (let index = 0; index < legendItems.length; index++) {
            const element = legendItems[index];
            var c = element.colour_code;
            div.innerHTML +=
              '<div class="legend-padding-items"><i style="background: ' + 
              c + '"></i><span>' + element.value + "</span><br></div>";
          }

          return div;
        };

      this.legend.addTo(this.map);
      }
    }, 
    /**
     * Removes the legend from the map
     */
    removeLegend() {
      if (this.legend) {
        this.map.removeControl(this.legend);
      }
    },
  },
  watch: {
    /**
     * This watch function listens to the toogle event of the parent component (sidebar). The events is  passed to the component
     * by the `invalidateSize` prop
     */ 
    invalidateSize: function () {
      this.invalidateMapSize();
    },
    /**
     * This function is called when the aoi item in the products page changed 
     */
    productsAOIMapItem(aoiID) {
      if (aoiID == null) {
        this.removeAOIBoundaryLayer();
        this.removeSatelltiteExtent();
      } else {
        this.addAOIBoundaryLayer(aoiID);
      }
    },
    /**
     * This function is called when the layer selection within a product card item changed
     */
    dataLayerInformation: {
      handler: function (val) {
        if (val == null) {
          this.removeDataLayer();
          //this.removeSatelltiteExtent();
          this.removeLegend();
          this.clearSentinel1Metadata();
        } else {
          this.addDataLayer(val);
          this.addLegend(val);
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
#map {
 width: auto;
  height: 100%; 
}
</style>

<style>
.info {
  padding: 6px 8px;
  /*font: 14px/16px Arial, Helvetica, sans-serif;*/
  font-size: 12px;
  background: white;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}
.legend {
  text-align: left;
  line-height: 18px;
  color: #555;
}
.legend i {
  width: 18px;
  height: 18px;
  float: left;
  margin-right: 8px;
  opacity: 0.8;
}
.legend .colorcircle {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  opacity: 1;
  border: 1px solid;
  border-color: #888888;
  margin-top: 2px;
}
.legend-padding-header {
  padding-bottom: 8px;
}
.legend-padding-items {
  padding-bottom: 2px;
}
.leaflet-tooltip-pane {
  z-index: 750 !important;
}
</style>