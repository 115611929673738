<template>
  <div class="container mt-4 ">

    <b-overlay :show="showOverlay">
      <div>
        <b-card title="Personal Information">
          <p class="text-muted card-info-text">This form allows you to change your personal user data.</p>
          <b-alert v-if="profileLoadingError" show dismissible variant="danger"><strong>Error!&nbsp;</strong>Profile information could not be loaded!</b-alert>
          <b-form>

            <!-- Mail information -->
            <b-form-group>
              <label class="label-style">E-mail address</label>
              <b-form-input
                v-model="formData.mail"
                placeholder=""
                disabled
              ></b-form-input>
            </b-form-group>

            <!-- Name and gender information -->
            <div class="row">
              <div class="col">
                <b-form-group>
                <label class="label-style">First Name</label>
                  <b-form-input
                    v-model="formData.firstName"
                    placeholder="Enter first name"
                    autocomplete="given-name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col">
                <b-form-group>
                  <label class="label-style">Last Name</label>
                    <b-form-input
                      v-model="formData.lastName"
                      placeholder="Enter last name"
                      autocomplete="family-name"
                      disabled
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <!-- Organization information -->
              <div class="row">
                <div class="col"> 
                  <b-form-group>
                    <label class="label-style">Organization Name</label>
                    <b-form-input
                      v-model="formData.organizationName"
                      placeholder="Enter organization name"
                      autocomplete="organization"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <!-- Organization information -->
              <div class="row">
                <div class="col">
                  <b-form-group>
                    <label class="label-style">Organization Type</label>
                    <b-form-select v-model="formData.organizationType" :options="organizationTypeOptions">
                      <template #first>
                        <b-form-select-option :value="null">-- Select organization type --</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>  
              </div>

              <!-- Address information 1 -->
              <div class="row">
                <div class="col">
                  <b-form-group>
                    <label class="label-style">Country</label>
                    <b-form-select v-model="formData.country" :options="countryOptions">
                      <template #first>
                        <b-form-select-option :value="null">-- Select country --</b-form-select-option>
                      </template>     
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group>
                    <label class="label-style">Access Token</label><br/>
                    <b-link v-on:click="retrieveAccessToken">Retrieve access token</b-link>
                  </b-form-group>
                </div>
              </div>
              
              <div class="row mt-2">
                <div class="col text-right">
                  <b-button variant="secondary mr-2" @click="refreshBtnClicked()">Refresh</b-button>
                  <b-button variant="primary" @click="submitBtnClicked()">Update data</b-button>
                </div>
              </div>
    
          </b-form>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios_services from '@/axios/axios-services.js'
import {eventBus} from "@/main.js";

export default {
  name: "GlofasProfile",
  components: {},
  data() {
    return {
      showOverlay: false,
      countriesLoaded: false,
      organizationTypesLoaded: false,

      formData: {
        mail: "",
        firstName: "",
        lastName: "",
        organizationName: "",
        organizationType: null,
        country: null,
        inputData: ""
      },
      countryOptions:  [],
      organizationTypeOptions: []
    };
  },
  computed: {
    profileLoadingStatus() {
      return this.$store.getters.getProfileLoadingStatus;
    },
    profileLoadingError() {
      return this.$store.getters.getProfileLoadingError;
    },
    formDataLoaded() {
      if ((this.organizationTypesLoaded) && (this.countriesLoaded) && !this.profileLoadingStatus) {
        return true;
      } else {
        return false;
      }
    },
    userType() {
      return this.$store.getters.getUserType;
    }
  },
  methods: {
    /**
     * This function initializes the personal data form
     */
    initFormData(userData) {
      let organizationType;
      if (userData.company_type == "") {
        organizationType = null;
      } else {
        organizationType = userData.company_type
      }
      
      let country;
      if (userData.country == "") {
        country = null;
      } else {
        country = userData.country
      }

      this.formData.mail = userData.email;
      this.formData.firstName = userData.first_name;
      this.formData.lastName = userData.last_name;
      this.formData.organizationName = userData.company;
      this.formData.organizationType = organizationType;
      this.formData.country = country;
    },
    /**
     * Event handler for the submit button
     */
    submitBtnClicked() {
      this.showUpdateDataModal();
    },
    /**
     * Event handler for the refresh button
     */
    refreshBtnClicked() {
      this.initFormData(this.$store.getters.userData);
    },
    /**
     * Modal dialog to confirm the update operation
     */
    showUpdateDataModal() {
      this.$parent.$bvModal.msgBoxConfirm('Do you want to save the changes?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then((value) => {
        if (value) {

        let organizatonType = this.formData.organizationType == null ? "" : this.formData.organizationType;
        let country = this.formData.country == null ? "" : this.formData.country;

          // Payload for update operation
          var updatedFormData = {
            'customer_id': this.$store.getters.clientID,
            'country': country,
            'company_name': this.formData.organizationName,
            'company_type': organizatonType,
          };

          this.showOverlay = true;

          axios_services
          .patch("users/update", updatedFormData)
          .then(() => {
            this.showNotification("success", "Success! Data updated", "Personal data successfully updated",);
            // Refresh data in store
            this.$store.commit('storeUser', {
              first_name: this.formData.firstName,
              last_name: this.formData.lastName,
              company: this.formData.organizationName,
              company_type: this.formData.organizationType,
              country: this.formData.country,
            });
            this.showOverlay = false;
          })
          .catch((error) => {
            var errorMsg = error.response.data.message.error_definition.message;
            console.log("Error: ", errorMsg);
            this.showNotification("danger", "Error!", "Personal data could not be updated");
          });
        }
      })
    },
    /**
     * Show the dialog to retrieve the access token and the client id 
     */
    retrieveAccessToken() {
      eventBus.$emit('show-modal-get-token-glofas');
    },
    /***
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true
      });  
    },
  },
  mounted() {
    this.showOverlay = true;

    // Load user data from server
    this.$store.dispatch('getProfileData').then( () => {
      this.initFormData(this.$store.getters.userData);
    })
    
    // Load countries data from server
    axios_services.get("utils/countries").then((response) => {
      this.countryOptions = response.data;
      this.countriesLoaded = true;
    });

    // Load organization type data from server
    axios_services.get("utils/organization_types").then((response) => {
      this.organizationTypeOptions = response.data
      this.organizationTypesLoaded = true;
    });
  },
  watch: {
    formDataLoaded(newState) {
      if (newState) {
        this.showOverlay = false;
      } else {
        this.showOverlay = true;
      }
    }
  }
};
</script>

<style scoped>

.card .label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

.card-text-font {
  font-style: normal;
}

.card .card-info-text {
  font-size: 14px;
}

</style>
