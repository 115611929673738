import Vue from "vue";
import store from "./store/store.js";

let codeSnippet = new Vue({
  store,
  methods: {
    getCodeSnippet(codeSnippetParams) {
      return getCodeSnippet(codeSnippetParams);
    },
  },
});

function getCodeSnippet(codeSnippetParams) {
  let mapUrl = codeSnippetParams.mapUrl;
  let mapAttr = codeSnippetParams.mapAttr;

  let wmsLayerName = codeSnippetParams.wmsLayerName;
  let wmsLayerDate = codeSnippetParams.wmsLayerDate;

  let aoiCoordinates = codeSnippetParams.coordinatesAOIExtent;
  let satelliteCoordinates = codeSnippetParams.coordinatesSatelliteExtent;

  let showAOIExtent = codeSnippetParams.addAOIExtent;
  let showSatelliteExtent = codeSnippetParams.addSatelliteExtent;

  let productDate = codeSnippetParams.productDate;

  let legendTitle = codeSnippetParams.legendTitle;
  let legendItems = codeSnippetParams.legendItems;

  let htmlCode = `
  <!DOCTYPE html>
  <html>
    <head>
      <title>GFM - Map</title>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
  
      <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"/>
      <script src="https://unpkg.com/leaflet@1.7.1/dist/leaflet.js"></script>
  
      <style>
        #map {
          width: 800px;
          height: 500px;
        }
      </style>
      <style>
        .info {
          padding: 6px 8px;
          font-size: 12px;
          background: white;
          background: rgba(255, 255, 255, 0.8);
          box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
        }
        .info h4 {
          margin: 0 0 5px;
          color: #777;
        }
        .legend {
          text-align: left;
          line-height: 18px;
          color: #555;
        }
        .legend i {
          width: 18px;
          height: 18px;
          float: left;
          margin-right: 8px;
          opacity: 0.8;
        }
        .legend .colorcircle {
          border-radius: 50%;
          width: 14px;
          height: 14px;
          opacity: 1;
          border: 1px solid;
          border-color: #888888;
          margin-top: 2px;
        }
        .legend-padding-header {
          padding-bottom: 8px;
        }
        .legend-padding-items {
          padding-bottom: 2px;
        }
        .leaflet-tooltip-pane {
          z-index: 750 !important;
        }
      </style>
    </head>
    <body>

      <!-- Leaflet map -->
      <div id="map"></div>

      <script>
        /**
         * This function adds the flood prodcut (WMS layer) to the map
         */
        function addWMSLayer() {
          var wmsLayer = L.tileLayer.wms('https://geoserver.gfm.geoville.com/geoserver/gfm/wms', {
            pane: 'LAYER_1',
            layers: 'gfm:' + wmsLayerName,
            transparent: true,
            format: 'image/png',
            time: wmsLayerDate,
            tiled: false,
          }).addTo(map);
          wmsLayer.setOpacity(0.8);
        }
        /**
         * This function adds the satellite extent (GeoJSON) to the map
         */
        function addSatelliteExtent(coordinates, show) {
          let style = {
            pane: 'LAYER_2',
            color: "#000000",
            dashArray: '5,10',
            weight: 1,
            fill: false
          };
  
          let options = {
            interactive: false,
          };
  
          let satelliteExtentFeature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: coordinates
            }
          };
  
          let satelliteExtentLayer = new L.geoJSON(satelliteExtentFeature, {
            options: options,
            style: style,
            onEachFeature: function (feature, layer) {
              layer.bindTooltip("${productDate}", { permanent: true });
            }
          });
  
          if (show) {
            map.addLayer(satelliteExtentLayer);
          }

          return satelliteExtentLayer;
        }
        
        /**
         * This function adds the AOI extent (GeoJSON) to the map
         */
        function addAOIExtent(coordinates, show) {
          let style = {
            pane: 'LAYER_3',
            color: "#dc3545",
            weight: 2,
            fill: false
          };
  
          let options = {
            interactive: false,
          };
  
          let aoiExtentFeature = {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: coordinates
            }
          };
  
          let aoiExtentLayer = new L.geoJSON(aoiExtentFeature, {
            options: options,
            style: style,
          });
          
          if (show) {
            map.addLayer(aoiExtentLayer);
          }

          return aoiExtentLayer;
        }
  
        /**
         * This function adds a legend to the map
         */    
        function addLegend(legendTitle, legendItems) {
          var legend = L.control({ position: "bottomright" });
  
          legend.onAdd = function () {
            var div = L.DomUtil.create("div", "info legend");
  
            div.innerHTML +=
              '<div class="legend-padding-header"><strong>' +
              legendTitle +
              "</strong></div>";
  
            for (let index = 0; index < legendItems.length; index++) {
              const element = legendItems[index];
              var c = element.colour_code;
              div.innerHTML +=
                '<div class="legend-padding-items"><i style="background: ' +
                c + '"></i><span>' + element.value + "</span><br></div>";
            }
  
            return div;
          };
  
          legend.addTo(map);
        }
      </script>
  
      <script>
        let mapUrl = '${mapUrl}';
        let mapAttr = '${mapAttr}';
  
        let wmsLayerName = '${wmsLayerName}';
        let wmsLayerDate = '${wmsLayerDate}';
  
        let coordinatesAOIExtent = ${aoiCoordinates};
        let coordinatesSatelliteExtent = ${satelliteCoordinates};

        let showAOIExtent = ${showAOIExtent};
        let showSatelliteExtent = ${showSatelliteExtent};
  
        let legendTitle = '${legendTitle}';
        let legendItems = ${legendItems};

        // Initialize the leaflet map with a base layer
        var map = L.map('map').setView([45.815, 8.610], 10);
        L.tileLayer(mapUrl, {
          maxZoom: 18,
          minZoom: 2,
          attribution: mapAttr,
        }).addTo(map);
        
        // Add scale control
        L.control.scale().addTo(map);
        
        // Create panes to control the ordering of layers
        map.createPane('LAYER_1');
        map.createPane('LAYER_2');
        map.createPane('LAYER_3');
        map.getPane('LAYER_1').style.zIndex = 600;
        map.getPane('LAYER_2').style.zIndex = 700;
        map.getPane('LAYER_3').style.zIndex = 800;
        
        // Add data layers to the map
        addWMSLayer();
        let aoiExtentLayer = addAOIExtent(coordinatesAOIExtent, showAOIExtent)
        let satelliteExtentLayer = addSatelliteExtent(coordinatesSatelliteExtent, showSatelliteExtent);
        
        map.fitBounds(satelliteExtentLayer.getBounds());

        // Add legend to the map
        addLegend(legendTitle, legendItems);
      </script>
    </body>
  </html>   
  `;

  return htmlCode;
}

export default {
  install: function(Vue) {
    Vue.prototype.$codeSnippet = codeSnippet;
  },
};
