import Vue from "vue";
import Vuex from "vuex";

import axios_services from "@/axios/axios-services.js";
import router from "../router/router.js";
import { eventBus } from "@/main.js";

Vue.use(Vuex);
var logoutTimer = null;

/* eslint-disable */
const store = new Vuex.Store({
  state: {
    loginSource: "",
    drawnAOIs: null,
    addedAOI: null,
    aois: [],
    aoisLoadingStatus: false,
    aoisLoadingError: null,
    aoisActiveItem: "",
    products: [],
    productsLoadingStatus: null,
    productsLoadingError: null,
    productsActiveItem: "",
    productsAOIMapItem: null,
    userSite: false,
    authTyoe: null,
    auth: {
      access_token: null,
      refresh_token: null,
      scope: null,
      client_id: null,
      client_secret: null,
    },
    userType: null,
    user: {
      gender: "",
      email: "",
      first_name: "",
      last_name: "",
      company: "",
      company_type: "",
      address: "",
      city: "",
      zip_code: "",
      country: "",
      twitter_config_state: null,
    },
    glofasUser: {
      emai: "",
      firstName: "",
      lastName: "",
      organizationName: "",
      organizationType: "",
      country: "",
    },
    efasUser: {
      emai: "",
      firstName: "",
      lastName: "",
      organizationName: "",
      organizationType: "",
      country: "",
    },
    dataLayer: null,
    clipboardCoordinates: "",
    viewVisibleForGlofas: true,
    glofasRegistrationCompleted: false,
    efasRegistrationCompleted: false,
    profileLoadingStatus: null,
    profileLoadingError: null,
    toggleProductsDownloadLoadingView: false,
    toggleProductsReportLoadingView: false,
    twitterAccountCompleted: false,
    notifications: [],
    notificatonLoadingStatus: false,
    updateNotificationHistoryToggle: false,
    sidebarRightVisibility: false,
    mapLoadingState: false,
    filterOpts: {
      start: "",
      end: "",
    },
    administrativeRegions: [],
    currentAoiName: "",
    timeRangeCorrect: false,
    maxNumberAoisReached: false,
  },
  mutations: {
    setLoginSource(state, paylaod) {
      state.loginSource = paylaod;
    },
    setDrawnAOIs(state, paylaod) {
      state.drawnAOIs = null;
      state.drawnAOIs = paylaod;
    },
    setAddedAOI(state, paylaod) {
      state.addedAOI = paylaod;
    },
    setAddedAOICount(state, paylaod) {
      state.addedAOICount = paylaod;
    },
    setAOIs(state, paylaod) {
      state.aois = paylaod;
    },
    setAOIsLoadingStatus(state, paylaod) {
      state.aoisLoadingStatus = paylaod;
    },
    setAOIsLoadingError(state, paylaod) {
      state.aoisLoadingError = paylaod;
    },
    setAOIsActiveItem(state, paylaod) {
      state.aoisActiveItem = paylaod;
    },
    setProducts(state, paylaod) {
      state.products = paylaod;
    },
    setProductsLoadingStatus(state, paylaod) {
      state.productsLoadingStatus = paylaod;
    },
    setProductsLoadingError(state, paylaod) {
      state.productsLoadingError = paylaod;
    },
    setProductsActiveItem(state, paylaod) {
      state.productsActiveItem = paylaod;
    },
    setProductsAOIMapItem(state, paylaod) {
      state.productsAOIMapItem = paylaod;
    },
    setClipboardCoordinates(state, payload) {
      state.clipboardCoordinates = payload;
    },
    authUser(state, userData) {
      state.auth.access_token = userData.access_token;
      state.auth.refresh_token = userData.refresh_token;
      state.auth.scope = userData.scope;
      state.auth.client_id = userData.client_id;
      state.auth.client_secret = userData.client_secret;
      axios_services.defaults.headers.common["Authorization"] =
        "Bearer " + userData.access_token;
    },
    storeUser(state, userData) {
      state.user.gender = userData.gender;
      state.user.email = userData.email;
      state.user.first_name = userData.first_name;
      state.user.last_name = userData.last_name;
      state.user.company = userData.company;
      state.user.company_type = userData.company_type;
      state.user.address = userData.address;
      state.user.city = userData.city;
      state.user.zip_code = userData.zip_code;
      state.user.country = userData.country;
      state.user.twitter_config_state = userData.twitter_config_state;
    },
    setGlofasUser(state, userData) {
      state.glofasUser.email = userData.email;
      state.glofasUser.firstName = userData.firstName;
      state.glofasUser.lastName = userData.lastName;
      state.glofasUser.organizationName = userData.organisationName;
      state.glofasUser.organizationType = userData.organisationType;
      state.glofasUser.country = userData.country;
      state.glofasUser.glofasAccountFinished = userData.glofasAccountFinished;
    },
    setEfasUser(state, userData) {
      state.efasUser.email = userData.email;
      state.efasUser.firstName = userData.firstName;
      state.efasUser.lastName = userData.lastName;
      state.efasUser.organizationName = userData.organisationName;
      state.efasUser.organizationType = userData.organisationType;
      state.efasUser.country = userData.country;
      state.efasUser.efasAccountFinished = userData.efasAccountFinished;
    },
    setUserType(state, userType) {
      state.userType = userType;
      localStorage.setItem("user_type", state.userType);
    },
    clearData(state) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("scope");
      localStorage.removeItem("client_id");
      localStorage.removeItem("client_secret");
      localStorage.removeItem("user_type");
      localStorage.removeItem("login_src");

      for (var prop in state.auth) {
        if (state.auth.hasOwnProperty(prop)) {
          state.auth[prop] = null;
        }
      }
      for (var prop in state.user) {
        if (state.user.hasOwnProperty(prop)) {
          state.auth[prop] = "";
        }
      }
    },
    changeUserSite(state, userSite) {
      state.userSite = userSite;
    },
    setDataLayer(state, paylaod) {
      if (paylaod != null) {
        state.dataLayer = paylaod;
      } else {
        state.dataLayer = null;
      }
    },
    setGlofasAuth(state, paylaod) {
      state.glofasAuth = paylaod;
    },
    setEfasAuth(state, paylaod) {
      state.efasAuth = paylaod;
    },
    setViewVisibleForGlofas(state, paylaod) {
      state.viewVisibleForGlofas = paylaod;
    },
    setProfileLoadingStatus(state, paylaod) {
      state.profileLoadingStatus = paylaod;
    },
    setProfileLoadingError(state, paylaod) {
      state.profileLoadingError = paylaod;
    },
    setToggleProductsDownloadLoadingView(state, payload) {
      state.toggleProductsDownloadLoadingView = payload;
    },
    setToggleProductsReportLoadingView(state, payload) {
      state.toggleProductsReportLoadingView = payload;
    },
    // setTwitterAccountCompleted(state, payload) {
    //   state.twitterAccountCompleted = payload;
    // },
    setNotifications(state, paylaod) {
      state.notifications = paylaod;
    },
    updateNotifications(state, index) {
      state.notifications.splice(index, 1);
    },
    setNotificationsLoadingStatus(state, paylaod) {
      state.notificatonLoadingStatus = paylaod;
    },
    setSidebarRightVisibility(state, paylaod) {
      state.sidebarRightVisibility = paylaod;
    },
    setMapLoadingState(state, paylaod) {
      state.mapLoadingState = paylaod;
    },
    setFilterOpts(state, paylaod) {
      state.filterOpts = paylaod;
    },
    setAdministrativeRegions(state, paylaod) {
      state.administrativeRegions = paylaod;
    },
    setCurrentAoiName(state, paylaod) {
      state.currentAoiName = paylaod;
    },
    setTimeRangeCorrect(state, paylaod) {
      state.timeRangeCorrect = paylaod;
    },
    setMaxNumberAoisReached(state, payload) {
      state.maxNumberAoisReached = payload;
    },
  },
  actions: {
    getAOIs(context) {
      context.commit("setAOIsLoadingStatus", true);
      context.commit("setAOIsLoadingError", null);

      return axios_services
        .get("aoi/user/" + this.state.auth.client_id)
        .then((response) => {
          context.commit("setAOIs", response.data.aois);
          context.commit("setAOIsLoadingStatus", false);
        })
        .catch((error) => {
          context.commit("setAOIsLoadingStatus", false);
          context.commit("setAOIsLoadingError", error.message);
        });
    },
    getProducts(context, payload) {
      context.commit("setProducts", null);
      context.commit("setProductsLoadingStatus", true);
      context.commit("setProductsLoadingError", null);
      context.commit("setProductsActiveItem", "");
      context.commit("setProductsAOIMapItem", null);

      var aoi_id = payload.aoi_id;
      var get_url = getProductsEndpoint(aoi_id, payload);

      return axios_services
        .get(get_url)
        .then((response) => {
          context.commit("setProducts", response.data);
          context.commit("setProductsLoadingStatus", false);

          // Do not show the aoi geometry on the map if no products were found
          // (Note: Could be improved!)
          if (response.data.products.length > 0) {
            context.commit("setProductsAOIMapItem", aoi_id);
          } else {
            //context.commit('setProductsAOIMapItem', null);  // Do show boundary box if no results were found
            context.commit("setProductsAOIMapItem", aoi_id); // Show the boundary box even if no results were found
          }
        })
        .catch((error) => {
          context.commit("setProductsLoadingStatus", false);
          context.commit("setProductsLoadingError", error.message);
          // Do not show the aoi geometry in case of an error
          context.commit("setProductsAOIMapItem", null);
        });
    },
    getProfileData(context) {
      context.commit("setProfileLoadingStatus", true);
      context.commit("setProfileLoadingError", null);
      return axios_services
        .get("users/" + this.state.auth.client_id)
        .then((response) => {
          context.commit("storeUser", {
            gender: response.data.gender,
            email: response.data.email,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            company: response.data.company_name,
            company_type: response.data.company_type,
            address: response.data.address,
            city: response.data.city,
            zip_code: response.data.zip_code,
            country: response.data.country,
            twitter_config_state: response.data.twitter_config_state,
          });
          context.commit("setProfileLoadingStatus", false);
        })
        .catch((error) => {
          console.log("Error: Profile data could not be loaded", error);
          context.commit("setProfileLoadingStatus", false);
          context.commit("setProfileLoadingError", error);
        });
    },
    setLogoutTimer({ commit }, expirationDate) {
      const now = new Date();
      const expirationTime = expirationDate.getTime() - now.getTime();

      if (logoutTimer != null) {
        clearTimeout(logoutTimer);
      }
      // Set the timeout
      logoutTimer = setTimeout(() => {
        commit("clearData");
        eventBus.$emit("show-modal-session-expired");
      }, expirationTime);
    },
    signin({ commit, dispatch }, authData) {
      return new Promise((resolve, reject) => {
        axios_services
          .post("auth/login", authData)
          .then((response) => {
            commit("authUser", {
              access_token: response.data.access_token,
              refresh_token: response.data.refresh_token,
              scope: response.data.scope,
              client_id: response.data.client_id,
              client_secret: response.data.client_secret,
            });
            const now = new Date();
            const expirationDate = new Date(
              now.getTime() + response.data.expires_in * 1000
            );

            localStorage.setItem("access_token", response.data.access_token);
            localStorage.setItem("expirationDate", expirationDate);
            localStorage.setItem("refresh_token", response.data.refresh_token);
            localStorage.setItem("scope", response.data.scope);
            localStorage.setItem("client_id", response.data.client_id);
            localStorage.setItem("client_secret", response.data.client_secret);
            dispatch("fetchUser");
            dispatch("setLogoutTimer", expirationDate);
            resolve(response);

            // Show a modal dialog on the startup
            setTimeout(function () {
              eventBus.$emit("show-modal-startup");
            }, 500);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    register({ commit, dispatch }, registerData) {
      return new Promise((resolve, reject) => {
        axios_services
          .post("auth/register", registerData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    tryAutoSignIn({ commit, dispatch }) {
      const token = localStorage.getItem("access_token");
      if (!token) {
        return;
      }
      const expirationDate = new Date(localStorage.getItem("expirationDate"));
      const now = new Date();
      if (now >= expirationDate) {
        return;
      }
      commit("authUser", {
        access_token: localStorage.getItem("access_token"),
        refresh_token: localStorage.getItem("refresh_token"),
        scope: localStorage.getItem("scope"),
        client_id: localStorage.getItem("client_id"),
        client_secret: localStorage.getItem("client_secret"),
        client_permissions: localStorage.getItem("client_permissions"),
      });
      dispatch("fetchUser");
      dispatch("setLogoutTimer", expirationDate);
    },
    signout({ commit, state }) {
      /*axios_services.post("auth/tokens/revoke", {
        client_id: state.auth.client_id,
        client_secret: state.auth.client_secret,
        token: state.auth.access_token,
        token_type_hint: "access_token"
      }).then(response => {
        commit('clearData');
        localStorage.removeItem('access_token');
        localStorage.removeItem('expirationDate');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('scope');
        localStorage.removeItem('client_id');
        localStorage.removeItem('client_secret');
        router.replace('/login');
      }).catch(error => {
      })*/
      //TODO NEEDS TO BE IMPLEMENTED IN API
      commit("clearData");
      commit("setUserType", null);
      if (logoutTimer != null) {
        clearTimeout(logoutTimer);
      }
      // localStorage.removeItem('access_token');
      // localStorage.removeItem('expirationDate');
      // localStorage.removeItem('refresh_token');
      // localStorage.removeItem('scope');
      // localStorage.removeItem('client_id');
      // localStorage.removeItem('client_secret');
      // localStorage.removeItem('user_type');
      router.replace("/login");
    },
    fetchUser({ commit, state }) {
      axios_services
        .get("users/" + state.auth.client_id)
        .then((response) => {
          commit("storeUser", {
            gender: response.data.gender,
            email: response.data.email,
            first_name: response.data.first_name,
            last_name: response.data.last_name,
            company: response.data.company_name,
            company_type: response.data.company_type,
            address: response.data.address,
            city: response.data.city,
            zip_code: response.data.zip_code,
            country: response.data.country,
            twitter_config_state:
              response.data.twitter_config_status == null ||
              response.data.twitter_config_status == false
                ? false
                : true,
          });
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    resetPasswordRequest({}, formData) {
      axios_services.post("auth/password_forget", formData).catch((error) => {
        console.log("Error: ", error);
      });
    },
    resetPassword({}, formData) {
      return new Promise((resolve) => {
        axios_services
          .post("auth/password_reset", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      });
    },
    confirmRegister({}, formData) {
      return new Promise((resolve) => {
        axios_services
          .post("auth/email_confirmation", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      });
    },
    resentEmail({}, formData) {
      axios_services
        .post("auth/confirmation_mail/send", formData)
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    signinGlofas({ commit, dispatch }, data) {
      commit("authUser", {
        //access_token: data.access_token,
        refresh_token: data.refresh_token,
        scope: data.scope,
        client_id: data.client_id,
        client_secret: data.client_secret,
      });

      const now = new Date();
      const expirationDate = new Date(now.getTime() + data.expires_in * 10000);

      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("expirationDate", expirationDate);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("scope", data.scope);
      localStorage.setItem("client_id", data.client_id);
      localStorage.setItem("client_secret", data.client_secret);
      dispatch("fetchUser");
      dispatch("setLogoutTimer", expirationDate);
    },
    signinEfas({ commit, dispatch }, data) {
      commit("authUser", {
        //access_token: data.access_token,
        refresh_token: data.refresh_token,
        scope: data.scope,
        client_id: data.client_id,
        client_secret: data.client_secret,
      });

      const now = new Date();
      const expirationDate = new Date(now.getTime() + data.expires_in * 10000);

      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("expirationDate", expirationDate);
      localStorage.setItem("refresh_token", data.refresh_token);
      localStorage.setItem("scope", data.scope);
      localStorage.setItem("client_id", data.client_id);
      localStorage.setItem("client_secret", data.client_secret);
      dispatch("fetchUser");
      dispatch("setLogoutTimer", expirationDate);
    },
    getNotifications(context, paylaod) {
      const url = `notifications/push_notifications/${context.state.auth.client_id}?filter=true`;
      return axios_services
        .get(url)
        .then((response) => {
          context.commit("setNotifications", response.data);
          console.log("------------------------------");
          console.log(response.data);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    changeSingleNotificationStatus(context, data) {
      context.commit("setNotificationsLoadingStatus", true);
      const url = `notifications/notification_status/product_id`;
      let payload = {
        notification_seen: true,
        user_id: data.user_id,
        aoi_id: data.aoi_id,
        product_id: data.product_id,
      };

      // Get index of notification item
      const notificationItemIndex = context.state.notifications.findIndex(
        (x) => x.product_id === payload.product_id
      );

      return new Promise((resolve, reject) => {
        return axios_services
          .post(url, payload)
          .then(() => {
            context.commit("updateNotifications", notificationItemIndex);
            context.commit("setNotificationsLoadingStatus", false);
            resolve();
          })
          .catch((error) => {
            console.log("Error: ", error);
            context.commit("setNotificationsLoadingStatus", false);
            reject();
          });
        //context.commit("updateNotifications", notificationItemIndex);
      });
    },
    setAllNotificationsAsRead(context) {
      context.commit("setNotificationsLoadingStatus", true);
      const url = `notifications/notification_status`;
      let paylaod = {
        notification_seen: true, // TODO: Change flag
        user_id: context.state.auth.client_id,
      };
      return axios_services
        .post(url, paylaod)
        .then(() => {
          context.commit("setNotifications", []);
          context.commit("setNotificationsLoadingStatus", false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          context.commit("setNotificationsLoadingStatus", false);
        });
    },
    loadAdministrativeRegions({ commit }) {
      return axios_services
        .get("utils/administrative_regions")
        .then((response) => {
          commit("setAdministrativeRegions", response.data.countries);
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    loadMaxNumberAoisReached(context) {
      return axios_services
        .get("aoi/allowance/" + this.state.auth.client_id)
        .then((response) => {
          context.commit("setMaxNumberAoisReached", !response.data.allowance);
        })
        .catch((error) => {});
    },
  },
  getters: {
    getLoginSource(state) {
      return state.loginSource;
    },
    getDrawnAOIsCount(state) {
      if (state.drawnAOIs) {
        return Object.keys(state.drawnAOIs._layers).length;
      } else {
        return 0;
      }
    },
    getAddedAOIsCount(state) {
      return state.addedAOI;
    },
    getDrawnAOI(state) {
      return state.drawnAOIs;
    },
    getAddedAOI(state) {
      return state.addedAOI;
    },
    getAOIs(state) {
      return state.aois;
    },
    getAOIsLoadingStatus(state) {
      return state.aoisLoadingStatus;
    },
    getAOIsLoadingError(state) {
      return state.aoisLoadingError;
    },
    getAOIsActiveItem(state) {
      return state.aoisActiveItem;
    },
    getProducts(state) {
      return state.products;
    },
    getProductsLoadingStatus(state) {
      return state.productsLoadingStatus;
    },
    getProductsLoadingError(state) {
      return state.productsLoadingError;
    },
    getProductsActiveItem(state) {
      return state.productsActiveItem;
    },
    getProductsAOIMapItem(state) {
      return state.productsAOIMapItem;
    },
    accessToken: (state) => {
      return state.access_token;
    },
    clientID: (state) => {
      return state.auth.client_id;
    },
    clientSecret: (state) => {
      return state.auth.client_secret;
    },
    userData: (state) => {
      return state.user;
    },
    getGlofasUserData: (state) => {
      return state.glofasUser;
    },
    getEfasUserData: (state) => {
      return state.efasUser;
    },
    getUserType: (state) => {
      return localStorage.getItem("user_type");
    },
    name: (state) => {
      return state.user.first_name + " " + state.user.last_name;
    },
    isAuthenticated: (state) => {
      return state.auth.access_token != null;
    },
    userSite: (state) => {
      return state.userSite;
    },
    getDataLayer: (state) => {
      return state.dataLayer;
    },
    getClipboardCoordinates: (state) => {
      return state.clipboardCoordinates;
    },
    getViewVisibleForGlofas: (state) => {
      return state.viewVisibleForGlofas;
    },
    getProfileLoadingStatus(state) {
      return state.profileLoadingStatus;
    },
    getProfileLoadingError(state) {
      return state.profileLoadingError;
    },
    getToggleProductsDownloadLoadingView(state) {
      return state.toggleProductsDownloadLoadingView;
    },
    getToggleProductsReportLoadingView(state) {
      return state.toggleProductsReportLoadingView;
    },
    getTwitterAccountCompleted(state) {
      return state.user.twitter_config_state;
    },
    getNotifications(state) {
      return state.notifications;
    },
    getNotificatonLoadingStatus(state) {
      return state.notificatonLoadingStatus;
    },
    getUpdateNotificationHistoryToggle(state) {
      return state.updateNotificationHistoryToggle;
    },
    getSidebarRightVisibility(state) {
      return state.sidebarRightVisibility;
    },
    getMapLoadingState(state) {
      return state.mapLoadingState;
    },
    getFilterOpts(state) {
      return state.filterOpts;
    },
    getAdministrativeRegions(state) {
      return state.administrativeRegions;
    },
    getCurrentAoiName(state) {
      return state.currentAoiName;
    },
    getTimeRangeCorrect(state) {
      return state.timeRangeCorrect;
    },
    getMaxNumberAoisReached(state) {
      return state.maxNumberAoisReached;
    },
  },
});
/* eslint-enable */
export default store;

/**
 * This function returns the url for the get products endpoint (optional: with query paramter suffix)
 */
function getProductsEndpoint(aoiID, payload) {
  var getURL = "aoi/".concat(aoiID).concat("/products");
  var filterOption = payload.filterOption;

  if (filterOption === "latest") {
    getURL = getURL.concat("?time=latest");
  } else if (filterOption === "all") {
    getURL = getURL.concat("?time=all");
  } else if (filterOption === "range") {
    //getURL = getURL.concat('?time=range&from=2019-01-01T00:00:00&to=2020-01-02T00:00:00')
    var from = payload.dateBegin.concat("T00:00:00");
    var to = payload.dateEnd.concat("T23:59:59");

    getURL = getURL
      .concat("?time=range")
      .concat("&from=")
      .concat(from)
      .concat("&to=")
      .concat(to);
  }

  return getURL;
}
