import Vue from "vue";
import store from "./store/store.js";
import axios from "axios";

let glofasAuth = new Vue({
  store,
  methods: {
    login() {
      const authorization_url = process.env.VUE_APP_GFM_BACKEND_URL.concat("auth/glofas_authorize");
      axios
        //.get("https://api.gfm.geoville.com/v1/auth/glofas_authorize")
        .get(authorization_url)
        .then((res) => {
          var url = res.data.url;
          window.location.href = url;
        });
    },
    handleCallback() {
      return new Promise((resolve, reject) => {
        var payload = {
          callback_url: window.location.href,
        };

        const callback_url = process.env.VUE_APP_GFM_BACKEND_URL.concat("auth/glofas_callback");
        axios
          //.post("https://api.gfm.geoville.com/v1/auth/glofas_callback", payload)
          .post(callback_url, payload)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});

export default {
  install: function(Vue) {
    Vue.prototype.$glofasAuth = glofasAuth;
  },
};
