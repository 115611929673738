<template>
  <div>
      <div class="row">
        <div class="col">
          <div id="code-snippet-map"></div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-right">
          <b-button variant="secondary mr-2" @click="cancel()">Cancel</b-button>
          <b-button variant="primary" @click="getCode()">Get map widget code</b-button>
        </div>
      </div>

    <b-modal
      id="bv-modal-code-snippet"
      size="lg"
      ref="modalCodeSnippet"
      title="Map Widget Code"
    >
      <p class="pt-2">
        Copy snippet code to the clipboard and paste to an empty website.
      </p>

      <b-form-textarea
        id="textarea-code-snippet"
        ref="textAreaInput"
        placeholder=""
        rows="20"
        no-resize
        v-model="text"
        @click="textAreaClicked"
      ></b-form-textarea>
      <b-button block class="mt-3" variant="primary" @click="copyText()">Copy snippet code to clipboard</b-button>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="secondary" class="float-right" @click="hideModal">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

export default {
  name: "CodeSnippetMap",
  props: {
    data: {type: Object}
  },
  data() {
    return {
      map: null,
      legend: null,
      scaler: null,
      mapInitialized: false,

      baseLayer: null,
      wmsLayer: null,
      satelliteExtentLayer: null,
      aoiExtentLayer: null,

      codeSnippetParams: {},
      text: "",
    };
  },
  computed: {},
  mounted() {
    this.initMap();
  },
  methods: {
    /**
     * This function initializes the leaflte map
     */
    initMap: function () {
      // Initialize map object
      this.map = L.map('code-snippet-map');

      this.map.createPane('LAYER_1');                   
      this.map.createPane('LAYER_2');                   
      this.map.createPane('LAYER_3');                   
      this.map.getPane('LAYER_1').style.zIndex = 600;
      this.map.getPane('LAYER_2').style.zIndex = 690;
      this.map.getPane('LAYER_3').style.zIndex = 1750;

      // Add scaler to map
      this.scaler = L.control.scale();
      this.scaler.addTo(this.map);
    },
    /**
     * This function adds a base layer to the map
     */
    setBaseLayer() {
      if (this.baseLayer != null) {
        this.map.removeLayer(this.baseLayer);
        this.baseLayer = null;
      }

      let mapUrl;
      let mapAttr;

      if (this.data.baseMapType == "mapGray") {
        mapUrl = process.env.VUE_APP_LEAFLET_BASE_MAP_GRAY;
        mapAttr = "&copy; Esri &mdash; Esri, DeLorme, NAVTEQ";
      } else if (this.data.baseMapType == "mapOSM") {
        mapUrl = process.env.VUE_APP_LEAFLET_BASE_MAP_OSM;
        mapAttr = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

      } else if (this.data.baseMapType == "mapSatellite") {
        mapUrl = process.env.VUE_APP_LEAFLET_BASE_MAP_SATELLITE;
        mapAttr = "&copy;  Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ";
      }

      this.baseLayer = L.tileLayer(mapUrl, {
        maxZoom: 18,
        minZoom: 2,
        zoom: 8,
        attribution: mapAttr,
      }).addTo(this.map);

      this.map.invalidateSize();

      this.codeSnippetParams.mapUrl = mapUrl;
      this.codeSnippetParams.mapAttr = mapAttr;
    },
    /**
     * This function adds data layers (WMS layer, GeoJSON layer) to the map
     */
    addLayers() {
      this.addWMSLayer(this.data.geoserverBaseUrl, this.data.productLayer, this.data.productDate);
      this.addSatelliteExtent(this.data.satelliteExtentCoordinates, this.data.productDate, this.data.showSatelliteExtent);
      if (this.data.showAoiExtent) {
        this.addAoiExtent(this.data.aoiExtentCoordinates);
      }
      this.map.fitBounds(this.satelliteExtentLayer.getBounds());

      this.codeSnippetParams.addAOIExtent = this.data.showAoiExtent;
      this.codeSnippetParams.addSatelliteExtent = this.data.showSatelliteExtent;
      this.codeSnippetParams.productDate = this.data.productDate;
    },
    /**
     * This function adds AOI extent to the map
     */
    addAoiExtent(coordinates) {
      if (this.aoiExtentLayer != null) {
        this.map.removeLayer(this.aoiExtentLayer);
        this.aoiExtentLayer = null;
      }

      let style = {
        pane: 'LAYER_3',
        color: "#dc3545",
        weight: 2,
        fill: false
      };

      let options = {
        interactive: false,
      };

    
      let aoiExtentFeature = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates]
        }
      };

      this.aoiExtentLayer = new L.geoJSON(aoiExtentFeature, {
        options: options,
        style: style,
      });    

      this.map.addLayer(this.aoiExtentLayer);

      // Create coordinates str
      var coordinatesStr = "[[";
      coordinates.forEach(element => {
        let c = '[' + element + '],';
        coordinatesStr = coordinatesStr + c;
      });

      coordinatesStr = coordinatesStr + "]]";

      this.codeSnippetParams.coordinatesAOIExtent = coordinatesStr;
    },
    /**
     * This function adds the satellite extent to the map
     */
    addSatelliteExtent(coordinates, productDate, show) {
      if (this.satelliteExtentLayer != null) {
        this.map.removeLayer(this.satelliteExtentLayer);
        this.satelliteExtentLayer = null;
      }

      let style = {
        pane: 'LAYER_2',
        color: "#000000",
        dashArray: '5,10',
        weight: 1,
        fill: false
      };

      let options = {
        interactive: false,
      };

      let satelliteExtentFeature = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [coordinates]
        }
      };

      this.satelliteExtentLayer = new L.geoJSON(satelliteExtentFeature, {
        options: options,
        style: style,
        onEachFeature: function (feature, layer) {
          layer.bindTooltip(productDate, { permanent: true });
        }
      });
      
      if (show) {
        this.map.addLayer(this.satelliteExtentLayer);
      }
      
      // Create coordinates
      var coordinatesStr = "[[";
      coordinates.forEach(element => {
        let c = '[' + element + '],';
        coordinatesStr = coordinatesStr + c;
      });

      <my-map layer="flood:extent"/>

      coordinatesStr = coordinatesStr + "]]"

      this.codeSnippetParams.coordinatesSatelliteExtent = coordinatesStr;
    },
    /**
     * This function adds the WMS layer
     */
    addWMSLayer(wmsUrl, wmsLayerName, wmsLayerDate) {
      if (this.wmsLayer != null) {
        this.map.removeLayer(this.wmsLayer);
        this.wmsLayer = null;
      }

      this.wmsLayer = L.tileLayer.wms(wmsUrl, {
        pane: 'LAYER_1',
        layers: 'gfm:' + wmsLayerName,
        transparent: true,
        format: 'image/png',
        time: wmsLayerDate.split('T')[0],
        tiled: false,
      }).addTo(this.map);

      this.wmsLayer.setOpacity(0.8);

      this.codeSnippetParams.wmsLayerName = wmsLayerName;
      this.codeSnippetParams.wmsLayerDate = wmsLayerDate;
    },
    addLegend() {
      if (this.legend != null) {
        this.map.removeControl(this.legend);
        this.legend = null;
      }

      let legendItem = this.data.legendData;

      var legendTitle = legendItem.legend.title;
      var legendItems = legendItem.legend.values;

      // Add legend to map
      this.legend = L.control({ position: "bottomright" });
      this.legend.onAdd = function () {
        var div = L.DomUtil.create("div", "info legend");

        div.innerHTML +=
          '<div class="legend-padding-header"><strong>' +
          legendTitle +
          "</strong></div>";

        for (let index = 0; index < legendItems.length; index++) {
          const element = legendItems[index];
          var c = element.colour_code;
          div.innerHTML +=
            '<div class="legend-padding-items"><i style="background: ' + 
            c + '"></i><span>' + element.value + "</span><br></div>";
        }

        return div;
      };

      this.legend.addTo(this.map);

      // Store information for html file
      this.codeSnippetParams.legendTitle = legendTitle;
      let itemsStr = '[';
      for (let index = 0; index < legendItems.length; index++) {
        const element = legendItems[index];
        let _v = element.value;
        let _c = element.colour_code;
        let e = '{"value": "' + _v + '", "colour_code": "' + _c + '"},';
        itemsStr = itemsStr + e;
      }
      itemsStr = itemsStr + ']';
      this.codeSnippetParams.legendItems = itemsStr;
    },
    /**
     * This function switches to the first page of the tab view
     */
    cancel() {
      this.emitToParent();
    },
    /**
     * This function copies the HTML code to the clipboard
     */
    copyText() {
      this.$refs.textAreaInput.select();
      document.execCommand('copy');
      this.showNotification("success", "Snippet code copied", "Successfully copied snippet code to clipboard");
    },
    /**
     * Selects the text inside the textarea
     */
    textAreaClicked() {
      this.$refs.textAreaInput.select();
    },
    /**
     * Opens the modal dialog
     */
    showModal() {
      this.$refs["modalCodeSnippet"].show();
    },
    /**
     * Closes the modal dialog
     */
    hideModal() {
     this.$refs["modalCodeSnippet"].hide();
    },
    /**
     * This function emits a a signal to the parent component
     */
    emitToParent () {
      this.$emit('changeTab', 0);
    },
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true
      });
    },
    /**
     * This function shows a modal dialog containing the HTML code of the widget
     */
    getCode() {
      var codeSnippetParameters = {
        "mapUrl" : this.codeSnippetParams.mapUrl,
        "mapAttr" : this.codeSnippetParams.mapAttr,
        "wmsLayerName" : this.codeSnippetParams.wmsLayerName,
        "wmsLayerDate" : this.codeSnippetParams.wmsLayerDate.split('T')[0],
        "coordinatesAOIExtent" : this.codeSnippetParams.coordinatesAOIExtent,
        "coordinatesSatelliteExtent" : this.codeSnippetParams.coordinatesSatelliteExtent,
        "addAOIExtent" : this.codeSnippetParams.addAOIExtent,
        "addSatelliteExtent" : this.codeSnippetParams.addSatelliteExtent,
        "productDate": this.codeSnippetParams.productDate,
        "legendTitle": this.codeSnippetParams.legendTitle,
        "legendItems": this.codeSnippetParams.legendItems
      }

      let htmlCode = this.$codeSnippet.getCodeSnippet(codeSnippetParameters);

      this.text = htmlCode;
      this.showModal();
    },
  },
  watch: {
    data: {
      handler: function () {
        var ref = this;
        setTimeout(function() { 
          ref.setBaseLayer(); 
          ref.addLayers();
          ref.addLegend();
        }, 10);
      },
      deep: true,
    },
  }
};
</script>

<style scoped>
#code-snippet-map {
  width: auto;
  height: 500px; 
}
</style>
