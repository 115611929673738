<template>
  <div>
      <b-nav-item-dropdown right class="nabvar-icon" >
        <template v-slot:button-content>
          <em><b-icon class="nabvar-icon" icon="bell-fill" scale="0.9"></b-icon></em>
          <b-badge v-if="notifications.length != 0" class="notification-badge" variant="success">{{notifications.length}}</b-badge>
        </template>
        <div>
          <div id="notifications-header">
            <div class="row">
              <div class="col"><span>Notifications</span></div>
              <div class="col text-right"><b-link id="read-all" v-on:click="markNotificationsAsRead">Clear all</b-link></div>
            </div>
          </div>
          <b-dropdown-item class="pt-2" v-if="notifications.length==0" disabled ><span class="text-muted">No notifications</span></b-dropdown-item>
          <div class="scrollable-menu">
            <b-overlay :show="notoficationsLoadingStatus==true" rounded="sm" spinner-small spinner-variant="secondary">
            <div v-for="(item, key) in notifications" :data="item" v-bind:key="key">
              <b-dropdown-item  @click="notificationVisited(item, key)" class="notification-item" href="#">
                <!-- <div>
                <li class="notification-box">
                  <div class="row align-middle xx">
                    <div class="col col-sm-8">
                      <strong class="notification-title">{{item.product_time}}</strong>
                      <div class="text-muted notification-sub-title"><div v-html="getNotificationSubTitle(item.aoi_name)"></div></div>
                    </div>
                    <div class="col-sm-4 text-right">
                      <b-button size="sm" @click="showNotificationOnMap('item')"><b-icon icon="geo-alt"></b-icon></b-button>
                    </div>   
                  </div>
                </li>
                </div> -->
                <div>
                  <li class="notification-box">
                    <strong class="notification-title">{{getNotificationProductTitle(item.product_time)}}</strong>
                    <div class="text-muted notification-sub-title"><div v-html="getNotificationSubTitle(item.aoi_name)"></div></div>
                  </li>
                </div>
              </b-dropdown-item>
            </div>
            </b-overlay>
          </div>
      </div>
    </b-nav-item-dropdown> 
  </div>
</template>

<script>
import {eventBus} from "@/main.js";

export default {
  name: "Notifications",
  components: {},
  computed: {
    notifications() {
      return this.$store.getters.getNotifications;
    },
    notoficationsLoadingStatus() {
      return this.$store.getters.getNotificatonLoadingStatus;
    }
  },
  data() {
    return {
    };
  },
  methods: {
    /**
     * This function navigates to the products page an opens the corresponding product item
     */
    showNotificationOnMap(itemData) {
      if ((this.$route.name == 'products') || (this.$route.name == 'productsID')) {
        var data = {
          "aoiID" : itemData.aoi_id,
          "productID" : itemData.product_id, 
          "filterOption" : "all" 
        }
        eventBus.$emit('loadProductFromNotification', data);
      } else {
        this.$router.push({name: "products", params: {id: itemData}})
      }
    },
    notificationVisited(item, key) {
      // Delete notofication from notifications list
      let data = {
        "notification_seen": true,
        "user_id": item.user_id,
        "aoi_id": item.aoi_id,
        "product_id": item.product_id,
        "notificationItemIndex": key
      };
      this.$store.dispatch('changeSingleNotificationStatus', data).catch( () => {
        this.showNotification("danger", "Error!", "Notification status could not be updated");
      })

      // Navivate to the notification
      this.showNotificationOnMap(item);
    },
    getNotificationSubTitle(title) {
      if (title.length > 30) {
        var subStr = title.substring(0,20)
        return '<div title="' + title + '">' + subStr + ' ...</div>'
      } else {
        return '<div>' + title + '</div>'
      }
    },
    getNotificationProductTitle(productDateStr) {
      const productStrArr = productDateStr.split('T')
      return productStrArr[0].concat(" (").concat(productStrArr[1]).concat(")")
    },
    markNotificationsAsRead() {
      var currentNotifications = this.notifications;
      this.$store.dispatch('setAllNotificationsAsRead').then( () => {
        if (this.$route.name == 'notifications') {
          let productIDs = currentNotifications.map( id => id.product_id);
          eventBus.$emit('xxxx', productIDs);
        }
      });
    },
    initNotificationList() {
      this.$store.dispatch("getNotifications");
    },
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true
      });
    },
  },
  mounted() {
    this.initNotificationList();
  },
};
</script>

<style scoped>
#notifications-header span {
  padding-left: 24px;
  font-weight: 400;
  font-size: 14px;
  font-weight: bold;
}

#notifications-header #read-all {
  padding-right: 24px;
  font-weight: 400;
  font-size: 14px;
  font-weight: bold;
}

#notifications-header {
  padding-bottom: 12px;
  border-bottom: 1px solid rgb(207, 207, 207);
}

span.notification-badge {
    position: relative;
    top: -10px;
    right: 10px;
    margin-right: -8px;
}

.notification-box{
  padding-top: 4px;
  padding-bottom: 4px;
}

.scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: scroll;
    min-width: 240px !important;
    overflow-x: hidden;
} 

.notification-title {
  color: #007bff;
  font-size: 14px;
}

.notification-sub-title {
  font-size: 14px;
}

.notification-item {
  border-bottom: 1px solid rgb(207, 207, 207);
}

</style>


