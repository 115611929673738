<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <b-card>
        <b-card-title>
          <div class="row">
            <div class="col">Notification history</div>
            <div class="col text-right">
              <b-button variant="outline-secondary" size="sm" @click="reload()"><b-icon class="mr-0" icon="arrow-repeat"></b-icon></b-button>
            </div>
          </div>
        </b-card-title>
        <b-alert v-model="showAlert" show dismissible variant="danger">
          <div class="alert-heading"><strong>Error!</strong> Notification history could not be loaded.</div>
        </b-alert>
        <b-input-group prepend="AOI" class="mt-4 mb-3">
          <div id="list-select-aoi">
            <list-select :list="aoiList"
              @select="onSelect"
              :selected-item="selectedAoiItem"
              option-value="code"
              option-text="name"
              placeholder="Select AOI">
            </list-select>
          </div>
          <b-button :disabled="searchBtnBusy"  @click="searchAOIClicked" class="ml-2" variant="primary"><b-icon icon="search" scale="1.0"></b-icon></b-button>
        </b-input-group>
        <div class="text-right mb-3 mt-0">
          <b-link v-on:click="clearFilter">Clear filter</b-link>
        </div>
        <div v-if="tableItemsActive.length > 0">
          <b-table id="notifications-table" :per-page="perPage" :current-page="currentPage" hover bordered :items="tableItemsActive" :fields="fields">
            <template v-slot:cell(aoi_name)="data">
              <div v-if="!data.item.notification_seen">
              <span>{{data.item.aoi_name}}&nbsp;</span>
              <b-badge class="notification-badge" variant="success">New</b-badge>
              </div>
              <div v-else>
                <span>{{data.item.aoi_name}}&nbsp;</span>
                <b-badge class="notification-badge" variant="primary">Seen</b-badge>
              </div>
            </template>
            <template v-slot:cell(product_time)="data">
              {{getProductcStr(data.item.product_time)}}
            </template>
            <!-- <template v-slot:cell(notification_seen)="data">
              <div v-if="!data.item.notification_seen">
                <div class="text-center">
                  <b-badge class="notification-badge" variant="success">New</b-badge>
                </div>
              </div>
              <div v-else>
                <div class="text-center">
                <b-badge class="notification-badge" variant="primary">Seen</b-badge>
                </div>
              </div>
            </template> -->
            <template v-slot:cell(mark_as_read)="data">
              <div class="text-center">
              <b-link v-bind:class="{ 'disabled-link': data.item.notification_seen }" id="read-all" v-on:click="markNotificationsAsRead(data.item)">Mark as seen</b-link>
              </div>
            </template>
            <template v-slot:cell(show_on_map)="data">
              <div class="text-center">
              <b-button @click="showNotificationsOnMap(data.item)" size="sm"><b-icon class="mr-0" icon="geo-alt"></b-icon></b-button>
              </div>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="notifications-table">
          </b-pagination>
        </div>
        <div v-else>
          <i>No items could be found!</i>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ListSelect } from 'vue-search-select'
import axios_services from '@/axios/axios-services.js'
import {eventBus} from "@/main.js";

export default {
  name: "NotificationsHistory",
  components: {
    ListSelect
  },
  computed: {
    rows() {
      return this.tableItemsActive.length;
    },
    searchBtnBusy() {
      return Object.keys(this.selectedAoiItem).length === 0 ? true : false;
    },
    clientID() {
      return this.$store.getters.clientID;
    },
    showOverlay() {
      return this.aoiLoadingStatus || this.notificationHistoryLoadingStatus || this.notificationsTableBusy;
    },
    aoiLoadingStatus() {
      return this.$store.getters.getAOIsLoadingStatus;
    },
    notificationsTableBusy() {
      return this.$store.getters.getNotificatonLoadingStatus;
    }
  },
  data() {
    return {
      notificationHistoryLoadingStatus: false,
      showAlert: false,
      aoiList: [],
      selectedAoiItem: {},
      tableItems: [],
      tableItemsActive: [],
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: 'aoi_name', label: 'AOI Name', class: 'align-middle' },
        { key: 'product_time', label: 'Notification Date', class: 'align-middle' },
        // { key: 'notification_seen', label: 'State', class: 'align-middle text-center' },
        { key: 'mark_as_read', label: '', class: 'align-middle' },
        { key: 'show_on_map', label: '', class: 'align-middle' },
      ],
    };
  },
  mounted() {
    this.initAOISelectionBox();
    this.loadNotifications();
    this.initEventHandlers()
  },
  methods: {
    /**
     * This function is called when the search box selection changes
     */
    onSelect (selectedAoiItem) {
      this.selectedAoiItem = selectedAoiItem
    },
    /**
     * Loads the AOIs and initializes the search box
     */
    initAOISelectionBox() {
      this.aoiList = [];
      this.$store.dispatch("getAOIs").then( () => {
        var tmpAOIs = this.$store.getters.getAOIs.slice();
        var sortedAOIs = tmpAOIs.sort((a, b) => (a.aoi_name.toLowerCase() > b.aoi_name.toLowerCase()) ? 1 : -1);
        sortedAOIs.forEach(element => {
          var d =  { code: element.aoi_id, name: element.aoi_name };
          this.aoiList.push(d);
        });
      }).then( () => {
      });
    },
    /**
     * Eventhandler for the AOI search button
     */
    searchAOIClicked() {
      var tmpAOIs = this.tableItems.slice();
      var filteredAOIs = tmpAOIs.filter((aoi) => {
        if (aoi.aoi_name.toLowerCase() == this.selectedAoiItem.name.toLowerCase()) {
          return aoi;
        }
      });
      this.tableItemsActive = filteredAOIs;
    },
    /**
     * This function clears the filter and resets the table 
     */
    clearFilter() {
      this.selectedAoiItem = {};     
    },
    /**
     * Reload the card content
     */
    reload() {
      this.initAOISelectionBox()
      this.loadNotifications();
      this.selectedAoiItem = {};
    },
    /**
     * This function sets a notification item to the state seen/read
     */
    markNotificationsAsRead(itemData) {
      // Perform an optimistic update strategy
      this.updateNotificationBadgeInTable(itemData.product_id, true);

      let data = {
        "notification_seen": true,
        "user_id": itemData.user_id,
        "aoi_id": itemData.aoi_id,
        "product_id": itemData.product_id+"1",
      };
      
      this.$store.dispatch('changeSingleNotificationStatus', data).catch( () => {
        this.showNotification("danger", "Error!", "Notification status could not be updated");
        this.updateNotificationBadgeInTable(itemData.product_id, false);
      })
    },
    /**
     * Shows the notification item on the map
     */
    showNotificationsOnMap(itemData) {
      this.markNotificationsAsRead(itemData);
      this.$router.push({name: "products", params: {id: itemData}});
    },
    /**
     * Load all push notifications from backend
     */
    loadNotifications() {
      this.tableItems = [];
      this.tableItemsActive = [];
      this.notificationHistoryLoadingStatus = true;
      const user_id = this.clientID;
      axios_services
        .get(`/notifications/push_notification_history/${user_id}`)
        .then( (response) => {
          this.notificationHistoryLoadingStatus = false;
          this.tableItems = response.data;
          this.tableItemsActive = response.data;
        })
        .catch((err) => {
          console.log("Error: ", err);
          this.showAlert = true;
          this.notificationHistoryLoadingStatus = false;
        });
    },
    /**
     * This function deletes the badge from the notification row
     */
    updateNotificationBadgeInTable(productID, markAsReaddFlag) {
      const itemIndex = this.tableItems.findIndex( item => item.product_id == productID);
      this.tableItems[itemIndex]['notification_seen'] = markAsReaddFlag;
    },
    /**
     * Returns the formatted producte timestamp
     */
    getProductcStr(productTime) {
      const arr = productTime.split('T').concat("11");
      return arr[0].concat(' (').concat(arr[1]).concat(')');
    },
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true
      });
    },
    /**
     * Initializes eventbus handlers
     */
    initEventHandlers() {
      var ref = this;
      eventBus.$on('xxxx', function (productIDs) {
        productIDs.map(x => ref.updateNotificationBadgeInTable(x, true));
      });
    }
  },
  watch: {
    selectedAoiItem(v) {
      if ( Object.keys(v).length === 0 ) {
        this.tableItemsActive = this.tableItems;
      }
    }
  }
};
</script>

<style scoped>
#list-select-aoi {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

span.notification-badge {
  /* note: hacky solution - could be improved */ 
  position: relative;
  top: -1px !important;
}

.disabled-link {
  cursor: not-allowed;
  color: #6c757d;
}
</style>
