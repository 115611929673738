<template>
  <div>
    <b-form-group>
      <label class="label-style">{{inputName}}</label>
      <b-input-group>
        <b-form-input v-model="inputText" :disabled="mode=='saved'" autocomplete="off"></b-form-input>
        <b-input-group-append>
          <b-button @click="changeMode" v-if="mode=='saved'" size="sm" variant="secondary">
            <b-icon icon="pencil"></b-icon>
          </b-button>
          <b-button @click="changeMode" v-else-if="mode=='edit'" size="sm" variant="primary">Save</b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: "TextInputEditSubmit",
  components: {},
  props: ['inputName', 'id'],
  data() {
    return {
      mode: "saved",
      inputText: "",
      inputTextSaved: ""
    };
  },
  methods: {
    changeMode() {
      if (this.mode == 'saved') {
        this.mode = 'edit';
        this.$emit('inputModeChanged', [this.id, 'edit']);
      } else if (this.mode == 'edit') {
        this.mode = 'saved';
        this.inputTextSaved = this.inputText;
        this.$emit('inputModeChanged', [this.id, 'saved']);
      }
    },
    resetMode() {
      if (this.mode == 'edit') {
        this.changeMode();
      }
    },
    clear() {
      this.inputText = "";
      this.inputTextSaved = "";
    }
  },
  mounted() {},
};
</script>

<style scoped>
.label-style {
  font-size: 14px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}
</style>

