<template>
  <div>
    <div id="map-with-sidebar-container">
      <div v-bind:class="[sidebarOpened ? 'toggle-icon-opened-position' : 'toggle-icon-closed-position']">
        <b-icon v-if="!sidebarOpened" @click="toggleSidebar"  font-scale="2.0" icon="chevron-right" class="toggle-icon rounded p-1" variant="dark"></b-icon>
        <b-icon v-if="sidebarOpened"  @click="toggleSidebar"  font-scale="2.0" icon="chevron-left" class="toggle-icon rounded p-1" variant="dark"></b-icon>
      </div>
      <div id="sidebar" ref="sidebar" v-bind:class="[sidebarOpened ? 'sidebar-opened' : 'sidebar-closed']">
          <transition name="fade">
            <div v-show="sidebarOpened">
              <ProductsSidebarContent></ProductsSidebarContent> 
            </div>
          </transition>
      </div>
      <ProductsMap :invalidateSize="invalidateSize"/>
    </div>
    <ProductsDownloadLoadingView/>
    <ProductsReportLoadingView/>
    <ProductsSidebarRight/>
  </div>
</template>

<script>
import ProductsMap from '@/pages/products/Products_Map.vue'
import ProductsSidebarContent from '@/pages/products/Products_SidebarContent.vue'
import ProductsDownloadLoadingView from '@/pages/products/Products_DownloadLoadingView.vue'
import ProductsReportLoadingView from '@/pages/products/Products_ReportLoadingView.vue'
import ProductsSidebarRight from '@/pages/products/Products_SidebarRight.vue'

export default {
  name: "ProductsMain",
  components: {
    ProductsMap,
    ProductsSidebarContent,
    ProductsDownloadLoadingView,
    ProductsReportLoadingView,
    ProductsSidebarRight,
  },
  data() {
    return {
      sidebarOpened: true,
      invalidateSize: null,
    };
  },
  mounted() {
    this.$refs.sidebar.addEventListener("transitionend", this.transitionendHandler);
  },
  beforeDestroy: function() {
    this.$refs.sidebar.removeEventListener("transitionend", this.transitionendHandler);
  },
  methods: {
    /**
     * This function toggles the sidebar container
     */
    toggleSidebar() {
      this.sidebarOpened = !this.sidebarOpened;
    },
    /**
     * This function re-renders the leaflet map (after container resize event)
     */
    transitionendHandler() {
      this.invalidateSize = this.sidebarOpened;
    }
  },
};
</script>

<style scoped>
#map-with-sidebar-container {
  position: absolute;
  height: calc(100% - 66px) !important;
  width: 100% !important;
  overflow-y: hidden !important;
}

#sidebar {
  display: block;
  height: 100%;
  max-width: 100%;
  float: left;
  background-color: white;
  z-index: 1;
  border-right: 1px solid lightgray;
}

/**
 * Sidebar toggle animation (closing)
 */
.sidebar-closed {
  width: 60px !important;
  transition: 0.5s;
}

/**
 * Sidebar toggle animation (opening)
 */
.sidebar-opened {
  width: 500px !important;
  transition:.5s;
} 

/**
 * Animation to display sidebar content with delay (see vue animations)
 */
.fade-enter-active {
  transition: all 0.3s;
  display: none;
}

/**
 * Sidebar open icon
 */
.toggle-icon-opened-position {
  position: absolute;
  top :12px;
  left: 450px;
  transition:.5s;
  z-index: 2;
}

/**
 * Sidebar close icon
 */
.toggle-icon-closed-position {
  position: absolute;
  top:12px;
  left: 12px;
  transition:.5s;
  z-index: 2;
}

/**
 * Sidebar icon hover styling
 */
.toggle-icon:hover{
  cursor: pointer;
  background-color: #f4f4f4;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
.loader {
  margin: 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(73, 73, 73, 0.1);
  border-right: 1.1em solid rgba(73, 73, 73, 0.1);
  border-bottom: 1.1em solid rgba(73, 73, 73, 0.1);
  border-left: 1.1em solid #757171;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-text-title {
  font-size: 18px;
  text-align: center;
  /* color: #757171; */
  font-weight: 800;
}

.loading-text {
  font-size: 14px;
  text-align: center;
  /* color: #757171; */
  font-style: italic;
  
}

</style>
