<template>
  <div>
    <ModalDialogStartup />
    <ModalDialogSessionExpired />
    <ModalDialogChangePassword />
    <ModalDialogGetToken />
    <ModalDialogGetTokenGloFAS />
    <ModalDisclaimer />
  </div>
</template>

<script>
import Vue from "vue";

import ModalDialogStartup from "@/components/ModalDialogStartup.vue";
import ModalDialogSessionExpired from "@/components/ModalDialogSessionExpired.vue";
import ModalDialogChangePassword from "@/pages/profile/ModalDialogChangePassword.vue";
import ModalDialogGetToken from "@/pages/profile/ModalDialogGetToken";
import ModalDialogGetTokenGloFAS from "@/pages/profile/ModalDialogGetTokenGloFAS";
import ModalDisclaimer from "@/components/ModalDisclaimer";

Vue.component("ModalDialogStartup", ModalDialogStartup);
Vue.component("ModalDialogSessionExpired", ModalDialogSessionExpired);
Vue.component("ModalDialogChangePassword", ModalDialogChangePassword);
Vue.component("ModalDialogGetToken", ModalDialogGetToken);
Vue.component("ModalDialogGetTokenGloFAS", ModalDialogGetTokenGloFAS);
Vue.component("ModalDisclaimer", ModalDisclaimer);

export default {
  name: "GlobalComponents",
  data() {
    return {};
  },
};
</script>
