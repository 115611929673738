<template>
  <div>
    <Navbar></Navbar>
    <router-view />
    <GlobalComponents/>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import GlobalComponents from "./components/GlobalComponents.vue";

//import Vue from "vue";

//import ModalDialogStartup from "./components/ModalDialogStartup.vue"
//import ModalDialogSessionExpired from "@/components/ModalDialogSessionExpired.vue"

//Vue.component("ModalDialogStartup", ModalDialogStartup);
//Vue.component("ModalDialogSessionExpired", ModalDialogSessionExpired);

export default {
  name: "App",
  components: {
    Navbar,
    GlobalComponents
  },
  data() {
    return {};
  },
  mounted() {
    // console.log("GFM_BACKEND_URL           :", process.env.VUE_APP_GFM_BACKEND_URL);
    // console.log("GLOFAS_PORTAL             :", process.env.VUE_APP_GLOFAS_PORTAL);
    // console.log("MANUAL_PUM                :", process.env.VUE_APP_GFM_MANUAL_PUM)
    // console.log("MANUAL_PDD                :", process.env.VUE_APP_GFM_MANUAL_PDD)
    // console.log("LEAFLET_BASE_MAP_GRAY     :", process.env.VUE_APP_LEAFLET_BASE_MAP_GRAY)
    // console.log("LEAFLET_BASE_MAP_OSM      :", process.env.VUE_APP_LEAFLET_BASE_MAP_OSM)
    // console.log("LEAFLET_BASE_MAP_SATELLITE:", process.env.VUE_APP_LEAFLET_BASE_MAP_SATELLITE)
    // console.log("AOI_MAX_SIZE_SQUARE_METERS:", process.env.VUE_APP_AOI_MAX_SIZE_SQUARE_METERS)
  }
};
</script>

<style scoped>
</style>
