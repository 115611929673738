<template>
  <div>
    <div>
      <b-overlay :show="showOverlay" rounded="sm" variant="light" spinner-variant="secondary">
        <b-card >
          <b-card-title>
            <div class="row">
              <div class="col">Message configuration</div>
              <div class="col text-right">
                <b-button variant="outline-secondary" size="sm" @click="refreshBtnClicked()"><b-icon class="mr-0" icon="arrow-repeat"></b-icon></b-button>
              </div>
            </div>
          </b-card-title>
          <div class="mb-4"></div>
          <b-alert v-if="!twitterConfigurationDone" variant="success" show>Please complete the Twitter configuration process first!</b-alert>
          <b-alert v-if="showError" show dismissible variant="danger">
            <div class="alert-heading"><strong>Error!</strong> Message configuration could not be loaded..</div>
          </b-alert>
          <div>
            <b-button :disabled="!twitterConfigurationDone" v-b-toggle.collapse-body block class="d-flex">
              <span>Message body</span>
              <!-- replace these with your preferred icons -->
              <strong v-if="bodyTextOpened" aria-hidden="true" class="ml-auto"><b-icon icon="chevron-up"></b-icon></strong>
              <strong v-else aria-hidden="true" class="ml-auto"><b-icon icon="chevron-down"></b-icon></strong>
            </b-button>
            <div class="mt-2"></div>
            <b-collapse id="collapse-body" v-model="bodyTextOpened">
              <b-card>
              <b-form-group>
                <label class="label-style">Message body</label>
                <b-form-textarea
                  v-model="textBody"
                  placeholder="Enter message body ..."
                  rows="2"
                  max-rows="4"
                  maxlength="125"
                ></b-form-textarea>
              </b-form-group>
              <div class="float-right mt-2">
                <b-button @click="deleteBtnClicked" class="mr-2" variant="danger">Delete configuration</b-button>
                <b-button @click="saveBtnClicked" class="mr-0" variant="primary">Save configuration</b-button>
              </div>
              </b-card>
            </b-collapse>
          </div>

          <div>
            <b-button :disabled="!twitterConfigurationDone" v-b-toggle.collapse-header block class="d-flex">
              <span>Message header (can not be changed)</span>
              <strong v-if="headerTextOpened" aria-hidden="true" class="ml-auto"><b-icon icon="chevron-up"></b-icon></strong>
              <strong v-else aria-hidden="true" class="ml-auto"><b-icon icon="chevron-down"></b-icon></strong>
            </b-button>
            <div class="mt-2"></div>
            <b-collapse id="collapse-header" v-model="headerTextOpened">
              <b-card class="mb-2">
                <b-form-group>
                  <label class="label-style">Message Header</label>
                  <b-form-textarea
                    v-model="textHeader"
                    placeholder="Enter message header ..."
                    rows="2"
                    max-rows="4"
                    disabled
                  ></b-form-textarea>
                </b-form-group>
                <div id="placeholders"> 
                  <span class="text-muted"><b>PLACEHOLDERS</b></span> 
                    <ul class="text-muted">
                      <div v-for="(item, key) in twitterPlaceholders" :data="item" v-bind:key="key">
                        <li><span>{{item.name}}</span><span>: </span><span>{{item.description}}</span></li>
                      </div>
                    </ul>
                </div>
              </b-card>
            </b-collapse>
          </div>

          <div>
            <b-button :disabled="!twitterConfigurationDone" v-b-toggle.collapse-footer block class="d-flex">
              <span>Message footer (can not be changed)</span>
              <!-- replace these with your preferred icons -->
              <strong v-if="footerTextOpened" aria-hidden="true" class="ml-auto"><b-icon icon="chevron-up"></b-icon></strong>
              <strong v-else aria-hidden="true" class="ml-auto"><b-icon icon="chevron-down"></b-icon></strong>
            </b-button>
            <div class="mt-2"></div>
            <b-collapse id="collapse-footer" v-model="footerTextOpened">
              <b-card>
              <b-form-group>
                <label class="label-style">Message Footer</label>
                <b-form-textarea
                  v-model="textFooter"
                  rows="2"
                  max-rows="4"
                  disabled
                ></b-form-textarea>
              </b-form-group>
              </b-card>
            </b-collapse>
          </div>
        </b-card>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import axios_services from "@/axios/axios-services.js";

export default {
  name: "NotificationsTwitterMessage",
  components: {},
  computed: {
    twitterConfigurationDone() {
      return this.twitterConfigCompleted;
    },
    clientID() {
      return this.$store.getters.clientID;
    },
  },
  props: ['twitterConfigCompleted'],
  data() {
    return {
      textHeader: "",
      textBody: "",
      textFooter: "",
      headerTextOpened: false,
      bodyTextOpened: false,
      footerTextOpened: false,
      twitterPlaceholders: [
        {"id ": "1", "name": "${FLOOD_DATE}", "description" : "Date of the flood"},
        {"id ": "2", "name": "${AOI_NAME}", "description" : "Name of the AOI"},
      ],
      showOverlay: false,
      showError: false
    };
  },
  mounted() {
    this.loadTwitterMessageContents();
  },
  methods: {
    loadTwitterMessageContents() {
      this.showOverlay = true;
      let user_id = this.clientID;
      axios_services.get(`/notifications/twitter_message_config/${user_id}`)
        .then((response) => {
          this.textHeader = response.data.header;
          this.textBody = response.data.body;
          this.textFooter = response.data.footer;
          this.showError = false;
        })
        .catch((error) => {
          console.log('Error: Notification settings could not be loaded. Details: ', error);
          this.showError = true;
        })
        .finally( () => {
          this.showOverlay = false;
        });
    },
    saveBtnClicked() {
      this.showOverlay = true;
      let payload = {
        "body": this.textBody,
        "user_id": this.clientID
      }
      axios_services.patch('/notifications/twitter_message_config/user', payload)
        .then(() => {
          this.showOverlay = false;
          this.showNotification("success", "Success! Configuration updated", "Message configuration successfully updated");
        })
        .catch((error) => {
          console.log('Error: Notification settings could not be saved. Details: ', error);
          this.showNotification("danger", "Error!", "Message configuration could not be updated");
          this.showOverlay = false;
        });
    },
    
    deleteBtnClicked() {
      this.$parent.$bvModal.msgBoxConfirm('Do you want to delete the message configuration?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then((value) => {
        if (value) {
          this.showOverlay = true;
          let payload = {
            "body": "",
            "user_id": this.clientID
          }
          axios_services.patch('/notifications/twitter_message_config/user', payload)
            .then(() => {
              this.showOverlay = false;
              this.textBody = "";
              this.showNotification("success", "Success! Configuration updated", "Message configuration successfully deleted");
            })
            .catch((error) => {
              console.log('Error: Notification settings could not be deleted. Details: ', error);
              this.showNotification("danger", "Error!", "Message configuration could not be updated");
              this.showOverlay = false;
            }); 
        }
      });  
    },
    refreshBtnClicked() {
      this.loadTwitterMessageContents();
    },
    showNotification(variant, title, content, hideDelay = 4000) {
      this.$parent.$bvToast.toast(content, {
        autoHideDelay: hideDelay,
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  watch: {
    twitterConfigurationDone(v) {
      if (v==false) {
        this.headerTextOpened = false;
        this.bodyTextOpened = false;
        this.footerTextOpened = false;
      }
    }
  }
};
</script>

<style scoped>
.card .label-style {
  font-size: 14px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}
#placeholders span {
  font-size: 14px;
}
</style>
