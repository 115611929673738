<template>
  <div id="login" class="login container">
    <b-card class="card">
      <b-form>
        <!-- <b-button variant="link" class="text-decoration-none" @click="goBack">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon>
          <b-img
            class="image"
            :src="require('./../assets/gfmlogo.png')"
            fluid
            alt="Responsive image"
            width="100%"
          ></b-img>
        </b-button> -->
        <div class="row">
          <div class="col my-auto">
            <b-img
              class="image"
              :src="require('./../assets/gfmlogo.png')"
              fluid
              alt="Responsive image"
              width="100%"
            ></b-img>
          </div>
          <div class="col text-right">
            <b-button variant="link" class="text-decoration-none" @click="goBack">
              <b-icon icon="chevron-left" class="toggle-icon rounded p-1" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </div>
      </b-form>
      <b-form @submit.prevent="onSubmit">
        <h3 align="justify">
          <b>Create new password</b>
        </h3>
        <p
          class="p"
        >Your new password should be at least 8 characters long and contain both letters and numbers</p>
        <b-alert v-model="showAlert" variant="danger" dismissible>{{alertMsg}}</b-alert>
        <b-alert v-model="showSuccess" variant="success" dismissible>Successfully changed password! Redirect in 3 seconds.</b-alert>
        <b-form-group class="font-bold" id="input-group-1" label="New password" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.password"
            type="password"
            required
            v-bind:class="{ pwalert: showAlert }"
            placeholder="Enter new password"
          ></b-form-input>
        </b-form-group>

        <b-form-group class="font-bold" id="input-group-2" label="Confirm password" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.password2"
            type="password"
            required
            v-bind:class="{ pwalert: showAlert }"
            placeholder="Confirm new password"
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" block>Set new password</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        password2: ""
      },
      showAlert: false,
      alertMsg: "",
      showSuccess: false,
    };
  },
  methods: {
    onSubmit() {
      if (this.form.password != this.form.password2) {
        this.alertMsg = "Passwords must be similar";
        this.showAlert = true;
      } else if (this.form.password.length < 8) {
        this.alertMsg = "Password must contain at least 8 characters";
        this.showAlert = true;
      }else {
        this.alertMsg = "";
        this.showAlert = false;

        const formData = {
          reset_token: this.$route.query["reset-token"],
          password: this.form.password
        };
        /* eslint-disable */
        this.$store.dispatch("resetPassword", formData).then(
          response => {
            this.showSuccess = true;

            var ref = this;
            setTimeout(function(){ ref.$router.push("/login"); }, 3000)
          }
        );
        /* eslint-enable */
      }
    },
    goBack() {
      this.$router.push("/login")
    }
  }
};
</script>

<style scoped>
.login {
  width: 400px;
  padding-top: 30px;
}

.card {
  border-color: #212529;
  border-width: 2px;
}

.p {
  font-size: 14px;
}

.pwalert {
  border-color: rgba(240, 36, 36, 0.397);
  border-width: 1px;
}

.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn .b-icon.bi {
  font-size: 250%;
  vertical-align: middle;
}

.btn-link {
  color: #000000;
}

/**
 * Sidebar icon hover styling
 */
.toggle-icon:hover{
  cursor: pointer;
  background-color: #f4f4f4;
}

.font-bold {
  font-weight: bold;
}
</style>
