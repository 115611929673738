<template>
  <div class="container mt-4">
    <b-overlay :show="!formDataLoaded || showOverlay" spinner-variant="secondary">
      <b-card title="Code Snippet">
        <p class="text-muted card-info-text">
          The code snippet tool allows you to generate an inline frame widget which can be integrated into any HTML page. 
        </p>
        
        <b-tabs content-class="mt-3" v-model="tabIndex">
          <b-tab title="Step 1 - Generate Map">
            <b-form>
              <div class="row">
                <div class="col">
                  <b-form-group>
                    <label class="label-style">AOI Name</label>
                    <div id="list-select-aoi">
                      <list-select
                        :list="aoiItemList"
                        @select="onSelect"
                        :selected-item="formData.selectedAoiItem"
                        option-value="code"
                        option-text="name"
                        placeholder="Select AOI"
                      >
                      </list-select>
                    </div>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col col">
                  <b-form-group>
                    <label class="label-style">Product Layer</label>
                    <b-form-select v-model="formData.productLayerValue" :options="productLayerOptions">
                      <template #first>
                        <b-form-select-option :value="null" disabled>Select product layer</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col col">
                  <b-form-group>
                    <label class="label-style">Product Date</label>
                    <b-form-select v-model="formData.productDateValue" :options="productDateOptions" :disabled="aoiProductsLoaded == false">
                      <template #first>
                        <b-form-select-option :value="null" disabled>Select product date</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
                <div class="col">
                  <b-form-group>
                    <label class="label-style">Map Type</label>
                    <b-form-select v-model="formData.mapTypeValue" :options="mapTypeOptions">
                      <template #first>
                        <b-form-select-option :value="null" disabled>Select map type</b-form-select-option>
                      </template>
                    </b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col col">
                  <b-form-group>
                    <label class="label-style">AOI Extent</label>
                    <b-form-checkbox
                      id="checkbox-aoi-extent"
                      v-model="formData.applyAoiExtent"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      Show AOI extent
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col col">
                  <b-form-group>
                    <label class="label-style">Satellite Extent</label>
                    <b-form-checkbox
                      id="checkbox-satellite-extent"
                      v-model="formData.applySatelliteExtent"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      Show satellite extent
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col text-right">
                  <b-button variant="secondary mr-2" @click="refresh()">Refresh</b-button>
                  <b-button variant="primary" :disabled="isSubmitBtnLocked" @click="submitBtnClicked()">Generate map</b-button>
                </div>
              </div>
            </b-form>
          </b-tab>
          <b-tab :disabled="step2Disabled" title="Step 2 - Preview">
            <CodeSnippetMap :data="this.mapData"  v-on:changeTab="onChangeTab"/>
          </b-tab>
        </b-tabs>

      </b-card>
    </b-overlay>

    <div class="mb-4"></div>

    <div v-if="mapGenerated">
      <b-card title="Preview">
        <!-- <CodeSnippetMap :data="mapData" v-on:childToParent="onChildClick"/> -->
      </b-card>
    </div>

  </div>
</template>

<script>
import { ListSelect } from "vue-search-select";
import CodeSnippetMap from "@/pages/more/More_CodeSnippetMap.vue";
import axios_services from "@/axios/axios-services.js";

export default {
  name: "CodeSnippet",
  components: {
    ListSelect,
    CodeSnippetMap,
  },
  data() {
    return {
      step2Disabled: true,
      tabIndex: 0,

      showOverlay: false,
      aoiDataLoaded: false,
      mapGenerated: false,
      aoiProductsLoaded: false,

      aois: null,
      aoiItemList: [],

      formData: {
        selectedAoiItem: {},
        productLayerValue: null,
        productDateValue: null,
        mapTypeValue: null,
        applyAoiExtent:  'accepted',
        applySatelliteExtent: 'accepted'
      },

      productLayerOptions: [
        { text: "Observed Flood Extent", value: "observed_flood_extent" },
        { text: "Observed Water Extent", value: "observed_water_extent" },
        { text: "Reference Water Mask", value: "reference_water_mask" },
        { text: "Exclusion Mask", value: "exclusion_mask" },
        { text: "Uncertainty Values", value: "uncertainty_values" },
        { text: "Affected Population", value: "affected_population" },
        { text: "Affected Landcover", value: "affected_landcover" },
      ],
      mapTypeOptions: [
        { text: "Light Gray Map", value: "mapGray" },
        { text: "Open Street Map", value: "mapOSM" },
        { text: "Satellite Map", value: "mapSatellite" },
      ],
      productDateOptions: null,

      text: '',
      mapData: {}
    };
  },
  computed: {
    isSubmitBtnLocked() {
      if (
        (Object.keys(this.formData.selectedAoiItem).length == 0 ) ||
        (this.formData.productLayerValue == null) ||
        (this.formData.productDateValue == null) ||
        (this.formData.mapTypeValue == null)
      ) {
        return true;
      } else {
        return false;
      }
    },
    formDataLoaded() {
      if (this.aoiDataLoaded) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    /**
     * Triggered when `changeTab` event is emitted by the child.
     */
    onChangeTab (value) {
      this.tabIndex = value;
    },
    /**
     *
     * Eventhandler for the multi-select input widget
     */
    onSelect(selectedAoiItem) {
      this.productDateOptions = [];
      this.formData.selectedAoiItem = selectedAoiItem;
      
      if (selectedAoiItem.code == null) {
        this.aoiProductsLoaded = false;
        this.formData.productDateValue = null;
      } else {
        this.aoiProductsLoaded = true;
        this.getProducts();
      }      
    },
    /**
     * This function initializes the personal data form
     */
    initFormData() {
      // Load aois
      this.aoiItemList = [];
      this.aoiDataLoaded = false;
      this.$store
        .dispatch("getAOIs")
        .then(() => {
          this.aois = this.$store.getters.getAOIs;
          this.aois.forEach((element) => {
            var d = { code: element.aoi_id, name: element.aoi_name };
            this.aoiItemList.push(d);
          });
          this.aoiDataLoaded = true;
        })
        .catch(() => {
          this.aoiDataLoaded = true;
        });
    },
    /**
     * This function retrieves the pruducts for a selectd AOI
     */
    getProducts() {
      // Retrieve products
      var payload = {
        'aoi_id': this.formData.selectedAoiItem.code,
        'filterOption' : 'all'
      };

      this.showOverlay = true;
      this.$store.dispatch('getProducts', payload).then( () => {
        let sortedProducts =  this.$store.getters.getProducts.products.sort((a, b) => (a.product_time < b.product_time) ? 1 : -1);
        sortedProducts.forEach((element => {
          var d = {value: element.product_id, text: element.product_time}
          this.productDateOptions.push(d);
        }));
      }).finally(() => {
        this.showOverlay = false;
      });
    },
    /**
     * Event handler for the submit button
     */
    async submitBtnClicked() {
      // Get product date
      let productElement = this.productDateOptions.find(x => x.value == this.formData.productDateValue);
      let productDate = productElement.text;

      // GeoJSON of the selected aoi
      let aoiExtentGeoJSON = this.aois.find(x => x.aoi_id == this.formData.selectedAoiItem.code).geoJSON;

      // Get GeoJSON of satellite extent and geoserver base url
      let productData = await this.getSatelliteExtentData(this.formData.productDateValue);

      let satelliteExtentGeoJSON = productData[0].layer_boundary;
      let geoserverBaseUrl = productData[0].geoserver_base_url;
      
      // Legend data
      let legendData = await this.getLegendData(this.formData.productLayerValue);

      this.mapData = {
        'showAoiExtent': (this.formData.applyAoiExtent === 'not_accepted' ? false : true),
        'showSatelliteExtent': (this.formData.applySatelliteExtent === 'not_accepted' ? false : true),
        'productLayer': this.formData.productLayerValue,
        'productDate': productDate,
        'aoiExtentCoordinates': aoiExtentGeoJSON.coordinates[0],
        'satelliteExtentCoordinates': satelliteExtentGeoJSON.coordinates[0],
        'geoserverBaseUrl': geoserverBaseUrl,
        'baseMapType' : this.formData.mapTypeValue,
        'legendData': legendData,
      }

      this.step2Disabled = false;
      this.$nextTick(() => {
        this.tabIndex = 1;
      });
    },
    /**
     * Fetches the satellite extent data
     */
    async getSatelliteExtentData(productID) {
      let url = 'products/' + productID;
      const response = await axios_services.get(url);
      return response.data;
    },
    /**
     * This function retrieves the data for the map legend
     */
    async getLegendData(productLayerName) {
      let url = 'legends';
      const response = await axios_services.get(url);

      let layerID = this.getLayerID(productLayerName)
      const legendItem = response.data.find(element => element.layer_id == layerID);

      return legendItem;  
      
    },
    /**
     * Event handler for the refresh button
     */
    refresh() {
      this.initFormData();
      this.mapGenerated = false;
      this.formData.selectedAoiItem = {};
      this.formData.mapTypeValue = null;
      this.formData.productLayerValue = null;
      this.formData.applyAoiExtent = 'accepted';
      this.formData.applySatelliteExtent = 'accepted';
      this.productDateOptions = [];
      this.formData.productDateValue = null;
      this.aoiProductsLoaded = false;
    },
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true,
      });
    },
    /**
     * This function return the layer-id for a layer name
     */
    getLayerID(layerName) {
      return layerName == 'observed_flood_extent' ? 1
          :  layerName == 'observed_water_extent' ? 2
          :  layerName == 'reference_water_mask' ? 3
          :  layerName == 'exclusion_mask' ? 4
          :  layerName == 'uncertainty_values' ? 5        
          :  layerName == 'affected_population' ? 10
          :  layerName == 'affected_landcover' ? 11                
          :  null;
      }
  },
  mounted() {
    this.initFormData();
  },
  watch: {
    tabIndex(vNew) {
      if (vNew == 0) {
        this.refresh();
        this.step2Disabled = true;
      }
    }
  }
};
</script>

<style scoped>
.card .label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

.card-text-font {
  font-style: normal;
}

.card .card-info-text {
  font-size: 14px;
}
</style>

<style >
.ui.default.dropdown:not(.button)>.text, .ui.dropdown:not(.button)>.default.text {
    color: #495057 !important;
}
</style>

