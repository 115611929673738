<template>
  <div>
    <b-overlay :show="showOverlay==true" rounded="sm" variant="light" spinner-variant="secondary">
      <b-card title="">
        <b-card-title>
          <div class="row">
            <div class="col">Twitter account</div>
            <div class="col text-right">
              <b-button variant="outline-secondary" size="sm" @click="loadUserKeys()"><b-icon class="mr-0" icon="arrow-repeat"></b-icon></b-button>
            </div>
          </div>
        </b-card-title>
        <b-alert v-if="showMissingValuesAlert" show dismissible variant="warning" @dismissed="alertClosedClicked()">
          <div class="alert-heading"><strong>Warning!</strong> Some values are missing.</div>
        </b-alert>
        <b-alert v-if="showLoadingError" show dismissible variant="danger">
          <div class="alert-heading"><strong>Error!</strong> Configuration could not be loaded..</div>
        </b-alert>
        <TextInputEditSubmit @inputModeChanged="onInputModeChanged" ref="ref_consumerKey" inputName='consumer key' id='consumerKey'/>
        <TextInputEditSubmit @inputModeChanged="onInputModeChanged" ref="ref_consumerSecret" inputName='consumer secret' id='consumerSecret' />
        <TextInputEditSubmit @inputModeChanged="onInputModeChanged" ref="ref_accessToken" inputName='access token' id='accessToken'/>
        <TextInputEditSubmit @inputModeChanged="onInputModeChanged" ref="ref_secretKey" inputName='access token secret key' id='secretKey'/>
        <div class="float-right mt-2">
          <b-button class="mr-2" variant="danger"  @click="showDeleteConfigurationModal()" :disabled="deleteBtnDisabled" >Delete configuration</b-button>
          <b-button class="mr-0" variant="primary" @click="saveConfiguration()" :disabled="saveBtnEnabled">Save configuration</b-button>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import TextInputEditSubmit from "@/components/TextInputEditSubmit.vue"
import axios_services from '@/axios/axios-services.js'
import {eventBus} from "@/main.js";

export default {
  name: "NotificationsTwitterConfiguration",
  components: {
    TextInputEditSubmit
  },
  computed: {
    deleteBtnDisabled() {
      var disabled = false;
      if (!this.configurationDone || this.saveBtnEnabled) {
        disabled = true;
      } 
      return disabled;
    }
  },
  data() {
    return {
      showOverlay: true,
      saveBtnEnabled: false,
      lockSaveBtnStateDict: {},
      showMissingValuesAlert: false,
      showLoadingError: false,
      configurationDone: false,
    };
  },
  mounted() {
    this.loadUserKeys();
  },
  methods: {
    refreshInputs() {
      this.$refs.ref_consumerKey.resetMode();
      this.$refs.ref_consumerSecret.resetMode();
      this.$refs.ref_accessToken.resetMode();
      this.$refs.ref_secretKey.resetMode();
      this.showMissingValuesAlert = false;
    },
    clearInputs() {
      this.$refs.ref_consumerKey.clear();
      this.$refs.ref_consumerSecret.clear();
      this.$refs.ref_accessToken.clear();
      this.$refs.ref_secretKey.clear();
    },
    saveConfiguration() {
      if (
        (this.$refs.ref_consumerKey.$data.inputTextSaved == "") ||
        (this.$refs.ref_consumerSecret.$data.inputTextSaved == "") ||
        (this.$refs.ref_accessToken.$data.inputTextSaved == "") ||
        (this.$refs.ref_secretKey.$data.inputTextSaved == "")
      ) {
        this.showMissingValuesAlert = true;
        return;
      }

      // Check if a post or update operation is required 
      this.showOverlay = true;

      var payload = {
        "consumer_key": this.$refs.ref_consumerKey.$data.inputTextSaved,
        "consumer_secret": this.$refs.ref_consumerSecret.$data.inputTextSaved,
        "access_token": this.$refs.ref_accessToken.$data.inputTextSaved,
        "access_token_secret": this.$refs.ref_secretKey.$data.inputTextSaved,
        "user_id": this.$store.getters.clientID
      };
    
      if (!this.configurationDone) {
        // post
        axios_services
          .post("notifications/twitter_settings/create", payload)
          .then(() => {
            this.showOverlay = false;
            this.showNotification("success", "Success!", "Twitter accout successfully created");
            this.loadUserKeys();
        }).catch((err) => {
          console.log("Error: ", err);
          this.showLoadingError = true;
          this.showNotification("danger", "Error!", "Twitter accout could not be created");
          this.showOverlay = false;
        });
      } else {
        // update
        axios_services
          .put("notifications/twitter_settings/update", payload)
          .then(() => {
            this.showOverlay = false;
            this.showNotification("success", "Success!", "Twitter accout successfully updated");
            this.loadUserKeys();
        }).catch((err) => {
          console.log("Error: ", err);
          this.showNotification("danger", "Error!", "Twitter accout could not be updated");
          this.showOverlay = false;
        });
      }

    },
    loadUserKeys() {
      this.showOverlay = true;
      axios_services
        .get(`notifications/twitter_settings/${this.$store.getters.clientID}`)
        .then((response) => {
          if (Object.keys(response.data).length === 0) {
            this.configurationDone = false;
            this.clearInputs();
            this.refreshInputs();
          } else {
            this.$refs.ref_consumerKey.$data.inputText = response.data.consumer_key;
            this.$refs.ref_consumerSecret.$data.inputText = response.data.consumer_secret;
            this.$refs.ref_accessToken.$data.inputText = response.data.access_token;
            this.$refs.ref_secretKey.$data.inputText = response.data.access_token_secret;
            this.$refs.ref_consumerKey.$data.inputTextSaved = response.data.consumer_key;
            this.$refs.ref_consumerSecret.$data.inputTextSaved = response.data.consumer_secret;
            this.$refs.ref_accessToken.$data.inputTextSaved = response.data.access_token;
            this.$refs.ref_secretKey.$data.inputTextSaved = response.data.access_token_secret;
            this.refreshInputs();
            this.configurationDone = true;
          }
          // Update 
          eventBus.$emit('update-notification-configuration');
          this.showOverlay = false;
      }).catch((err) => {
        console.log("Error: ", err);
        this.showLoadingError = true;
        this.showOverlay = false;
      });
    },
    showDeleteConfigurationModal() {
      this.$parent.$bvModal.msgBoxConfirm('Do you want to delete your Twitter account?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then((value) => {
        if (value) {
          this.deleteConfiguration();
        }
      });  
    },
    deleteConfiguration() {
      this.showOverlay = true;
      axios_services
        .delete(`notifications/twitter_settings/delete/${this.$store.getters.clientID}`)
        .then(() => {
          this.showNotification("success", "Success!", "Twitter accout successfully deleted");
          this.showOverlay = false;
          this.loadUserKeys();
      }).catch((err) => {
        console.log("Error: ", err);
        this.showNotification("danger", "Error!", "Twitter accout could not be deleted");
        this.showOverlay = false;
      });
    },
    onInputModeChanged(v) {
      this.lockSaveBtnStateDict[v[0]] = v[1];
      var _state = false;
      for (let k in this.lockSaveBtnStateDict) {
        if (this.lockSaveBtnStateDict[k] == 'edit') {
          _state = true;
        } 
      }
      this.saveBtnEnabled = _state;
    },
    alertClosedClicked() {
      this.showMissingValuesAlert = false;
    },
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 2000,
        title: title,
        variant: variant,
        solid: true
      });
    },
  },
  watch: {
    configurationDone() {
      this.$store.dispatch("fetchUser");
    }
  }
};
</script>

<style scoped>

</style>
