import Vue from "vue";
import store from "./store/store.js";

import { jsPDF } from "jspdf";
import "jspdf-autotable";
import domtoimage from "dom-to-image";
import axios_services from "@/axios/axios-services.js";

import "@/assets/css/global.css";
import "@/assets/fonts/Roboto-Regular-normal.js";
import "@/assets/fonts/Roboto-Bold-normal.js";

// Header text
const headerTextLine1 = "The Global Flood Monitoring (GFM) product is a component of the EU’s Copernicus Emergency Management";
const headerTextLine2 = "Service (CEMS) that provides continuous monitoring of floods worldwide by processing and analysing in near";
const headerTextLine3 = "real-time all incoming Synthetic Aperture Radar (SAR) imagery acquired by the Sentinel-1 satellites.";

let reportService = new Vue({
  store,
  methods: {
    createReport(productID, data) {
      return new Promise((resolve, reject) => {
        createReport(productID, data)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});

/**
 * This functin creates a PDF report for a product
 */
async function createReport(productID, reportData) {

  // Create pfd object
  const doc = new jsPDF({ orientation: "p", unit: "mm" });

  var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  pageWidth = pageWidth + 1;
  //var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

  var yStart = 0;
  const margin = 20;
  const spacingHeader = 2;
  const d = 5;

  // ** Header ** 

  // Rectangle
  doc.setDrawColor(0);
  doc.setFillColor(245, 170, 52);
  doc.rect(margin, 15, pageWidth - 2 * margin, 25, "F");

  // Header text
  doc.setFont("roboto-bold");
  doc.setFontSize(22);
  doc.setTextColor(255, 255, 255);

  doc.text("GFM", pageWidth - margin - 5, 26, { align: "right" });
  doc.text("Report", pageWidth - margin - 5, 34, { align: "right" });

  // Header logo
  let dimensions2 = getImageDimensions(1909, 835, 38);
  let gfmLogo = require("./assets/logo-em_white.png");
  let imgMap2 = await getDataFromUri2(gfmLogo);
  doc.addImage(imgMap2, "png", margin + 5, 19, dimensions2[0], dimensions2[1]);

  // ** Opening text **

  yStart = 50;
  doc.setTextColor(0, 0, 0);
  doc.setFont("roboto");
  doc.setFontSize(10);
  doc.text(
    [headerTextLine1, headerTextLine2, headerTextLine3],
    margin,
    yStart
  );

  // ** Section 1 ** 

  yStart = 70;
  doc.setFontSize(14);
  //doc.setTextColor(245, 170, 52);
  doc.setTextColor(0, 0, 0);
  doc.setFont("roboto-bold");
  doc.text("PRODUCT DETAILS", margin, yStart);

  doc.setFontSize(10);
  doc.setFont("roboto");
  doc.setTextColor(0, 0, 0);
  
  // Display prouct data
  doc.text("Product:", margin, yStart + spacingHeader + d * 1);
  doc.text("AOI Name:", margin, yStart + spacingHeader + d * 2);
  doc.text("Timestamp:", margin, yStart + spacingHeader + d * 3);
  doc.text("Layer:", margin, yStart + spacingHeader + d * 4);

  doc.text(reportData.identifier.toString(), margin + 35, yStart + spacingHeader + d * 1);
  doc.text(reportData.aoiName.toString(), margin + 35, yStart + spacingHeader + d * 2);
  doc.text(reportData.timestamp.toString(), margin + 35, yStart + spacingHeader + d * 3);
  doc.text(reportData.layer.toString(), margin + 35, yStart + spacingHeader + d * 4);

  // ** Section 2 ** 

  // Get report statistics
  let reportStatistics = await fetchReportStatistics(productID);

  let affetctedLandcover = reportStatistics.affected_landcover;
  let affetctedPopulation = reportStatistics.affected_population;

  // Landcover
  // Check the number of keys and determine if table is visible
  let showLandcoverTable = true
  if (affetctedLandcover == null) {
    showLandcoverTable = false;
  }

  // Population
  let affetctedPopulationStr = '-';
  if (affetctedPopulation != null) {
    affetctedPopulationStr = affetctedPopulation.toString();
  }

  // Display statistics
  yStart = 102;
  doc.setFontSize(10);
  doc.setFont("roboto-bold");
  doc.text("STATISTICS", margin, yStart);

  yStart = yStart + spacingHeader;
  doc.setFont("roboto");
  doc.text("Affected Population:", margin, yStart + d * 1);
  doc.text(affetctedPopulationStr, margin + 35, yStart + d * 1);
  doc.text("Affected Landcover", margin, yStart + d * 2);
  
  let yEnd;
  if (!showLandcoverTable){
    doc.text("-", margin + 35, yStart + d * 2);
    yEnd = 126;
  } else {
    let statisticsPart1 = splitStatistics(affetctedLandcover)[0];
    let statisticsPart2 = splitStatistics(affetctedLandcover)[1];
    let tableSpacingCount = statisticsPart1.length;
    
    let counter = 0;
    let showRightTable = true;
    for (const obj of statisticsPart2) {
      if (obj.landcoverValue != '') counter++;
    }
    if (counter === 0) {
      showRightTable = false;
    }

    var tableLeftWidth;
    if (showRightTable == false) {
      tableLeftWidth = pageWidth - (2*margin);
    } else {
      tableLeftWidth = 80;
    }

    doc.autoTable({
      columns: [
        { dataKey: "landcoverClass", header: "Landcover Type" },
        { dataKey: "landcoverValue", header: "Area [m²]" },
      ],
      body: bodyRows(statisticsPart1),
      startY: 115,
      margin: { left: 20 },
      tableWidth: tableLeftWidth,
      font: "roboto",
      styles: { cellPadding: 1.2, fontSize: 8 },
      headStyles: { fillColor: "#808080" },
    });
    
    if (showRightTable) {
      doc.autoTable({
        columns: [
          { dataKey: "landcoverClass", header: "Landcover Type" },
          { dataKey: "landcoverValue", header: "Area" },
        ],
        body: bodyRows(statisticsPart2),
        startY: 115,
        margin: { left: 110 },
        tableWidth: 80,
        font: "roboto",
        styles: { cellPadding: 1.2, fontSize: 8 },
        headStyles: { fillColor: "#808080" },
      });
    }
    
    let tableHeaderHeight = 10;
    let tableRowHeight = 5;
    let tableMarginBottom = 9;
    yEnd = 115 + (tableHeaderHeight + (tableSpacingCount*tableRowHeight)) + tableMarginBottom;
  }

  // ** Section 3 **

  yStart = yEnd;
  doc.setFontSize(14);
  doc.setFont("roboto-bold");
  //doc.setTextColor(245, 170, 52);
  doc.setTextColor(0, 0, 0);
  doc.text("PRODUCT MAP", margin, yStart);

  yStart = yStart + spacingHeader + 2;

  // Dispaly map
  let imgTagValuesMap = await createImgTag("map");
  let imgTagMap = imgTagValuesMap[0];
  let imgWidthMap = imgTagValuesMap[1];
  let imgHeightMap = imgTagValuesMap[2];

  let imgMap = await getDataFromUri2(imgTagMap);
  let widhtNew = pageWidth - 2 * margin;
  let dimensions = getImageDimensions(imgWidthMap, imgHeightMap, widhtNew);

  doc.addImage(imgMap, "jpg", margin, yStart, dimensions[0], dimensions[1]);

  // Footer rectangle
  // doc.setDrawColor(0);
  // doc.setFillColor(245,170,52);
  // doc.rect(0, pageHeight-12, 210, pageHeight-10, 'F'); //Fill and Border

  // doc.setFont('roboto-bold');
  // doc.setFontSize(12);
  // doc.setTextColor(255,255,255);
  // doc.text("http://gfm.portal.geoville.com", pageWidth / 2, pageHeight - 5, {align: 'center'});

  // Save report
  var today = new Date();

  let year = today.getFullYear().toString();
  let month = (today.getMonth()+1).toString().padStart(2, "0");
  let day = (today.getDate()).toString().padStart(2, "0");
  let hour = (today.getHours()).toString().padStart(2, "0");
  let minute = (today.getMinutes()).toString().padStart(2, "0");

  let fileName = 'GFM_Report' + '__' + year + '_' + month + '_' + day + '__' + hour + '_' + minute + '.pdf';
  doc.save(fileName);

  // Finally delete image node
  let tmpMap = document.getElementById("temp-map");
  tmpMap.remove();
}

function fetchReportStatistics(productID) {
  let url = process.env.VUE_APP_GFM_BACKEND_URL.concat(`reporting/report_statistics/${productID}`);

  return new Promise((resolve, reject) => {
    axios_services
    .get(url)
    .then( (res) => {
        resolve(res.data);
      })
    .catch( (err) => {
      reject(err);
    });
  });
}

function splitStatistics(statistics) {
	let arr = [];

	for (const [key, value] of Object.entries(statistics)) {
    // Avoid too long strings
    let newKey;
    if (key.includes('Cultivated and managed vegetation/agriculture (cropland)')) {
      newKey = 'Agriculture / Cropland';
      arr.push({ landcoverClass: newKey, landcoverValue: value });
    } else {
      arr.push({ landcoverClass: key, landcoverValue: value });
    }
	}

  let arrPart1 = arr.slice(0,5);
  let arrPart2 = arr.slice(5,10);

  // if (arrPart1.length < 5) {
  //   let missingValues = 5 - arrPart1.length;
  //   for (let j = 0; j < missingValues; j++) {
  //     arrPart1.push({ landcoverClass: "", landcoverValue: "" });
  //   }
  // }
  
  // if (arrPart2.length < 5) {
  //   let missingValues = 5 - arrPart2.length;
  //   for (let j = 0; j < missingValues; j++) {
  //     arrPart2.push({ landcoverClass: "", landcoverValue: "" });
  //   }
  // }
    
  return [arrPart1, arrPart2];
}

function createImgTag(element) {
  var node = document.getElementById(element);

  return new Promise((resolve) => {
    const x = {
      width: node.offsetWidth,
      height: node.offsetHeight,
    };
    domtoimage
      .toPng(node, x)
      .then(function(dataUrl) {
        var img = new Image();
        img.id = "temp-map";
        img.src = dataUrl;
        document.body.appendChild(img);

        resolve([img.src, node.offsetWidth, node.offsetHeight]);
      })
      .catch(function(error) {
        console.error("Error:", error);
      });
  });
}

function getDataFromUri2(url) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = function() {
      var canvas = document.createElement("canvas");

      canvas.width = this.naturalWidth;
      canvas.height = this.naturalHeight;

      var ctx = canvas.getContext("2d");
      ctx.fillStyle = "#f6aa34"; /// set white fill style
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      canvas.getContext("2d").drawImage(this, 0, 0);

      resolve(canvas.toDataURL("image/jpeg"));
    };
    img.onerror = reject;
    img.src = url;
  });
}

function getImageDimensions(widthOriginal, heightOriginal, widhtNew) {
  const heightNew = (heightOriginal / widthOriginal) * widhtNew;
  return [widhtNew, heightNew];
}

function bodyRows(tableData) {
  var body = [];
  for (var j = 1; j <= tableData.length; j++) {
    body.push({
      landcoverClass: tableData[j-1].landcoverClass,
      landcoverValue: numberWithSpaces(tableData[j-1].landcoverValue)
    });
  }
  return body;
}

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}



export default {
  install: function(Vue) {
    Vue.prototype.$reportService = reportService;
  },
};
