<template>
  <div> 
    <!-- Sidebar header -->
    <div id="sidebar-header" class="container-fluid">
      <div id="sidebar-header-font">Products</div>
    </div>

    <!-- Sidebar content with scrollbar -->
    <!-- <b-overlay :show="true" rounded="sm" class="mt-2"> -->
    <div id="sidebar-content-outer">
      <div id="sidebar-content-inner" class="container-fluid">
        <FilterProducts :filterBtnBusyFromLoading="productsLoadingStatus"/>
        
        <hr class="mt-2 mb-2"/>  

        <div v-if="productsLoadingStatus==true">
          <div class="d-flex justify-content-center mt-3">
            <b-spinner variant="secondary" label="Loading"></b-spinner>
          </div>
        </div>
        <div v-else-if="productsLoadingStatus==false">
          <div v-if="productsLoadingError != null">
            <b-alert show dismissible variant="danger">
              <div class="alert-heading"><strong>Error!</strong> Products could not be loaded.</div>
              <div><small>{{productsLoadingError}}</small></div>
            </b-alert>
          </div>
          <div v-else>
            <div v-if="numberOfProducts > 0">
              <div class="row mb-2">
                <div class="col-sm col-10">
                  <span class="mr-3 products-label" style="font-size: 14px">Number of products: {{numberOfProducts}}</span>
                </div>
              </div>  
              <b-form-group label-cols="3" content-cols-sm="9" label-size="sm" label="Sort products:" label-for="input-sm">
                <b-form-select :options="sortOptions" v-model="selectedSortOption" size="sm"></b-form-select>
              </b-form-group>

              <div class="mb-2 text-right">
                  <DownloadAllProductsAndFloodExtent :productMetaInformation="this.procuctsMetaData" :numberOfProducts=this.numberOfProducts :timeRangeForFloodExtentCorrect="true"/>
              </div> 

              <div v-for="(item, key) in this.products" :data="item" v-bind:key="key">
                <ProductItem 
                  v-on:notification_cardClicked="receiver_cardItemClicked"
                  :cardData="item" 
                  :cardID="'prod_' + item.product_id"
                  :cardMetaData="procuctsMetaData"
                  :cardCurrent="selectedCard">
                </ProductItem>
              </div>
            </div>
            <div v-else>
              <p class="products-label">No products could be found!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-overlay> -->
  </div>
</template>


<script>
import FilterProducts from '@/pages/products/Products_FilterProducts.vue'
import ProductItem from '@/pages/products/Products_ProductItem.vue'
import DownloadAllProductsAndFloodExtent from '@/pages/products/Products_DownloadAllProductsAndFloodExtent.vue'
import {eventBus} from "@/main.js";

export default {  
  name: "ProductsSidebarContent",
  components: {
    FilterProducts,
    ProductItem,
    DownloadAllProductsAndFloodExtent
  },
  data() {
    return {
      currentCardID: '',
      sortOptions: [
        { value: 'sort_date_desc', text: 'Sort products by date descending (default)' },
        { value: 'sort_date_asc', text: 'Sort products by date ascending' },
      ],
      selectedSortOption: 'sort_date_desc',
      timeRangeForFloodExtentCorrect: false
    };
  },
  computed: {
    aois() {
      return this.$store.getters.getAOIs;
    },
    products() {
      var _products = this.$store.getters.getProducts.products;
      
      var sortedProducts
      if (this.selectedSortOption == 'sort_date_desc') {
        sortedProducts = _products.sort((a, b) => (a.product_time < b.product_time) ? 1 : -1);
      } else {
        sortedProducts = _products.sort((a, b) => (a.product_time > b.product_time) ? 1 : -1);
      }

      return sortedProducts;
    },
    procuctsMetaData() {
      var metaData = {
        'aoiName': this.getAOINameForID(this.$store.getters.getProducts.aoi_id),
        'aoiID': this.$store.getters.getProducts.aoi_id,
        'layerBoundary': ''
      }
      return metaData;
    },
    selectedCard() {
      return this.currentCardID;
    },
    numberOfProducts() {
      if (this.products != null) {
        return this.products.length;
      } else {
        return '-'
      }
    },
    productsLoadingStatus() {
      return this.$store.getters.getProductsLoadingStatus;
    },
    productsLoadingError() {
      return this.$store.getters.getProductsLoadingError;
    },
    showProducts() {
      if ((this.products != null) && (this.productsLoadingStatus == false)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    /**
     * This function sets the height of the sidebar parent container
     */
    initSidebarHeight() {
      // Get the height of the sidebar and the sidebar header
      var sidebarHeight = document.getElementById("sidebar").offsetHeight;
      var sidebarHeaderHeight = document.getElementById("sidebar-header").offsetHeight;

      // Calculate new height and resize outer container
      var _height = sidebarHeight - sidebarHeaderHeight;
      var _height_str = String(_height) + "px";

      document.getElementById("sidebar-content-outer").style.height = _height_str;
    },
    /**
     * This function handles the window resize event
     */
    handleResize() {
      this.initSidebarHeight();
    },
    /**
     * This function receives a notification from the child component if the card gets seleced
     */
    receiver_cardItemClicked(cardID) {
      this.currentCardID = cardID;
    },
    /**
     * This function returns the aoi name for an aoi-id
     */
    getAOINameForID(aoiID) {
      return this.aois.find(x => x.aoi_id === aoiID).aoi_name;
    },
    /**
     * This function loads a spezific aoi. The aoi-id is passed through a path parameter 
     */
    loadProductFromNotification(aoiID, productID, filterOptions) {
      // Retrieve product  list with pre-defined payload
      var payload = {
        'aoi_id': aoiID,
        'filterOption' : filterOptions,
      };

      // Fetch product data
      this.$store.dispatch('getProducts', payload).then(() => {
        // Highlight active item
        var activeItem = 'prod_' + productID;
        this.$store.commit('setProductsActiveItem', activeItem);

        // Set sidebar element 
        eventBus.$emit('clear-filter');
        eventBus.$emit('close-filter-box');  
        eventBus.$emit('select-aoi', aoiID);
        eventBus.$emit('select-product', activeItem);
      }) 
      
    }
  },
  mounted() {
    this.initSidebarHeight();
    
    this.$store.commit("setProducts", null);
    this.$store.commit("setProductsLoadingStatus", null);
    
    if (this.$route.params["id"] != null) {
      const aoiID = this.$route.params["id"].aoi_id;
      const productID = this.$route.params["id"].product_id;
      const filterOptions = 'all';
      this.loadProductFromNotification(aoiID, productID, filterOptions);
    }

    // Initialize eventbus handler
    var ref = this;
    eventBus.$on('loadProductFromNotification', function (data) {
      ref.loadProductFromNotification(
        data.aoiID,
        data.productID,
        data.filterOption
      );
    });
  },
  created() {
    // Event handler for the windows resize event
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    // selectedSortOption(sortOption) {
    //   this.$store.commit('setProductsActiveItem', '');
    // },
    productsLoadingStatus() {
      this.selectedSortOption = 'sort_date_desc';
    },
    // '$route.params.id' : function(id) {
    // }
  }
};
</script>

<style scoped>
#sidebar-header {
  border-bottom: 1px solid lightgray;
  padding-top: 16px;
  padding-bottom: 16px;
}

#sidebar-header #sidebar-header-font {
  font-weight: bold;
  text-transform: uppercase;
}

#sidebar-content-outer {
  background-color: inherit;
}

#sidebar-content-inner {
  height: 100%;
  padding-top: 10px;
  overflow-y: scroll;
}

.products-label {
  font-style: italic;
  color: #191919;
}

.custom-icon-padding {
  margin-right: 2px;
}
</style>
