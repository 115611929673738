<template>
  <div>
    <b-modal id="bv-modal-startup" size="lg" ref="modalStartup" title="Global Flood Monitoring Portal" hhide-footer
      data-cy="title-gfm-entry-modal">
      <p class="pt-2">
        <strong>
          GFM is an experimental product.
        </strong>
      </p>
      <p class="pt-2">
        <small>
          <em>
            This Application is optimized for a screen resolution of 1920x1080 or higher.
          </em>
        </small>
      </p>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hideModal" data-cy="ok-gfm-entry-modal">
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "ModalDialogStartup",
  methods: {
    showModal() {
      this.$refs["modalStartup"].show();

    },
    hideModal() {
      this.$refs["modalStartup"].hide();
    }
  },
  created: function () {
    eventBus.$on("show-modal-startup", () => {
      //this.showModal();
    });
  }
};
</script>

<style>
ul.a {
  list-style-type: square;
}
</style>
