<template>
  <div class="container mt-4">
    <b-tabs content-class="mt-3">
      <b-tab title="Notification Settings" active>
        <NotificationsConfiguration :twitterConfigCompleted="twitterConfigCompleted"/>
      </b-tab>
      <b-tab  title="Push Notification History">
        <NotificationsHistory/>
      </b-tab>
      <b-tab title="Twitter Notifications">
        <NotificationsTwitter :twitterConfigCompleted="twitterConfigCompleted"/>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import NotificationsConfiguration from '@/pages/notifications/Notifications_Configuration.vue'
import NotificationsHistory from '@/pages/notifications/Notifications_History.vue'
import NotificationsTwitter from '@/pages/notifications/Notifications_Twitter.vue'

export default {
  name: "NotificationsMain",
  components: {
    NotificationsConfiguration,
    NotificationsHistory,
    NotificationsTwitter,
  },
  computed: {
    twitterConfigCompleted() {
      return this.$store.getters.getTwitterAccountCompleted;
    }
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {}
};
</script>

<style scoped>
</style>
