<template>
  <div id="activate_register" class="login container">
    <b-card class="card">
      <b-form>
        <!-- <b-button variant="link" class="text-decoration-none" @click="goToLogin">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon>
          <b-img
            class="image"
            :src="require('./../assets/gfmlogo.png')"
            fluid
            alt="Responsive image"
            width="100%"
          ></b-img>
        </b-button> -->
        <div class="row">
          <div class="col my-auto">
            <b-img
              class="image"
              :src="require('./../assets/gfmlogo.png')"
              fluid
              alt="Responsive image"
              width="100%"
            ></b-img>
          </div>
          <div class="col text-right">
            <b-button variant="link" class="text-decoration-none" @click="goBack">
              <b-icon icon="chevron-left" class="toggle-icon rounded p-1" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </div>
      </b-form>
      <b-alert v-model="showSuccess" variant="success" show>
        Your profile was successfully activated!<br><br>
        You can now log in to the GFM portal and access the basic functionalities of 
        GFM. You will be notified if you are granted access to additional features.
      </b-alert>
      <b-form @submit.prevent="onSubmit">
        <h3 align="justify">
          <b>Activate profile</b>
        </h3>
        <p class="p">Activate profile linked to email-adress</p>
        <p align="center"> {{ email }} </p>
        <b-button v-show="!showSuccess" type="submit" block>Activate</b-button>
        <b-button v-show="showSuccess" type="submit" block @click="goToLogin">Go to login</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showSuccess: false,
      email: ""
    };
  },
  beforeMount() {
      this.email = this.$route.query.email;
  },
  methods: {
    onSubmit() {
      const formData = {
        register_token: this.$route.query.register_token
      };
      /* eslint-disable */
      this.$store.dispatch("confirmRegister", formData).then(
          response => {
            this.showSuccess = true;
          }
        );
      /* eslint-enable */
    },
    goToLogin() {
      this.$router.push('login')
    }
  }
};
</script>

<style scoped>
.login {
  width: 400px;
  padding-top: 30px;
}

.card {
  border-color: #212529;
  border-width: 2px;
}

.p {
  font-size: 14px;
}

.pwalert {
  border-color: rgba(240, 36, 36, 0.397);
  border-width: 1px;
}

.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn .b-icon.bi {
  font-size: 250%;
  vertical-align: middle;
}

.btn-link {
  color: #000000;
}

/**
 * Sidebar icon hover styling
 */
.toggle-icon:hover{
  cursor: pointer;
  background-color: #f4f4f4;
}
</style>
