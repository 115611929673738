<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      v-model="isSidebarOpen"
      title="Sentinel-1 Metadata"
      class="font-title"
      right
      shadow
      z-index="1000"
    >
      <div class="px-3 py-0">
        <b-card class="pb-2 mb-2">
          <div v-for="(value, name) in sidebarData" v-bind:key="value">
              <div>
                <dd class="font-sidebar-key">{{name}}</dd>
                <dt class="font-sidebar-value mb-2">{{value}}</dt>
              </div>
            </div>
        </b-card>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "ProductsSidebarRight",
  components: {},
  data() {
    return {
      isSidebarOpen: false,
      sidebarData: [],  
    };
  },
  mounted() {},
  methods: {
    showSidebar() {
      this.isSidebarOpen = true;
    },
    hideSidebar() {
      this.isSidebarOpen = false;
    },
    sortObjKeysAlphabetically(obj) {
      var ordered = {};
      Object.keys(obj).sort().forEach(function(key) {
        ordered[key] = obj[key];
    });
return ordered;
}
  },
  created() {
    eventBus.$on("show-sidebar-right", (data) => {
      this.sidebarData = this.sortObjKeysAlphabetically(data);
      this.showSidebar();
    });
    eventBus.$on("hide-sidebar-right", () => {
      this.hideSidebar();
      this.sidebarData = [];
    });
  },
};
</script>

<style>
#sidebar-right {
  margin-top: 66px;
  height: calc(100% - 66px) !important;
  /*width: 400px;*/
}

#sidebar-right___title__ {
  font-size: 20px !important;
  padding-right: 0px;
}

#sidebar-right header {
  margin-bottom: 4px;
}

.font-sidebar-value {
  font-size: 12px;
}

.font-sidebar-key {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 0px;
}
</style>
