<template>
  <div>
    <NotificationsTwitterConfiguration />
    <div class="m-2"></div>
    <NotificationsTwitterMessage :twitterConfigCompleted="twitterConfigCompleted" />
  </div>
</template>

<script>
import NotificationsTwitterConfiguration from "@/pages/notifications/Notifications_TwitterConfiguration.vue";
import NotificationsTwitterMessage from "@/pages/notifications/Notifications_TwitterMessage.vue";

export default {
  name: "NotificationsTwitter",
  components: {
    NotificationsTwitterConfiguration,
    NotificationsTwitterMessage
  },
  props: ['twitterConfigCompleted'],
  computed: {},
  data() {
    return {
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
</style>
