<template>
  <div>
    <b-modal id="bv-modal-session-expired" size="lg" ref="modalSessionExpired" title="Session Expired"
      no-close-on-backdrop no-close-on-esc hide-header-close>
      <p class="pt-2">
        Your session has expired due to inactivity. Please click OK to return
        tothe login screen.
      </p>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hideModal">
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";

export default {
  name: "ModalDialogSessionExpired",
  methods: {
    showModal() {
      this.$refs["modalSessionExpired"].show();
    },
    hideModal() {
      this.$refs["modalSessionExpired"].hide();
      this.$store.dispatch("signout");
    },
  },
  created: function () {
    var ref = this;
    eventBus.$on("show-modal-session-expired", () => {
      ref.showModal();
    });
  },
};
</script>

<style>
ul.a {
  list-style-type: square;
}
</style>
