<template>
  <div>
    <!-- Sidebar header -->
    <div v-if="1 == 1">
      <div id="sidebar-header" class="container-fluid">
        <div id="sidebar-header-font">AOIs</div>
      </div>

      <!-- Sidebar content with scrollbar -->
      <div id="sidebar-content-outer">
        <div id="sidebar-content-inner" class="container-fluid">
          <div>
            <CreateAOI v-on:notification_AOICreated="receiverAOICreated" />
            <hr class="mt-2 mb-2" />

            <div v-if="aoiLoadingError == null">
              <div v-if="aoiLoadingStatus == true">
                <div class="d-flex justify-content-center mt-3">
                  <b-spinner variant="secondary" label="Loading"></b-spinner>
                </div>
              </div>
              <div v-else>
                <b-input-group size="sm" class="mb-2">
                  <b-input-group-append is-text>
                    <b-icon icon="search"></b-icon>
                  </b-input-group-append>
                  <b-form-input v-model="aoiSearchPattern" type="search" placeholder="Filter by AOI name ..."
                    autocomplete="off"></b-form-input>
                </b-input-group>

                <div v-if="this.aois.length > 0">
                  <div v-for="(item, key) in this.aois" :data="item" v-bind:key="key">
                    <!-- v-on:notification_cardClicked="receiver_cardItemClicked" -->
                    <AOIItem v-on:notification_aoiItemDeleted="receiver_aoiItemDeleted" :cardData="item"
                      :cardID="'id_' + item.aoi_id" :cardCurrent="selectedCard">
                    </AOIItem>
                  </div>
                </div>
                <div v-else>
                  <p class="font-italic">No AOI could be found!</p>
                </div>
              </div>
            </div>
            <div v-else>
              <b-alert show dismissible variant="danger" @dismissed="alertClosedClicked()">
                <div class="alert-heading"><strong>Error!</strong> AOIs could not be loaded.</div>
              </b-alert>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mt-3 ml-4">
        <b>Page under cunstruction ...</b>
      </div>
    </div>
  </div>
</template>

<script>
import CreateAOI from "@/pages/aoi/AOI_CreateAOI.vue";
import AOIItem from "@/pages/aoi/AOI_Item.vue";

export default {
  name: "AOISidebarContent",
  components: {
    CreateAOI,
    AOIItem,
  },
  data() {
    return {
      aoiSearchPattern: ''
    };
  },
  computed: {
    selectedCard() {
      return this.$store.getters.getAOIsActiveItem;
    },
    aoiCount() {
      return this.$store.getters.getDrawnAOIsCount;
    },
    aois() {
      var tmpAOIs = this.$store.getters.getAOIs.slice();
      var filteredAOIs = tmpAOIs.filter((aoi) => {
        if (aoi.aoi_name.toLowerCase().startsWith(this.aoiSearchPattern.toLowerCase())) {
          return aoi;
        }
      });
      var sortedAOIs = filteredAOIs.sort((a, b) => (a.aoi_name.toLowerCase() > b.aoi_name.toLowerCase()) ? 1 : -1)

      return sortedAOIs;
    },
    aoiLoadingStatus() {
      return this.$store.getters.getAOIsLoadingStatus;
    },
    aoiLoadingError() {
      return this.$store.getters.getAOIsLoadingError;
    },
  },
  methods: {
    /**
     * This function sets the height of the sidebar parent container
     */
    initSidebarHeight() {
      // Get the height of the sidebar and the sidebar header
      var sidebarHeight = document.getElementById("sidebar").offsetHeight;
      var sidebarHeaderHeight = document.getElementById("sidebar-header")
        .offsetHeight;

      // Calculate new height and resize outer container
      var _height = sidebarHeight - sidebarHeaderHeight;
      var _height_str = String(_height) + "px";

      document.getElementById(
        "sidebar-content-outer"
      ).style.height = _height_str;
    },
    /**
     * This function handles the window resize event
     */
    handleResize() {
      this.initSidebarHeight();
    },
    /***
     * This function receives notificatoins from the child component after a has been created
     */
    receiverAOICreated(currentAOIID) {
      this.aoiSearchPattern = '';
      this.$store.commit('setAOIsActiveItem', currentAOIID);
    },
    /***
     * This functions scrolls to the selected card item
     */
    scroll(id) {
      var selector = '#' + String(id);
      const el = this.$el.querySelector(selector);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    /**
     * Eventhandler for the close button of the (load aois) alert box
     */
    alertClosedClicked() {
      this.$store.dispatch("getAOIs");
    },
    receiver_aoiItemDeleted() { }
    ///**
    // * This function receives a notification from the child component if the card gets seleced
    // */
    // receiver_cardItemClicked(cardID) {
    //   this.currentCardID = cardID;
    //   this.scroll(cardID);
    // },
  },
  mounted() {
    this.initSidebarHeight();
    this.$store.dispatch("getAOIs");
    this.$store.commit("setClipboardCoordinates", null);
    this.$store.dispatch("setClipboardCoordinates", null);
  },
  created() {
    // Event handler for the windows resize event
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    /***
     * Watcher for changing the current AOI card
     */
    selectedCard(id) {
      // Note: The `id` paramter can be null
      this.scroll(id);
    },
  }
};
</script>

<style scoped>
#sidebar-header {
  border-bottom: 1px solid lightgray;
  padding-top: 16px;
  padding-bottom: 16px;
}

#sidebar-header #sidebar-header-font {
  font-weight: bold;
  text-transform: uppercase;
}

#sidebar-content-outer {
  background-color: inherit;
}

#sidebar-content-inner {
  height: 100%;
  padding-top: 10px;
  overflow-y: scroll;
}

.card-title {
  font-size: 1em;
  color: #007bff;
  font-weight: 600;
}

.card-text {
  color: #191919;
  font-style: italic;
  font-size: 0.85em;
}

.card:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.cardActive {
  background-color: #f0f0f0;
}

.alert-heading {
  font-weight: normal;
}
</style>
