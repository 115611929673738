<template>
  <div class="container mt-4 ">

    
    <b-overlay :show="showOverlay">
      <div>
      <b-card title="Personal Information">
        <b-form>

          <!-- Mail information -->
          <b-form-group>
            <label class="label-style">E-mail address</label>
            <b-form-input
              v-model="formData.mail"
              placeholder=""
              disabled
            ></b-form-input>
          </b-form-group>

          <div class="row">
            <div class="col">
              <b-form-group>
              <label class="label-style">First Name</label>
                <b-form-input
                  v-model="formData.firstName"
                  placeholder="Enter first name"
                  autocomplete="given-name"
                  disabled
                ></b-form-input>
              </b-form-group>
            </div>
            </div>

          <div class="row">
            <div class="col">
              <b-form-group>
                <label class="label-style">Last Name</label>
                  <b-form-input
                    v-model="formData.lastName"
                    placeholder="Enter last name"
                    autocomplete="family-name"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
<!-- 
            <div class="row">
              <div class="col"> 
                <b-form-group>
                  <label class="label-style">Organization Name (optional)</label>
                  <b-form-input
                    v-model="formData.organizationName"
                    placeholder="Enter organization name"
                    autocomplete="organization"
                  ></b-form-input>
                </b-form-group>
              </div> 
            </div>
            
            <div class="row">
              <div class="col"> 
                <b-form-group>
                  <label class="label-style">Organization Type (optional)</label>
                  <b-form-select v-model="formData.organizationType" :options="organizationTypeOptions">
                    <template #first>
                      <b-form-select-option :value="null">-- Select organization type --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>  
            </div>

            <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">Country (optional)</label>
                  <b-form-select v-model="formData.country" :options="countryOptions">
                    <template #first>
                      <b-form-select-option :value="null">-- Select country --</b-form-select-option>
                    </template>     
                  </b-form-select>
                </b-form-group>
              </div>
            </div> -->
            
            <div class="row mt-2">
              <div class="col text-right">
                <b-button variant="secondary mr-2" @click="cancelBtnClicked()">Cancel</b-button>
                <b-button variant="primary" @click="submitBtnClicked()">{{submitBtnText}}Continue</b-button>
              </div>
            </div>
  
        </b-form>
      </b-card>

      <b-overlay :show="showRegistrationInfoBox" no-wrap>
        <template #overlay>
          <div
            ref="dialog"
            tabindex="-1"
            role="dialog1"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center p-3"
          >
            <b-card title="Please complete the registration" body-class="text-center" header-tag="nav">
              <b-card-text class="card-text-font">
                Complete the registration by entering all the required profile fields.
              </b-card-text>
              <b-button variant="primary" @click="closeInfoBox()">Complete registration</b-button>
            </b-card>
          </div>
        </template>
      </b-overlay>

      </div>
    </b-overlay>

  </div>
</template>

<script>
import axios_services from '@/axios/axios-services.js'

export default {
  name: "GlofasRegistrationProfile",
  components: {},
  data() {
    return {
      showOverlay: false,
      countriesLoaded: false,
      organizationTypesLoaded: false,
      showRegistrationInfoBox: null,

      formData: {
        mail: "",
        firstName: "",
        lastName: "",
        organizationName: "",
        organizationType: null,
        country: null,
        inputData: ""
      },
      countryOptions:  [],
      organizationTypeOptions: [],
      glofasUserData: null
    };
  },
  computed: {
    formDataLoaded() {
      if ((this.organizationTypesLoaded) && (this.countriesLoaded)) {
        return true;
      } else {
        return false;
      }
    },
    isSubmitBtnLocked() {
      if (
          (this.formData.organizationName == "") ||
          (this.formData.organizationType == null) ||
          (this.formData.country == null)
       ) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    /**
     * This function initializes the personal data form. If the registratoin process is not completed, most fields are empty.
     */
    initFormData() {
      let organizationType = this.capitalizeFirstLetter(this.glofasUserData.organizationType);
      let country = this.capitalizeFirstLetter(this.glofasUserData.country);

      this.formData.mail = this.glofasUserData.email;
      this.formData.firstName = this.glofasUserData.firstName;
      this.formData.lastName = this.glofasUserData.lastName;
      
      this.formData.organizationName = this.glofasUserData.organizationName;
      this.formData.organizationType = organizationType
      this.formData.country = country;
    },
    /**
     * Event handler for the submit button
     */
    submitBtnClicked() {
      this.showUpdateDataModal();
    },
    /**
     * Event handler for the refresh button
     */
    cancelBtnClicked() {
      this.$store.dispatch("signout");
    },
    /**
     * This function hides the info box widget
     */
    closeInfoBox() {
      this.showRegistrationInfoBox = false;
    },
    /**
     * Modal dialog to confirm the update operation
     */
    showUpdateDataModal() {
      this.$parent.$bvModal.msgBoxConfirm('Do you want to complete the registration?', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then((value) => {
        if (value) {

          let organizatonType = this.formData.organizationType == null ? "" : this.formData.organizationType;
          let country = this.formData.country == null ? "" : this.formData.country;

          // Payload for update operation
          var updatedFormData = {
            'customer_id': this.$store.getters.clientID,
            'country': country,
            'company_name': this.formData.organizationName,
            'company_type': organizatonType,
            'glofas_account_finished': true
          };

          this.showOverlay = true;

          axios_services
          .patch("auth/glofas_registration", updatedFormData)
          .then(() => {
            this.showNotification("success", "Success!", "Registration completed",);
            // Refresh data in store
            this.$store.commit('storeUser', {
              first_name: this.formData.firstName,
              last_name: this.formData.lastName,
              company: this.formData.organizationName,
              company_type: this.formData.organizationType,
              country: this.formData.country,
            });
            this.showOverlay = false;
            this.$router.push('/')
          })
          .catch((error) => {
            var errorMsg = error.response.data.message.error_definition.message;
            console.log("Error: ",   errorMsg);
            this.showNotification("danger", "Error!", "Registration could not be completed");
          });
        }
      })
    },
    /***
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$root.$bvToast.toast(content, {
        autoHideDelay: 2000,
        title: title,
        variant: variant,
        solid: true
      });  
    },
    /**
     * Helper functin to capitalize the first letter of a string
     */
    capitalizeFirstLetter(string) {
      if (string == "" || string == null) {
        return null;
      }
      else {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  },
  mounted() {
    this.showOverlay = true;
    
    axios_services.get("utils/countries").then((response) => {
      this.countryOptions = response.data;
      this.countriesLoaded = true;
    });
    axios_services.get("utils/organization_types").then((response) => {
      this.organizationTypeOptions = response.data
      this.organizationTypesLoaded = true;
    });

    this.glofasUserData = this.$store.getters.getGlofasUserData;
    this.initFormData();

    if (this.$store.getters.getGlofasUserData.glofasAccountFinished) {
      this.showRegistrationInfoBox = false;
    } else {
      this.showRegistrationInfoBox = true;
    }

  },
  watch: {
    formDataLoaded(newState) {
      if (newState) {
        this.showOverlay = false;
      } else {
        this.showOverlay = true;
      }
    }
  }
};
</script>

<style scoped>

.card .label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

.card-text-font {
  font-style: normal;
}

</style>
