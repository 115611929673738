<template>
  <div>
    <div v-if="profileType == config.GFM_USER">
      <GFMProfile />
    </div>
    <div v-else-if="(profileType = config.GLOFAS_USER)">
      <GlofasProfile />
    </div>
  </div>
</template>

<script>
import GFMProfile from "@/pages/profile/GFMProfile.vue";
import GlofasProfile from "@/pages/profile/GlofasProfile.vue";
import config from "@/config.js";

export default {
  data() {
    return {
      config: config,
    };
  },
  components: {
    GFMProfile,
    GlofasProfile,
  },
  computed: {
    profileType() {
      return this.$store.getters.getUserType;
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>
