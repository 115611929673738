<template>
  <div>
    <b-modal
      size="lg"
      ref="modal-change-pw"
      title="Change Password"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <b-alert v-model="showWarning" show dismissible variant="warning" @dismissed="showWarning=false">
        <div class="alert-heading"><strong>Warning!</strong> {{warningMessage}}</div>
      </b-alert>

      <b-alert v-model="showError" show dismissible variant="danger" @dismissed="showAlert=false">
        <div class="alert-heading"><strong>Error!</strong> {{errorMessage}}</div>
      </b-alert>

      <!-- <b-form-group>
        <label class="label-style">Old password</label>
        <b-form-input
          v-model="oldPassword"
          placeholder="Enter old password"
          autocomplete="off"
        ></b-form-input>
      </b-form-group> -->


      <b-form-group>
        <label class="label-style">New password</label>
        <b-form-input
          v-model="newPassword"
          placeholder="Enter new password"
          autocomplete="off"
          type="password"
        ></b-form-input>
      </b-form-group>

      <b-form-group>
        <label class="label-style">Confirm new password</label>
        <b-form-input
          v-model="confirmNewPassword"
          placeholder="Confirm new password"
          autocomplete="off"
          type="password"
        ></b-form-input>
      </b-form-group>

      <template #modal-footer>
        <b-button variant="secondary" class="mr-2" :disabled="!passwordUpdateReqDone" @click="hideModal">Cancel</b-button>
        <b-button variant="primary" :disabled="btnDissabled" @click="updatePassword()">
          <b-spinner v-if="!passwordUpdateReqDone" small></b-spinner>
          <span> Confirm</span>
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { eventBus } from "@/main.js";
import axios_services from '@/axios/axios-services.js'

export default {
  name: "ChangePassword",
  data() {
    return {
      //oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      passwordUpdateReqDone: true,
      submitBtnLocked: true,
      showError: false,
      showWarning: false,
      warningMessage: "",
      errorMessage: ""
    };
  },
  computed: {
    btnDissabled() {
      if ( (this.newPassword.length == 0) || (this.confirmNewPassword.length == 0))  {
        return true;
      } else {
        return false;
      }
    },
    clientID() {
      return this.$store.getters.clientID;
    }
  },
  methods: {
    showModal() {
      this.$refs['modal-change-pw'].show();
    },
    hideModal() {
      this.refresh();
      this.clearInputs();
      this.$nextTick(() => {
        this.$refs['modal-change-pw'].hide();
      });
    },
    refresh() {
      this.warningMessage = "";
      this.errorMessage = "";
      this.showWarning = false;
      this.showError = false;
    },
    clearInputs() {
      //this.oldPassword = "";
      this.newPassword = "";
      this.confirmNewPassword =  "";
    },
    updatePassword() {
      //console.log(this.oldPassword);
      console.log(this.newPassword);
      console.log(this.confirmNewPassword);
      
      // Check password length
      if ((this.newPassword.length < 8) || (this.confirmNewPassword.length < 8)) {
        this.warningMessage = "Password must contain at least 8 characters";
        this.showWarning = true;
        return;
      }

      // Check password same
      if (this.newPassword != this.confirmNewPassword) {
        this.warningMessage = "Passwords do not match";
        this.showWarning = true;
        return;
      }

      this.showWarning = false;
      this.passwordUpdateReqDone = false;

      let payload = {
        "user_id": this.clientID,
        "password": this.newPassword
      };

      axios_services.post("auth/password_change", payload)
        .then(() => {
          this.showNotification("success", "Success!", "Password successfully updated");
          this.hideModal();
          this.passwordUpdateReqDone = true;
        })
        .catch((error) => {
          console.log('Error: Password could not be updated. Details: ', error);
          this.passwordUpdateReqDone = true;
          this.showError = true;
          this.errorMessage = "Password could not be updated";
        });
    },
    showNotification(variant, title, content, hideDelay = 4000) {
      this.$parent.$bvToast.toast(content, {
        autoHideDelay: hideDelay,
        title: title,
        variant: variant,
        solid: true
      });
    },
  },
  created: function () {
    var ref = this;
    eventBus.$on("show-modal-change-password", () => {
      ref.showModal();
    });
  },
  watch: {
    oldPassword(v) {
      if (v.length == 0) {
        this.submitBtnLocked = true;
      } else {
        this.submitBtnLocked = false;
      }
    },
    newPassword(v) {
      if (v.length == 0) {
        this.submitBtnLocked = true;
      } else {
        this.submitBtnLocked = false;
      }    
    },
    confirmNewPassword(v) {
      if (v.length == 0) {
        this.submitBtnLocked = true;
      } else {
        this.submitBtnLocked = false;
      }    
    },
  }
};
</script>

<style>
.label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}
</style>
