import axios from 'axios'
//import store from './store/store.js'

const axios_services = axios.create({
    baseURL: process.env.VUE_APP_GFM_BACKEND_URL,
})

/*axios_services.interceptors.request.use(function (config) {
    let key = 'Bearer ' + 'Q8ajYGdedXVwtbqbkoHD4G1tTTja6PrXVxNgXnZTfL';
    config.headers.Authorization = key;
    return config;
}, function (error) {
    return Promise.reject(error);
});*/

export default axios_services