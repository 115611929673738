<template>
  <div class="container mt-4">
    <b-overlay 
      :show="!formDataLoaded || showOverlay"
      spinner-variant="secondary"
    >
      <div>
        <b-card title="Archive Download">
        <b-alert v-model="showAlert" show dismissible variant="warning">
          <div class="alert-heading"><strong>Warning!</strong>&nbsp;Date values are not correct - please check your inputs.</div>
        </b-alert>
          <p class="text-muted card-info-text">The archive download allows you to start a manual download of products over a pre-defined period of time. 
            You will be informed by mail as soon as the data is available.
          </p>
          <!-- <b-alert v-if="profileLoadingError" show variant="danger"><strong>Error!&nbsp;</strong>Required information could not be loaded!</b-alert> -->
          <b-form>
            <b-form-group>
              <label class="label-style">E-mail address</label>
              <b-form-input v-model="formData.mail" disabled></b-form-input>
            </b-form-group>

            <div class="row">
              <!-- <div class="col col-2">
                <b-form-group>
                  <label class="label-style">Gender</label>
                  <b-form-input
                    v-model="formData.gender"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div> -->
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">First Name</label>
                  <b-form-input
                    v-model="formData.firstName"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">Last Name</label>
                  <b-form-input
                    v-model="formData.lastName"
                    disabled
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">Start Date</label>
                  <b-form-datepicker
                    id="datepicker-start"
                    v-model="formData.startDate"
                    class="mb-0"
                  ></b-form-datepicker>
                </b-form-group>
              </div>
              <div class="col col-6">
                <b-form-group>
                  <label class="label-style">End Date</label>
                  <b-form-datepicker
                    id="datepicker-end"
                    v-model="formData.endDate"
                    class="mb-0"
                  ></b-form-datepicker>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">AOI Name</label>
                  <div id="list-select-aoi-products">
                    <list-select
                      :list="aoiItemList"
                      @select="onSelect"
                      :selected-item="formData.selectedAoiItem"
                      option-value="code"
                      option-text="name"
                      placeholder="Select AOI"
                    >
                    </list-select>
                  </div>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <b-form-group>
                  <label class="label-style">Product Layers</label>
                  <multiselect
                    v-model="formData.productLayerValue"
                    placeholder="Add product layers"
                    label="name"
                    track-by="code"
                    :options="productLayerOptions"
                    :multiple="true"
                    :taggable="true"
                  ></multiselect>
                </b-form-group>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col text-right">
                <b-button variant="secondary mr-2" @click="refresh()">Refresh</b-button>
                <b-button variant="primary" :disabled="isSubmitBtnLocked" @click="submitBtnClicked()">Submit</b-button>
              </div>
            </div>
          </b-form>
        </b-card>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import axios_services from "@/axios/axios-services.js";
import { ListSelect } from "vue-search-select";
import Multiselect from "vue-multiselect";

export default {
  name: "ArchiveDownload",
  components: {
    ListSelect,
    Multiselect,
  },
  data() {
    return {
      showOverlay: false,
      userDataLoaded: false,
      aoiDataLoaded: false,
      showAlert: false,

      formData: {
        mail: "",
        gender: "",
        firstName: "",
        lastName: "",
        startDate: null,
        endDate: null,
        productLayerValue: [],
        selectedAoiItem: {},
      },

      aois: null,
      aoiItemList: [],

      productLayerOptions: [
        { name: "Observed Flood Extent", code: "floodExtent" },
        { name: "Observed Water Extent", code: "waterExtent" },
        { name: "Reference Water Mask", code: "waterMask" },
        { name: "Exclusion Mask", code: "exclusionMask" },
        { name: "Uncertainty Values", code: "uncertaintyValues" },
        { name: "Affected Population", code: "affectedPopulation" },
        { name: "Affected Landcover", code: "affectedLandcover" },
      ],
    };
  },
  computed: {
    isSubmitBtnLocked() {
      if (
          (this.formData.mail == "") ||
          (this.formData.gender == "") ||
          (this.formData.firstName == "") ||
          (this.formData.lastName == "") ||
          (this.formData.startDate == null) ||
          (this.formData.endDate == null) ||
          (Object.keys(this.formData.selectedAoiItem).length == 0 ) ||
          (this.formData.productLayerValue.length == 0)
        ) {
        return true
      } else {
        return false
      }
    },
    formDataLoaded() {
      if ((this.userDataLoaded) && (this.aoiDataLoaded)) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    /**
     *
     * Eventhandler for the multi-select input widget
     */
    onSelect(selectedAoiItem) {
      this.formData.selectedAoiItem = selectedAoiItem;
    },
    /**
     * Helper function for the multi-select widget
     */
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    /**
     * This function initializes the personal data form
     */
    initFormData() {
      // Load aois
      this.$store.dispatch("getAOIs").then(() => {
        this.aois = this.$store.getters.getAOIs;
        this.aois.forEach(element => { 
          var d = { code: element.aoi_id, name: element.aoi_name };
          this.aoiItemList.push(d);
        });
        this.aoiDataLoaded = true;
      });

      // Load user data 
      this.$store.dispatch('getProfileData').then( () => {
        this.formData.mail = this.$store.getters.userData.email;
        this.formData.gender = this.$store.getters.userData.gender;
        this.formData.firstName = this.$store.getters.userData.first_name;
        this.formData.lastName = this.$store.getters.userData.last_name;
        this.userDataLoaded = true;
      });
    },
    /**
     * Event handler for the submit button
     */
    submitBtnClicked() {
      this.showUpdateDataModal();
    },
    /**
     * Event handler for the refresh button
     */
    refresh() {
      this.initFormData();
      this.formData.startDate = null;
      this.formData.endDate = null;
      this.formData.productLayerValue =  [];
      this.formData.selectedAoiItem =  {};
    },
    /**
     * Modal dialog to confirm the update operation
     */
    showUpdateDataModal() {
      this.$parent.$bvModal
        .msgBoxConfirm("Do you want to send the download order manually?", {
          title: "Please Confirm",
          size: "md",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: false,
        })
        .then((value) => {
          if (value) {
            this.showAlert = false;

            // Chek date range
            if (this.formData.endDate <= this.formData.startDate) {
              this.showAlert = true;
              return;
            }

            //let userName = "";
            let aoi = null;
            let layer_list = [];

            //userName = [this.formData.gender, this.formData.firstName, this.formData.lastName].join(' ');
            this.formData.productLayerValue.forEach(item => {
              layer_list.push(item.name)
            });
            aoi = this.aois.find(x => x.aoi_id == this.formData.selectedAoiItem.code);

            var formData = {
              "time_start": this.formData.startDate,
              "time_end": this.formData.endDate,
              "layer_list": layer_list,
              "aoi": aoi.geoJSON,
              "user_email": this.formData.mail,
              "first_name": this.formData.firstName,
              "last_name": this.formData.lastName
            };

            this.showOverlay = true;
            axios_services
              .post("support/archive_download", formData)
              .then(() => {
                this.showNotification(
                  "success",
                  "Success! Order submitted",
                  "Download order successfully submitted"
                );
                this.showOverlay = false;
              })
              .catch((error) => {
                console.log("Error: ", error);
                this.showNotification(
                  "danger",
                  "Error!",
                  "Download order could not be submitted"
                );
                this.showOverlay = false;
              })
              .finally( () => {
                this.refresh();
                this.showOverlay = false;
              }) 
          }
        });
    },
    /***
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  mounted() {
    this.initFormData();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.card .label-style {
  font-size: 12px;
  text-transform: uppercase;
  color: #9a9a9a;
  margin-bottom: 4px;
}

.card-text-font {
  font-style: normal;
}

.card .card-info-text {
  font-size: 14px;
}

</style>

<style >
  /* .multiselect__tags {
         min-height: 30px;
        display: block;
        padding: 6px 40px 0 14px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px; 

  }

  .multiselect__placeholder {
     margin-bottom: 0px 
  } */
</style>

