const OBSERVED_FLOOD_EXTENT = "observed_flood_extent"
const OBSERVED_WATER_EXTENT = "observed_water_extent"
const REFERENCE_WATER_MASK = "reference_water_mask"
const EXCLUSION_MASK = "exclusion_mask"
const UNCERTAINTY_VALUES = "uncertainty_values"
const SENTINEL1_METADATA = "sentinel-1_metadata"
const SENTINEL1_FOOTPRINT = "sentinel-1_footprint"
const SENTINEL1_SCHEDULE = "sentinel-1_schedule"
const AFFECTED_POPULATION = "affected_population"
const AFFECTED_LANDCOVER = "affected_landcover"

export const ProductLayers = {
  OBSERVED_FLOOD_EXTENT: OBSERVED_FLOOD_EXTENT,
  OBSERVED_WATER_EXTENT: OBSERVED_WATER_EXTENT,
  REFERENCE_WATER_MASK: REFERENCE_WATER_MASK,
  EXCLUSION_MASK: EXCLUSION_MASK,
  UNCERTAINTY_VALUES: UNCERTAINTY_VALUES,
  SENTINEL1_METADATA: SENTINEL1_METADATA,
  SENTINEL1_FOOTPRINT: SENTINEL1_FOOTPRINT,
  SENTINEL1_SCHEDULE: SENTINEL1_SCHEDULE,
  AFFECTED_POPULATION: AFFECTED_POPULATION,
  AFFECTED_LANDCOVER: AFFECTED_LANDCOVER
}