<template>
  <div>
    <b-overlay :show="!productsLoadedStatus">
    <!-- <b-card :id="cardID" @click="cardClicked(cardID)" v-bind:class="{ cardActive: isCardActive }" class="mb-2"> -->
      <b-card :id="cardID" @click="cardClicked(cardID, cardData.product_id)" v-bind:class="{ cardActive: this.cardID == this.activeProductItem }" class="mb-2">
      <b-card-title>
        <div class="row pb-0">
          <div class="col-sm col-8 my-auto">
            <span class="">{{getFormattedDateStr(cardData.product_time)}}</span>
              <!-- <b-icon 
                v-if="cardData.product_seen != true"
                icon="dot"
                scale="2.0"
                variant="primary"
              ></b-icon> -->
          </div>
          <div class="col-sm col-4 text-right">
            <b-button :disabled="layerSelected" v-b-tooltip.hover title="Show footprint extent" size="sm" variant="secondary" class="mb-0 mr-1" @click="cardResetLocationBtnClicked()">
              <b-icon icon="geo-alt"></b-icon>
            </b-button>
            <b-button size="sm" variant="secondary" class="mb-0 mr-1" @click="cardToggleBtnClicked(cardID)">
              <b-icon :icon="productItemToggleIcon"></b-icon>
            </b-button>
          </div>
        </div>
      </b-card-title>
      <b-card-text class="card-text-aoi mb-0">{{cardMetaData.aoiName}}</b-card-text>
      <b-card-text class="card-text-timestamp mb-1">{{cardData.product_time}}</b-card-text>
      <b-collapse v-model="productBoxOpened" @shown="productBoxOpenedEvent(cardID)">
        <!-- Layer table -->
          <LayerSelection
            :productData="cardData"
            :productMetaData="cardMetaData"
            :productLayerData="productLayerData"
            :productLayerS1Data="productLayerS1Data"
            :productBoxToggled="productBoxOpened"
          >
          </LayerSelection> 
      </b-collapse>
       <b-badge 
        :id="'popover-latest-product' + cardData.product_id"
        v-if="cardData.latest_product" 
        variant="success">Latest products
      </b-badge>
      <b-popover :target="'popover-latest-product' + cardData.product_id" triggers="hover" placement="top">
        <template #title>Latest products</template>
        Latest products intersecting the AOI {{cardMetaData.aoiName}}
      </b-popover>
    </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {eventBus} from "@/main.js";
import LayerSelection from '@/pages/products/Products_LayerSelection.vue'
import axios_services from '@/axios/axios-services.js'
import axios from 'axios'

export default {
  name: "ProductItem",
  components: {
    LayerSelection,
  },
  props: {
    cardData: { type: Object, required: true},
    cardID: {type: String},
    cardCurrent: {type: String},
    cardMetaData: {type: Object}
  },
  data() {
    return {
      isCardActive: false,
      productBoxOpened: false,
      productsLoadedStatus: true,
      clearLayerSelection: true,
      productLayerData: [],
      productLayerS1Data: [],
      layerSelected: false
    };
  },
  computed: {
    productItemToggleIcon() {
      return this.productBoxOpened ? "chevron-up" : "chevron-down";
    },
    activeProductItem() { 
      return this.$store.getters.getProductsActiveItem;
    }
  },
  methods: {
    /***
     * Card clicked event handler
     */
    cardClicked(idStr) {
      eventBus.$emit('hide-sidebar-right');
      if (this.productLayerData.length == 0) {
        // Load layer info from server
        this.productsLoadedStatus = false;

        const reqProducts = axios_services.get(`/products/${this.cardData.product_id}`);
        const reqProductsS1 = axios_services.get(`/products/sentinel-1/${this.cardData.product_id}`);

        axios.all([reqProducts, reqProductsS1]).then(axios.spread((...responses) => {
          this.productLayerData = responses[0].data;
          this.productLayerS1Data = responses[1].data;
          this.productsLoadedStatus = true;
        })).catch(errors => {
            console.log("Error:", errors);
            this.showNotification("danger", "Error!", "Product data could not be loaded");
            this.productsLoadedStatus = true;
        });

        // Display satellite extent
        let d = {
          'layerBoundary': this.cardData.layer_boundary,
          'layerDate': this.cardData.product_time
        };
        eventBus.$emit('displayEqui7Grid', d);
      }
      this.$store.commit('setProductsActiveItem', idStr);
    },
    /***
     * Card toggle button event handler
     */
    cardToggleBtnClicked() {
      this.productBoxOpened =!this.productBoxOpened;
    },
    /**
     * This function resets the leaflet view to the aoi center
     */
    cardResetLocationBtnClicked() {
      eventBus.$emit('updateEqui7GridViewPoint');
    },
    /***
     * This functions scrolls to the selected card item
     */
    scroll(id) {
      var selector = '#' + String(id);
      const el = this.$el.querySelector(selector);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    },
    /***
     * This function is called when the collapse box showed up
     */
    productBoxOpenedEvent(id) {
      this.scroll(id);
    },
    /**
     * Returns the data from a string wich contains time and date information
     */
    getFormattedDateStr(dateTimeStr) {
      return dateTimeStr.split('T')[0]
    },
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  mounted() {
    var ref = this;
    eventBus.$on('select-product', function (activeItem) {
      if (ref.cardID == activeItem) {
        ref.cardClicked(ref.cardID, ref.cardData.product_id);
        ref.productBoxOpened = true;
      }
    });
  },
  created() {},
    watch: {
      /***
       * This watch method is called when the active card changes
       */
      activeProductItem(newVal) {  
        if (newVal == this.cardID) {
          this.isCardActive = true;
        } else {
          this.productBoxOpened = false;
          this.isCardActive = false;
          this.productLayerData = [];
          this.productLayerS1Data = [];
      }
      
      }
    },
};
</script>

<style scoped>

.card-title {
  font-size: 1.0em;
  color: #007bff;
  font-weight: 600;
  margin-bottom: 0px;
}

.card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-text-aoi {
  color: #191919;
  /*font-style: italic;*/
  font-size: 0.85em;
}

.card-text-timestamp {
  color: #191919;
  /*font-style: italic*/
  font-size: 0.85em;
}

.card:hover{
    background-color: #f0f0f0;
    cursor:pointer;
}

.cardActive {
  background-color:#f0f0f0;
}

</style>
