<template>
  <div>

    <b-modal id="bv-modal-health-information" size="xl" ref="modalHealthInformation" title="GFM System Health" no-close-on-backdrop hide-header-close>
      <div class="pt-2">
        <div v-if="healthState==3">
          <b-alert show variant="danger">
            Service is currently not available! 
          </b-alert>
        </div>
        <div v-else>
          <div>
            <b-alert v-if="healthState == 0" show variant="success" class="mb-4">All components are up and running!</b-alert>
            <b-alert v-else-if="healthState == 1" show variant="warning" class="mb-4">Some components are degraded!</b-alert>
            <b-alert v-else-if="healthState == 2" show variant="danger" class="mb-4">Some components are unavailable!</b-alert>
          </div>
          <b-table small id="health-table" :items="healthTableData" :fields="healthTableFields" hover bordered>
            <template v-slot:cell(status)="data">
              <div class="text-center">
                <b-badge style="width:105px" v-if="data.item.status=='UP'" variant="success">UP</b-badge>
                <b-badge style="width:105px" v-else-if="data.item.status=='DEGRADED'" variant="warning">DEGRADED</b-badge>
                <b-badge style="width:105px" v-else-if="data.item.status=='UNAVAILABLE'" variant="danger">UNAVAILABLE</b-badge>
              </div>  
            </template>
            <template v-slot:cell(date)="data">
              {{ getFormatedDate(data.item.date) }}
              </template>
          </b-table>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hideModal">
            Close
          </b-button>
        </div>
      </template>
    </b-modal>

    <div @click="showModal()">
      <b-nav-item v-if="healthState == 0"><b-icon icon="exclamation-triangle-fill" variant="success"></b-icon></b-nav-item>
      <b-nav-item v-else-if="healthState == 1" ><b-icon icon="exclamation-triangle-fill" variant="warning"></b-icon></b-nav-item>
      <b-nav-item v-else-if="healthState == 2" ><b-icon icon="exclamation-triangle-fill" variant="danger"></b-icon></b-nav-item>
      <b-nav-item v-else-if="healthState == 3" ><b-icon icon="exclamation-triangle-fill" variant="danger"></b-icon></b-nav-item>
    </div>

  </div>
</template>

<script>  
import axios from 'axios'

export default {
  name: "HealhState",
  components: {},
  data() {
    return {
      healthCheck: null,
      healthState: null,
      healthTableData: null,
      healthTableFields: [
        { key: 'name', label: 'Group', class: 'align-middle' },
        { key: 'status', label: 'Status', class: 'align-middle' },
        { key: 'msg', label: 'Message', class: 'align-middle' },
        { key: 'date', label: 'Date', class: 'align-middle' },
      ],
    };
  },
  computed: {
    userSite() {
      return this.$store.getters.userSite;
    },
  },
  mounted() {
    this.checkHealth();
    this.updateHealth();
  },
  methods: {
    checkHealth() {
      var self = this;
      this.healthCheck = setInterval( () => {
        if (this.userSite == false) {
         self.updateHealth();
        }
      }, 10000)
    },
    updateHealth() {
      axios
        .get("https://dev.status.gfm.eodc.eu/health?service=GFM")
        .then((res) => {
          var healthResponse = res.data.groups;
          this.healthTableData = res.data.groups;
          

          let healthStates = [];
          healthResponse.forEach(element => {
            healthStates.push(element.status);
          });
          
          // let f = Math.floor(Math.random() * 10)
          // if ((f > 2 && (f < 6))) {
          //   healthStates[0] = 'DEGRADED'
          // }
          // if (f > 6) {
          //   healthStates[0] = 'UNAVAILABLE'
          // }

          if (healthStates.filter(x => x=='UP').length === 4) {
            this.healthState = 0;
          } else {
            if (healthStates.includes('DEGRADED')) {
            this.healthState = 1;
            }
            if (healthStates.includes('UNAVAILABLE')) {
              this.healthState = 2;
            }
          }
        })
        .catch((err) => {
          console.log('Error: ', err)
          this.healthState = 3;
        });
    },
    showModal() {
      this.$refs["modalHealthInformation"].show();
    },
    hideModal() {
      this.$refs["modalHealthInformation"].hide();
    },
    getFormatedDate(dateStr) {
      let arr = dateStr.split('T');
      return arr[0] + " " + arr[1].split('.')[0]
    }
  },
};
</script>

<style scoped>
</style>
