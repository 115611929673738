<template>
  <div id="map"></div>
</template>

<script>
import {eventBus} from "@/main.js";
import "leaflet-draw/dist/leaflet.draw.css";
import L from "leaflet";
import "leaflet-draw";
import "leaflet-spin";
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import "leaflet-geosearch/dist/geosearch.css"

// This code is required to show the marker icon on the geo-search event
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: "AOIMap",
  props: ["invalidateSize"],
  data() {
    return {
      sidebarOpened: true,
      map: null,
      scaler: null,
      tileLayerGrey: null,
      tileLayerStreet: null,
      tileLayerSatellite: null,
      drawControl: null,
      editableLayers: null,
      mapConfiguration: {
        center: [45.815457, 8.6101375],
        zoom: 16,
        minZoom: 0,
        maxZoom: 16,
        startZoom: 14
      },
      boundingBoxAOI: null,
      aoiBoundaryLayer: null,
      searchControl: null
    };
  },
  computed: {
    selectedCard() {
      var x;
      x = this.$store.getters.getAOIsActiveItem;
      return x;
    },
    drawnAOIs() {
      return this.$store.getters.getDrawnAOI;
    },
    addedAOIs() {
      return this.$store.getters.getAddedAOI;
    },
    aois() {
      return this.$store.getters.getAOIs;
    }
  },
  created() {},
  mounted() {
    this.initMap();
    this.initEventBusHandlers();
  },
  beforeDestroy() {
    // clear vuex store
    this.$store.commit("setDrawnAOIs", null);
  },
  methods: {
    /**
     * This function initializes the leaflte map
     */
    initMap: function () {

      // Gray map
      const mapURLGray = process.env.VUE_APP_LEAFLET_BASE_MAP_GRAY;
      this.tileLayerGrey = L.tileLayer.wms(mapURLGray, {
        attribution: "&copy; Esri &mdash; Esri, DeLorme, NAVTEQ",
      });

      // OSM map
      const mapURLOsm = process.env.VUE_APP_LEAFLET_BASE_MAP_OSM;
      this.tileLayerStreet = L.tileLayer(mapURLOsm, {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      });

      // Satellite map
      const mapURLSatellite = process.env.VUE_APP_LEAFLET_BASE_MAP_SATELLITE;
      this.tileLayerSatellite = L.tileLayer(mapURLSatellite, {
        attribution:
          "&copy;  Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community ",
      });

      // Initialize maps
      var mapOptions = {
        minZoom: this.mapConfiguration.minZoom,
        maxZoom: this.mapConfiguration.maxZoom,
        layers: [this.tileLayerGrey],
      };

      this.map = L.map("map", mapOptions).setView([0, 0], 0);

      var ref = this;
      this.map.on('baselayerchange', function(e) {
        if (e.name === 'Light Gray Map') {
          ref.map.options.maxZoom = 16;
        } else {
          ref.map.options.maxZoom =  18;
        }
      });
      
      // Base maps
      var baseMaps = {
				"Light Gray Map": this.tileLayerGrey,
        "Open Street Map": this.tileLayerStreet,
        "Satellite Map": this.tileLayerSatellite,
			};  

      // Initialize control to switch maps
			L.control.layers(baseMaps).addTo(this.map);

      // Initialize draw control and pass it to the FeatureGroup
      var drawnItems = new L.FeatureGroup();
      this.map.addLayer(drawnItems);

      // Initialize the FeatureGroup to store editable layers
      this.editableLayers = new L.FeatureGroup();
      this.map.addLayer(this.editableLayers);
      
      // Event handler for map interaction (create)
      this.map.on(L.Draw.Event.CREATED, event => {
        var layer = event.layer;
        this.editableLayers.addLayer(layer);
        this.$store.commit("setDrawnAOIs", this.editableLayers);
      });

      // Event handler for map interaction (draw start)
      this.map.on(L.Draw.Event.DRAWSTART, () => {
        this.$store.commit('setAOIsActiveItem', null);
      });

      // Event handler for map interaction (delete start)
      this.map.on(L.Draw.Event.DELETESTART, () => {
        this.$store.commit('setAOIsActiveItem', null);
      });

      // Event handler for map interaction (edit start)
      this.map.on(L.Draw.Event.EDITSTART, () => {
        this.$store.commit('setAOIsActiveItem', null);
      });

      // Event handler for map interaction (delete)
      this.map.on(window.L.Draw.Event.DELETED, () => {
        this.$store.commit("setDrawnAOIs", this.editableLayers);
      });

      // Add leaflet geo-search control
      const provider = new OpenStreetMapProvider();
      this.searchControl = new GeoSearchControl({
        provider: provider,
        style: 'bar'  
      });
      this.map.addControl(this.searchControl);

      // Add scaler to map
      this.scaler = L.control.scale();
      this.scaler.addTo(this.map);

      // Init the map view
      this.initMapView();
    },
    /**
     * This function creates the event handlers for the event bus
     */
    initEventBusHandlers() {
      var ref = this;

      eventBus.$on('updateLocationAOIItem', function () {
        ref.updateLocation(ref.aoiBoundaryLayer.getBounds());
      });

      eventBus.$on('addDrawControl', function () {
        ref.addDrawControl();
      });

      eventBus.$on('removeDrawControl', function () {
        ref.removeDrawControl();      
      });
      
      eventBus.$on('removeBoundingBoxAOI', function () {
        ref.removeBoundingBoxAOI();
      });
    },
    /**
     * This functions calls the leaflet invalidateSize function
     */
    invalidateMapSize() {
      this.map.invalidateSize();
    },
    /**
     * Updates the map view point
     * @param bounds: Boundaries of the AOI (see leaflet docomentatoin for details)
     */
    updateLocation(bounds) {
      if (bounds) {
        this.map.fitBounds(bounds);
      }
    },
    /**
     * Sets the view point to the user's geo location.
     */
    initMapView() {
      this.map.spin(true);

      var ref = this;
      this.map.locate({setView: true, maxZoom: ref.mapConfiguration.startZoom}) 
      .on('locationfound', function(){
        ref.map.spin(false);
      })
      .on('locationerror', function(){
          ref.map.setView(ref.mapConfiguration.center, ref.mapConfiguration.startZoom);
          ref.map.spin(false);
      });

      this.searchControl.clearResults();
    },
    addDrawControl() {
      // Draw control options
      var drawControlOptions = {
        position: 'topleft',
        draw: {
          rectangle: {
            shapeOptions: {
              color: '#007bff', // color of rectangle - #f6aa34'
              weight: 3  
            },
          },
          polyline: false,
          polygon: false,
          circle: false,
          marker: false,
          circlemarker: false,
        },
        edit: {
          featureGroup: this.editableLayers, // required
          remove: true
        }
      }
      
      this.drawControl = new L.Control.Draw(drawControlOptions);
      this.map.addControl(this.drawControl);
    },
    removeDrawControl() {
      if (this.drawControl != null) {
        this.map.removeControl(this.drawControl);
      }
    },
    addBoundingBoxAOI() {
      this.removeBoundingBoxAOI();

      // Styling and options
      var style = {
        color: "#007bff",
        weight: 3,
        fill: true,
        opacity: 0.5,
      }
      var options = {
        interactive: false
      };

      // Create the GeoJSON layer
      this.boundingBoxAOI = new L.geoJSON(this.addedAOIs, {
        options: options,
        style: style
      });

      // Add the layer to the map
      this.$nextTick(() => {
        this.map.addLayer(this.boundingBoxAOI );
        this.updateLocation(this.boundingBoxAOI.getBounds());
      });
    },
    removeBoundingBoxAOI() {
      // Remove bounding box layer
      if (this.boundingBoxAOI != null) {
        this.map.removeLayer(this.boundingBoxAOI);
      }
    },
    /**
     * This function adds a GeoJSON layer to the map
     */
    addAOIBoundaryLayer() {
      
      // Remove the current aoi
      if (this.aoiBoundaryLayer != null) {
        this.map.removeLayer(this.aoiBoundaryLayer);
      }

      // Styling and options
      var style = {
        color: "#dc3545",
        weight: 2,
        fill: false
      };
      var options = {
        interactive: false
      };

      // Find item in aoi list
      var searchID = this.selectedCard.substring(3);
      var aoiItem = this.aois.find(x => x.aoi_id === searchID);

      var aoiLayer = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: aoiItem.geoJSON.coordinates
        }
      };

      // Create the GeoJSON layer
      this.aoiBoundaryLayer = new L.geoJSON(aoiLayer, {
        options: options,
        style: style
      });

      // Add the layer to the map
      this.$nextTick(() => {
        this.map.addLayer(this.aoiBoundaryLayer);
        this.updateLocation(this.aoiBoundaryLayer.getBounds());
      });

    },
    /**
     * This function removes a GeoJSON layer from the map
     */
    removeAOIBoundaryLayer() {
      if (this.aoiBoundaryLayer != null) {
        this.map.removeLayer(this.aoiBoundaryLayer);
        this.initMapView();
      }
    },
    /**
     * This function removes all editable layers (drawn layers) from the map
     */
    removeEidtableLayer() {
      var ref = this;
      this.editableLayers.eachLayer(function(layer) {
        ref.editableLayers.removeLayer(layer);
      })
    }
  },
  watch: {
    /**
     * This watch function listens to the toogle event of the parent component (sidebar). The events is  passed to the component
     * by the `invalidateSize` prop
     */
    invalidateSize: function () {
      this.invalidateMapSize();
    },
    /***
     * This function is executed when the aoi card item has changed
     */
    selectedCard(id) {
      if (id == null) {
        this.removeAOIBoundaryLayer();
      } else {
        this.addAOIBoundaryLayer();
        this.removeEidtableLayer();
      }
    },
    /**
     * Watch function for draw event
     */
    'drawnAOIs': function (newVal) {
      if (newVal == null) {
        // Remove the layer which the user has drawn
        var ref = this;
        this.editableLayers.eachLayer(function(layer) {
          ref.editableLayers.removeLayer(layer);
        });
      }
    },
    /**
     * Watch function for add event
     */
    'addedAOIs': function (newVal) {
      if (newVal) {
        this.addBoundingBoxAOI();
      } else {
        this.removeBoundingBoxAOI();
      }
    },
  },
};
</script>

<style scoped>
#map {
  width: auto;
  height: 100%;
}
</style>
