<template>
  <div id="passwordReset" class="login container">
    <b-card class="card">
      <b-form>
        <!-- <b-button variant="link" class="text-decoration-none" @click="goBack">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon>
          <b-img
            class="image"
            :src="require('./../assets/gfmlogo.png')"
            fluid
            alt="Responsive image"
            width="100%"
          ></b-img>
        </b-button> -->
        <div class="row">
          <div class="col my-auto">
            <b-img
              class="image"
              :src="require('./../assets/gfmlogo.png')"
              fluid
              alt="Responsive image"
              width="100%"
            ></b-img>
          </div>
          <div class="col text-right">
            <b-button variant="link" class="text-decoration-none" @click="goBack">
              <b-icon icon="chevron-left" class="toggle-icon rounded p-1" aria-hidden="true"></b-icon>
            </b-button>
          </div>
        </div>
      </b-form>
      <b-form @submit.prevent="onSubmit" data-cy=title-reset-password>
        <h3 align="justify">
          <b>Forgot your password?</b>
        </h3>
        <p class="p">Just enter your email, then we'll send you a link to reset your password</p>
        <b-alert v-model="showAlert" variant="success">Email sent! Redirect in 3 seconds.</b-alert>
        <b-form-group id="input-group-1" label="E-mail" label-for="input-1" class="font-bold">
          <b-form-input id="input-1" type="email" v-model="form.email" required autocomplete="email" placeholder="Enter e-mail address"
          data-cy="input-email-reset"></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" block data-cy="submit-send">Send</b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: ""
      },
      showAlert: false
    };
  },
  methods: {
    onSubmit() {
      if (!this.showAlert) {
        const formData = {
          email: this.form.email
        };
        this.$store.dispatch('resetPasswordRequest', formData);
        this.showAlert = true;
        
        var ref = this;
        setTimeout(function(){ ref.$router.push("/login"); }, 3000);
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  }
};
</script>

<style scoped>
.login {
  width: 400px;
  padding-top: 30px;
}

.card {
  border-color: #212529;
  border-width: 2px;
}

.p {
  font-size: 14px;
}

.alert {
  margin-top: 5px;
  margin-bottom: 5px;
}

.btn .b-icon.bi {
  font-size: 250%;
  vertical-align: middle;
}

.btn-link {
  color: #000000;
}

/**
 * Sidebar icon hover styling
 */
.toggle-icon:hover{
  cursor: pointer;
  background-color: #f4f4f4;
}

.font-bold {
  font-weight: bold;
}
</style>
