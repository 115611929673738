<template>
  <div>

    <b-modal
      id="edit-notification-settings"
      title="Edit Notification Settings"
      ok-title="Save"
      no-close-on-backdrop
      no-close-on-esc
      @ok="handleEditSave"
    >
      <form ref="form">
        <div class="mb-2">
          <span>AOI name:&nbsp;<span><b>{{editForm.aoiName}}</b></span></span>
        </div>
        <b-form-group class="mb-2">
          <b-form-checkbox
            id="checkbox-push-notification"
            value="accepted"
            unchecked-value="not_accepted"
            v-model="editForm.pushNotificationActive"
          >
            Activate push notifications
          </b-form-checkbox>
        </b-form-group>
        <b-form-group>
          <b-form-checkbox
            id="checkbox-twitter-notification"
            value="accepted"
            unchecked-value="not_accepted"
            v-model="editForm.twitterNotificationActive"
            :disabled="editForm.twitterConfigStatus==false"
          >
            Activate twitter notifications
            <b-icon v-if="editForm.twitterConfigStatus==false"
              id="popover-target-1"
              size="sm"
              class="ml-2"
              variant="success"
              icon="exclamation-circle-fill"
            >
            </b-icon>
            <b-popover target="popover-target-1" triggers="hover" placement="top">
              <template #title>Twitter setup</template>
              Please complete the Twitter setup process first!
            </b-popover>
          </b-form-checkbox>
        </b-form-group>
      </form>
    </b-modal>

    <b-overlay :show="showOverlay" rounded="sm">
      <b-card>
        <b-card-title>
          <div class="row">
            <div class="col">Notification settings</div>
            <div class="col text-right">
              <b-button variant="outline-secondary" size="sm" @click="reload()"><b-icon class="mr-0" icon="arrow-repeat"></b-icon></b-button>
            </div>
          </div>
        </b-card-title>
        <b-alert v-model="showAlert" show dismissible variant="danger"  @dismissed="showAlert=false">
          <div><strong>Error!</strong> Notification settings could not be loaded.</div>
        </b-alert>
        <b-input-group prepend="AOI" class="mt-4 mb-3">
          <div id="list-select-aoi">
            <list-select :list="aoiList"
              @select="onSelect"
              :selected-item="selectedAoiItem"
              option-value="code"
              option-text="name"
              placeholder="Select AOI">
            </list-select>
          </div>
          <b-button :disabled="searchBtnBusy"  @click="searchAOIClicked" class="ml-2" variant="primary"><b-icon icon="search" scale="1.0"></b-icon></b-button>
        </b-input-group>
        <div class="text-right mb-3 mt-0">
          <b-link v-on:click="clearFilter">Clear filter</b-link>
        </div>

        <b-table id="my-table" :per-page="perPage" :current-page="currentPage" hover bordered :items="tableItemsActive" :fields="fields">
          <template v-slot:cell(push_notifications_active)="data">
            <div v-if="data.item.push_notifications_active">
              <b-icon icon="check-circle-fill" scale="1.1" variant="success"></b-icon>
              <span class="pl-2 font-italic">Active</span>
            </div>
            <div v-else>
              <b-icon icon="x-circle-fill" scale="1.1" variant="danger"></b-icon>
              <span class="pl-2 font-italic">Not active</span>
            </div>
          </template>
          <template v-slot:cell(twitter_notifications_active)="data">
            <div v-if="data.item.twitter_notifications_active">
              <b-icon icon="check-circle-fill" scale="1.1" variant="success"></b-icon>
              <span class="pl-2 font-italic">Active</span>
            </div>
            <div v-else>
              <b-icon icon="x-circle-fill" scale="1.1" variant="danger"></b-icon>
              <span class="pl-2 font-italic">Not active</span>
            </div>
          </template>
          <template class="edit-btn-row" v-slot:cell(edit_btn)="rowData">
            <div class="text-center">
              <b-button @click="editNotification(rowData)" size="sm"><b-icon class="mr-0" icon="pencil"></b-icon></b-button>
            </div>
          </template>
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table">
        </b-pagination>
      </b-card>
    </b-overlay>

  </div>
</template>

<script>
import { ListSelect } from 'vue-search-select'
import axios_services from "@/axios/axios-services.js";
import {eventBus} from "@/main.js";

export default {
  name: "NotificationsConfiguration",
  components: {
    ListSelect
  },
  computed: {
    rows() {
      return this.tableItemsActive.length;
    },
    aoiLoadingStatus() {
      return this.$store.getters.getAOIsLoadingStatus;
    },
    notificationSettingsLoadingStatus() {
      return this.settingsLoading;
    },
    showOverlay() {
      return this.aoiLoadingStatus || this.notificationSettingsLoadingStatus;
    },
    searchBtnBusy() {
      return Object.keys(this.selectedAoiItem).length === 0 ? true : false;
    }
  },
  props: ['twitterConfigCompleted'],
  data() {
    return {
      showAlert: false,
      settingsLoading: false,
      selectedAoiItem: {},
      aoiList: [],
      perPage: 5,
      currentPage: 1,
      fields: [
        { key: 'aoi_name', label: 'AOI Name', class: 'align-middle' },
        { key: 'push_notifications_active', label: 'Push Notifications', class: 'align-middle' },
        { key: 'twitter_notifications_active', label: 'Twitter Notifications', class: 'align-middle' },
        { key: 'edit_btn', label: '' },
      ],
      tableItems: [],
      tableItemsActive: [],
      editForm: {
        aoiID: "",
        aoiName: "",
        pushNotificationActive: "not_accepted",
        twitterNotificationActive: "not_accepted",
        twitterConfigStatus: false,
      }
    };
  },
  mounted() {
    this.initAOISelectionBox();
    this.initNotificationSettingsTable();

    // Initialize eventbus handler
    var ref = this;
    eventBus.$on('update-notification-configuration', function () {
      ref.initNotificationSettingsTable();
    });

  },
  methods: {
    /**
     * This function is called when the search box selection changes
     */
    onSelect (selectedAoiItem) {
      this.selectedAoiItem = selectedAoiItem
    },
    /**
     * Eventhandler for the AOI search button
     */
    searchAOIClicked() {
      var tmpAOIs = this.tableItems.slice();
      var filteredAOIs = tmpAOIs.filter((aoi) => {
        if (aoi.aoi_name.toLowerCase() == this.selectedAoiItem.name.toLowerCase()) {
          return aoi;
        }
      });
      this.tableItemsActive = filteredAOIs;
    },
    /**
     * This function clears the filter and resets the table 
     */
    clearFilter() {
      this.tableItemsActive = this.tableItems;
    },
    /**
     * Reloads the card item
     */
    reload() {
      this.initNotificationSettingsTable();
      this.initAOISelectionBox();
      this.selectedAoiItem = {};  
    },
    /**
     * Loads the AOIs and initializes the search box
     */
    initAOISelectionBox() {
      this.$store.dispatch("getAOIs").then( () => {
        var tmpAOIs = this.$store.getters.getAOIs.slice();
        var sortedAOIs = tmpAOIs.sort((a, b) => (a.aoi_name.toLowerCase() > b.aoi_name.toLowerCase()) ? 1 : -1)
        sortedAOIs.forEach(element => {
          var d =  { code: element.aoi_id, name: element.aoi_name };
          this.aoiList.push(d);
        });
      }).then( () => {
        //this.showOverlay = false;
      });
    },
    /**
     * This function initializes the notification settings table
     */
    initNotificationSettingsTable() {    
        this.settingsLoading = true;
        var user_id = this.$store.getters.clientID;
        axios_services.get(`/notifications/notification_settings/${user_id}`)
        .then((response) => {
          this.tableItems = response.data;
          this.tableItemsActive = this.tableItems;
          this.showAlert = false;
          this.settingsLoading = false;
        })
        .catch((error) => {
          console.log('Error: Notification settings could not be loaded. Details: ', error);
          this.showAlert = true;
          this.settingsLoading = false;
        });
    },
    /**
     * This function shows the modal dialog to edit the notification settings
     */
    editNotification(rowData) {
      this.editForm = {
        aoiID: rowData.item.aoi_id, 
        aoiName: rowData.item.aoi_name,
        pushNotificationActive: (rowData.item.push_notifications_active === true) ? 'accepted' : 'not_accepted',
        twitterNotificationActive: (rowData.item.twitter_notifications_active === true) ? 'accepted' : 'not_accepted',
        twitterConfigStatus: this.twitterConfigCompleted
      }
      this.$bvModal.show("edit-notification-settings");
    },
    /**
     * This functions calls the backend route in order to store the notification settings
     */
    handleEditSave() {
      // Store data to database
      var payload = {
        "user_id": this.$store.getters.clientID,
        "aoi_id": this.editForm.aoiID,
        "push_notifications_active": (this.editForm.pushNotificationActive === 'accepted') ? true : false,
        "twitter_notifications_active": (this.editForm.twitterNotificationActive === 'accepted')  ? true : false
      };
      axios_services
        .put("/notifications/notification_settings/update", payload)
        .then(() => {
          this.updateNotificationSetting(this.editForm.aoiID, this.editForm.pushNotificationActive, this.editForm.twitterNotificationActive);
          this.showNotification("success", "Success!", "Notification settings successfully updated");
        })
        .catch((error) => {
          console.log("Error: Notification settings could not be updated: ", error)
          this.showNotification("danger", "Error!", "Notification settings could not be updated");
      })
    },
    /**
     * This function updates the state of the notification settings
     */
    updateNotificationSetting(aoiID, pushNotificationActive, twitterNotificationActive) {
      var itemIndex = this.tableItemsActive.findIndex(x => x.aoi_id === aoiID);
      this.tableItemsActive[itemIndex].push_notifications_active = ((pushNotificationActive === 'accepted') ? true : false);
      this.tableItemsActive[itemIndex].twitter_notifications_active = ((twitterNotificationActive === 'accepted') ? true : false);
    },
    /**
     * Shows a notification widget. The paramter `variant` sets the color
     */
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 4000,
        title: title,
        variant: variant,
        solid: true
      });
    },
  },
  watch: {
    selectedAoiItem(v) {
      if ( Object.keys(v).length === 0) {
        this.tableItemsActive = this.tableItems;
      }
    }
  }
};
</script>

<style scoped>
#list-select-aoi {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group-button {
    padding: 0 0 0 5px;
    border-color: transparent;
    background: none;
}
.edit-btn-row {
  text-align: center;
}
.label-bold {
  font-weight: bold;
}
</style>
