<template>
  <div>
    <!-- Layer Selection Table -->
    <b-table class="bg-color mt-2 mb-2" thead-class="hidden-header" small hover outlined :items="productItems"
      :tbody-tr-class="rowClass">
      <template v-slot:cell(index)="data">
        <div v-if="data.index == selectedLayerIndex " @click="activateLayerClicked(data.index)">
          <b-iconstack class="icon-hover my-auto" font-scale="1.1">
            <b-icon stacked icon="circle-fill" style="color: #007bff"></b-icon>
            <b-icon stacked icon="check" style="color: white"></b-icon>
          </b-iconstack>  
        </div>
        <div v-else @click="activateLayerClicked(data.index)">
          <b-iconstack class="icon-hover my-auto" font-scale="1.1">
            <b-icon stacked icon="circle" style="color: #007bff"></b-icon>
          </b-iconstack>
        </div>
      </template>

      <template v-slot:cell(layer)="data">
        {{data.item.layer.frontend_label}}
          <b-icon 
            v-if="showPopover(data.item.layer.geoserver_layer_name)"
            :id="'popover-latest-product-' + data.index + '-' + data.item.layer.product_id"
            size="sm"
            class="ml-2"
            variant="secondary"
            icon="exclamation-circle-fill"
          >
          </b-icon>
          <b-popover :target="'popover-latest-product-'  + data.index + '-' + data.item.layer.product_id" triggers="hover" placement="top">
            <template #title></template>
            Please click on the polygon in order to get more information.
          </b-popover>
      </template>

      <template v-slot:cell(downloadBtn)="row">
        <div class="text-right">
          <b-button
            :id="'download-btn-' + row.item.product_id + '-' + row.item.index"
            v-b-tooltip.hover.top :title=getTooltipText()
            size="sm"
            class="mb-0 btn-xs"    
            @click="downloadLayerClicked(row)"
          >
            <b-icon icon="cloud-download" aria-hidden="true"></b-icon>
          </b-button>
        </div>
      </template>
    </b-table>
    <div class="row">
      <div class="col">
        <b-button :disabled="reportButtonDisabled || mapLoadingState==true" v-b-tooltip.hover title="Generate product report" @click="generateReport()" size="sm" class="float-right"><b-icon icon="printer" aria-hidden="true"></b-icon></b-button>
        <b-button v-b-tooltip.hover title="Download all layers" @click="downloadAllLayersClicked()" size="sm mr-2" class="float-right"><b-icon icon="cloud-download " aria-hidden="true"></b-icon>&nbsp;&nbsp;Download layers</b-button>
      </div>
    </div> 

    <b-modal :id='"modal-download-single-flood-" +  productData.product_id' title="Flood extent download" ok-title="Download"
      @ok="submitSingleFloodExtentSelection()" size="lg">
      <b-form-group label="Please choose the output type for the observed flood extent layer." v-slot="{ ariaDescribedby }">
        <b-form-radio v-model="selectedFloodExtentLayerType" :aria-describedby="ariaDescribedby" value="raster">Raster</b-form-radio>
        <b-form-radio v-model="selectedFloodExtentLayerType" :aria-describedby="ariaDescribedby" value="vector">Vector</b-form-radio>
      </b-form-group>
    </b-modal>

  </div>
</template>

<script>
import axios_services from "@/axios/axios-services.js";
import {eventBus} from "@/main.js";
import {ProductLayers} from "@/product-layery.js";

export default {
  name: "LayerSelection",
  components: {
  },
  props: {
    productBoxToggled: { type: Boolean, required: false},
    productData: { type: Object, required: true },
    productMetaData: { type: Object, required: true },
    productLayerData: { type: Array, required: true },
    productLayerS1Data: { type: Array, required: true },
  },
  data() {
    return {
      selectedLayerIndex: null,
      productItems: [],
      reportButtonDisabled: true,
      selectedFloodExtentLayerType: "raster"
    };
  },
  computed: {
    mapLoadingState() {
      return this.$store.getters.getMapLoadingState;
    },
    clientID() {
      return this.$store.getters.clientID;
    }
  },
  methods: {
    activateLayerClicked(index) {
      if (index == this.selectedLayerIndex) {
        this.selectedLayerIndex = null;
      } else {
        this.selectedLayerIndex = index;
      }
    },
    downloadLayerClicked(row) {
      //console.log("row: ", row)
      //console.log("this.productLayerData: ", this.productLayerData)
      if (row.item.layer.layer_id == 1) {
        let modal = "modal-download-single-flood-" +  this.productData.product_id
        this.$bvModal.show(modal)
      } else {
      let scene_id = this.productData.cell_code;
      let aoi_id = this.productMetaData.aoiID;
      //let layer_id = this.productLayerData[row.item.layer.layer_id].layer_id;
      let layer_id = row.item.layer.layer_id;

      this.donwloadLayer(scene_id, aoi_id, layer_id);
      }
    },
    donwloadLayer(scene_id, aoi_id, layer_id) {
      this.$store.commit('setToggleProductsDownloadLoadingView', true);
      axios_services
        .get(`/download/scene-file/${scene_id}/${aoi_id}/${layer_id}`)
        .then((response) => {
          const downloadLink = response.data.download_link;
          if (downloadLink == null) {
            this.showNotification("warning", "Warning!", "Product not available for download")
          } else {
            setTimeout(() => {          
              this.download_file(downloadLink);
              this.$store.commit("setToggleProductsDownloadLoadingView", false);
            }, 500);
          }
      }).catch((err) => {
        console.log("Error: ", err);
        this.showNotification("danger", "Error!", "The product could not be downloaded")
        this.$store.commit('setToggleProductsDownloadLoadingView', false);
      });
    },
    downloadAllLayersClicked() {
      let scene_id = this.productData.cell_code;
      let aoi_id = this.productMetaData.aoiID;

      this.$store.commit('setToggleProductsDownloadLoadingView', true);
      axios_services
        .get(`/download/scene-product/${scene_id}/${aoi_id}`)
        .then((response) => {
          const downloadLink = response.data.download_link;
          setTimeout(() => {
            this.download_file(downloadLink);
            this.$store.commit("setToggleProductsDownloadLoadingView", false);
          }, 500);
      }).catch((err) => {
        console.log("Error: ", err);
        this.showNotification("danger", "Error!", "The products could not be downloaded")
        this.$store.commit('setToggleProductsDownloadLoadingView', false);
      });
    },
    initTableData(data) {
      var tableRows = [];
      data.forEach((element, index) => {
        console.log(element, ' - ', index);
        var tableRow = {
          index: index,
          layer: element,
          downloadBtn: null
        }
        if (element.layer_id == 12) {
          return;
        } 
        tableRows.push(tableRow);
      });


      return tableRows;
    },
    download_file(downloadURL) {
      // const link = document.createElement('a');
      // link.style = "display: none";
      // link.href = downloadURL;
      // document.body.appendChild(link);
      // link.click();
      window.location.href = downloadURL;
    },
    showNotification(variant, title, content) {
      this.$bvToast.toast(content, {
        autoHideDelay: 2000,
        title: title,
        variant: variant,
        solid: true
      });
    },
    generateReport() {
      let reportProductDetails = {
        identifier: this.productData.product_time.split('T')[0],
        aoiName: this.productMetaData.aoiName,
        timestamp: this.productData.product_time,
        layer: this.productLayerData[this.selectedLayerIndex].frontend_label,
      }
      this.$store.commit('setToggleProductsReportLoadingView', true);
      this.$reportService.createReport(this.productData.product_id, reportProductDetails)
        .then(() => {
          this.$store.commit('setToggleProductsReportLoadingView', false);
        })
        .catch((err) => {
          console.log("Error: ", err);
          this.showNotification("danger", "Error!", "Report could not be created");
          this.$store.commit('setToggleProductsReportLoadingView', false);
        });
    },
    showPopover(layerName) {
      if (
        layerName == ProductLayers.SENTINEL1_METADATA ||
        layerName == ProductLayers.SENTINEL1_FOOTPRINT ||
        layerName == ProductLayers.SENTINEL1_SCHEDULE
      ) { 
        return true;
      } else {
        return false;
      }
    },
    rowClass(item) {
      if (this.selectedLayerIndex == item.index - 1) {
        return "table-active";
      }
    },
    getTooltipText() {
      //return (index == 0) ? "Download single layers" : "Download single layers"
      return "Download single layers"
    },
    submitSingleFloodExtentSelection() {
      let layerIndex;
      if (this.selectedFloodExtentLayerType == 'raster') {
        layerIndex = 1;
      } else {
        layerIndex = 12;
      }
      
      let scene_id = this.productData.cell_code;
      let aoi_id = this.productMetaData.aoiID;
      let layer_id = layerIndex;

      this.donwloadLayer(scene_id, aoi_id, layer_id);
    }
  },
  mounted() {},
  created() {},
  watch: {
    productBoxToggled(newValue) {
      if (!newValue) {
        this.selectedLayerIndex = null;
      }
    },
    selectedLayerIndex(index) {
      if (index == null) {
        this.$store.commit("setDataLayer", null);
        this.reportButtonDisabled = true;
        
      } else {
        var layerName = this.productLayerData[index].geoserver_layer_name;
        var layerBaseUrl = this.productLayerData[index].geoserver_base_url;
        var productDate = this.productData.product_time;
        var productExtent = this.productLayerData[index].layer_boundary;

        this.$store.commit("setDataLayer", {
          layerName: layerName,
          layerBaseUrl: layerBaseUrl,
          productDate: productDate,
          productExtent: productExtent,
          layerID: this.productLayerData[index].layer_id,
          sentinel1Layer: this.productLayerS1Data
        });
        eventBus.$emit('hide-sidebar-right');
        this.reportButtonDisabled = false;
      }
    },
    productLayerData(data) {
      if (data.length != 0) {
        this.productItems = this.initTableData(data);
      }
    }
  },
  showNotification(variant, title, content) {
    this.$bvToast.toast(content, {
      autoHideDelay: 4000,
      title: title,
      variant: variant,
      solid: true
    });
  },
};
</script>

<style scoped>
.bg-color {
  background-color: #f0f0f0;
}

.iocn-hover:hover {
  cursor: pointer;
}

.btn-xs {
  padding-top: 1px;
  padding-bottom: 1px;
}
</style>

<style>
.table-active,
.table-active > th,
.table-active > td {
  background-color: #c7e0f4 !important;
}

.hidden-header {
  display: none;
}
</style>
