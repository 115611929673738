<template>
  <div>
    <div id="spacing-top"></div>

    <div v-if="showLoading" class="container">
      <div class="loader">Loading...</div>
      <div class="mt-4 text-muted loading-text-title">LOADING ...</div>
      <div class="mt-1 text-muted loading-text">Page is loading, please wait.</div>
    </div>
    <div v-if="showPageNotFound" class="container text-center">
      <div class="page-wrap d-flex flex-row align-items-center">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <span class="display-1 d-block text-muted">404</span>
              <div class="mb-4 lead text-muted">
                The page you are looking for was not found.
              </div>
              <b-button variant="primary">Back to Login</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import config from '@/config.js'

export default {
  name: "EfasCallback",
  components: {},
  data() {
    return {
      showLoading: true,
      showPageNotFound: false,
    };
  },
  computed: {},
  mounted() {
    this.$efasAuth.handleCallback()
      .then((response) => {
        // set login type to GLOFAS
        this.$store.commit("setUserType", config.EFAS_USER);

        // store glofas user data
        var efasUserData = {
          isGlofasUser: response.data.is_glofas_user,
          glofasAccountFinished: response.data.glofas_account_finished,
          firstName: response.data.given_name, // TODO
          lastName: response.data.family_name,   // TODO
          email: response.data.email,
          organisationName: response.data.organisation_name,
          organisationType: response.data.organisation_type,
          country: response.data.country,
        };

        this.$store.commit("setEfasUser", efasUserData);
        this.$store.dispatch("signinEfas", response.data);

        var isAccountFinished = efasUserData.glofasAccountFinished ;
        if (!isAccountFinished) {
          this.$router.push("efas-registration");
          
        } else {
          this.$router.push("/");
        }

      })
      .catch((error) => {
        console.log("Error: ", error);
        this.$store.dispatch("signout");
      });
  },
  methods: {},
};
</script>

<style scoped>

#spacing-top {
  padding-top: 80px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 10px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(73, 73, 73, 0.1);
  border-right: 1.1em solid rgba(73, 73, 73, 0.1);
  border-bottom: 1.1em solid rgba(73, 73, 73, 0.1);
  border-left: 1.1em solid #757171;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-text-title {
  font-size: 22px;
  text-align: center;
  /* color: #757171; */
  font-weight: bold;
}

.loading-text {
  font-size: 16px;
  text-align: center;
  /* color: #757171; */
  font-style: italic;
  
}

</style>
