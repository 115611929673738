<template>
  <div>
    <b-modal id="bv-modal-disclaimer" size="lg" ref="modalDisclaimer" title="EU Disclaimer">
      <p class="pt-2">
        The designations employed and the presentation of material on this map do not imply the expression of any opinion
        whatsoever on the part of the European Union (EU) concerning the legal status of any country, territory, city or
        area or of its authorities, or concerning the delimitation of its frontiers or boundaries.
      </p>
      <p class="pt-2">
        The regions shown on the map are based on the geographical nomenclatures employed in the NUTS (Nomenclature des
        Unités Territoriales Statistiques) database for the EU, and the GADM (Global Administrative Areas) database for
        the rest of the world. We do not take responsibility for any quality issues that may be inherent in the
        designations of specific regions.
      </p>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" class="float-right" @click="hideModal">
            Confirm
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
  
<script>
import { eventBus } from "@/main.js";

export default {
  name: "",
  methods: {
    showModal() {
      this.$refs["modalDisclaimer"].show();

    },
    hideModal() {
      this.$refs["modalDisclaimer"].hide();
    }
  },
  created: function () {
    eventBus.$on("show-modal-disclaimer", () => {
      this.showModal();
    });
  }
};
</script>
  
<style>
ul.a {
  list-style-type: square;
}
</style>
  
  
  