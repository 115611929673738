import Vue from 'vue'
import VueRouter from 'vue-router'

import AOIMain from '@/pages/aoi'
import ProductsMain from '@/pages/products'
import NotificationsMain from '@/pages/notifications'
import Profile from '@/pages/profile/'
import Login from '../components/Login.vue'
import AccountRecovery from '../components/AccountRecovery.vue'
import PasswordReset from '../components/PasswordReset.vue'
import Registration from '../components/Registration.vue'
import ActivateRegistration from '../components/ActivateRegistration.vue'
import Callback from '@/pages/callback'
import EfasCallback from '@/pages/callback/EfasCallback'
import GlofasRegistrationProfile from '@/pages/profile/GlofasRegistrationProfile.vue'
import EfasRegistrationProfile from '@/pages/profile/EfasRegistrationProfile.vue'
import ArchiveDownload from '@/pages/more/More_ArchiveDownload.vue'
import CodeSnippet from '@/pages/more/More_CodeSnippet.vue'
import ErrorPage404 from '@/components/ErrorPage404.vue'

import store from '../store/store.js'
//import {eventBus} from "@/main.js";
//eventBus.$emit("show-modal-dialog-startup")

Vue.use(VueRouter)


export const routes = [
  { name: 'aoi', path: '/', component: AOIMain, meta: {requiresAuth: true} },
  { name: 'products', path: '/products', component: ProductsMain, meta: {requiresAuth: true} },
  { name: 'products2', path: '/products/:id?', component: ProductsMain, meta: {requiresAuth: true} },
  { name: 'notifications', path: '/notifications', component: NotificationsMain, meta: {requiresAuth: true} },
  { name: 'profile', path: '/profile', component: Profile, meta: {requiresAuth: true } },
  { name: 'login', path: '/login', component: Login, meta: {userSite: true} },
  { name: 'account-recovery', path: '/account-recovery', component: AccountRecovery, meta: {userSite: true} },
  { name: 'password-reset', path: '/password-reset', component: PasswordReset, meta: {userSite: true} },
  { name: 'register', path: '/register', component: Registration, meta: {userSite: true} },
  { name: 'registerEmail', path: '/registerEmail', component: ActivateRegistration, meta: {userSite: true}},
  { name: 'callback', path: '/callback', component: Callback, meta: {userSite: true}},
  { name: 'efasCallback', path: '/efas-callback', component: EfasCallback, meta: {userSite: true}},
  { name: 'glofas-registration', path: '/glofas-registration', component: GlofasRegistrationProfile, meta: {requiresToken: true, userSite: true}},
  { name: 'efas-registration', path: '/efas-registration', component: EfasRegistrationProfile, meta: {requiresToken: true, userSite: true}},
  { path: '*', component: ErrorPage404, meta: {requiresAuth: true, userSite: false}},
  { name: 'archive-download', path: '/archive-download', component: ArchiveDownload, meta: {requiresAuth: true } },
  { name: 'code-snippet', path: '/code-snippet', component: CodeSnippet, meta: {requiresAuth: true } },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch("tryAutoSignIn")
  if (!store.state.auth.access_token && to.meta.requiresAuth) {
    next({ path: '/login', query: { redirect: to } });
  }
  else if(store.state.auth.access_token && to.meta.requiresAuth && store.state.auth.scope == 'glofas') {
    next({ path: '/glofas-registration' });
  }
  else if(store.state.auth.access_token && to.meta.requiresAuth && store.state.auth.scope == 'efas') {
    next({ path: '/efas-registration' });
  }
  else if (!store.state.auth.access_token && to.meta.requiresToken) {
    next({ path: '/login', query: { redirect: to } });
  }
  else if (to.meta.userSite) {
    store.commit('changeUserSite', true);
    next();
  }
  else {
    store.commit('changeUserSite', false);
    next();
  }
})

export default router
